import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getDataFromSQLSp } from "../../../../Molecules/Common/File Upload/M_fileUpload.helper";
import CircularProgress from "@material-ui/core/CircularProgress";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActions from "../../../../../../store/actions/toastActions";
import A_CircularIndeternment from "../../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";

const styles = {
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  textField: {
    marginBottom: "15px",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "15px",
  },
  addButton: {
    marginRight: "10px",
  },
};

const KtCodesMainForm = ({
  formData,
  handleCloseForm,
  isEdited,
  getCategory,
  toolbarFilterValue,
}) => {
  const [errors, setErrors] = useState({
    Code: "",
    label: "",
    "Change Details": "",
    "Change Title": "",
    "Reason For Change": "",
    Category1: "",
    Category2: "",
    Category3: "",
    Asset: "",
  });

  const [newFormData, setNewFormData] = useState({
    Code: "",
    label: formData.label || "",
    "Change Details": formData["Change Details"] || "",
    "Change Title": formData["Change Title"] || "",
    "Reason For Change": formData["Reason For Change"] || "",
    Category1: "",
    Category2: "",
    Category3: "",
    Asset: formData.Asset || "",
    PBI_ID: formData.PBI_ID || "",
  });
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [loading, setLoading] = useState({
    Category1: false,
    Category2: false,
    Category3: false,
    Code: false,
  });
  const [getCategory2, setGetCategory2] = useState([]);
  const [getCategory3, setGetCategory3] = useState([]);
  const [ktCodes, setKtCodes] = useState("");
  useEffect(() => {
    setErrors({
      Code: "",
      Category1: "",
      Category2: "",
      Category3: "",
    });
  }, [formData]);

  const EmptyUpdate = () => {
    setNewFormData({
      Code: "",
      Category1: "",
      Category2: "",
      Category3: "",
    });
  };
  const getCategoryData2 = async (val) => {
    setLoading((prevLoading) => ({ ...prevLoading, Category2: true }));
    try {
      let query = "res";
      let response = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "JLCHART.Usp_GetCategory2",
            parameters: { CATEGORY1: val, QuarterCD: toolbarFilterValue },
          },
        ],
      });
      setGetCategory2(response.res);
    } catch (error) {
      // Handle errors
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, Category2: false }));
    }
  };

  const getCategoryData3 = async (val) => {
    setLoading((prevLoading) => ({ ...prevLoading, Category3: true }));
    try {
      let query = "res";
      let response = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "JLCHART.Usp_GetCategory3",
            parameters: {
              CATEGORY1: newFormData.Category1 || "",
              CATEGORY2: val,
              QuarterCD: toolbarFilterValue,
            },
          },
        ],
      });
      setGetCategory3(response.res);
    } catch (error) {
      // Handle errors
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, Category3: false }));
    }
  };

  const getKTCode = async (val) => {
    setLoading((prevLoading) => ({ ...prevLoading, Code: true }));
    try {
      let query = "res";
      let response = await getDataFromSQLSp({
        chartSPs: true,
        spDetails: [
          {
            id: query,
            spName: "JLCHART.DropDownQuarterly",
            parameters: {
              Category1: newFormData.Category1 || "",
              Category2: newFormData.Category2 || "",
              Category3: val,
              QuarterCD: toolbarFilterValue,
            },
          },
        ],
      });
      let value =
        response && response.res && response.res[0] && response.res[0].Code
          ? response.res[0].Code
          : "";
      setKtCodes(value);
      const newErrors = {};
      if (value) {
        newErrors.Code = "";
      }
      setErrors(newErrors);
    } catch (error) {
      // comment
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, Code: false }));
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!newFormData.Category1) {
      newErrors.Category1 = "Category 1 is required";
      isValid = false;
    }

    if (!newFormData.Category2) {
      newErrors.Category2 = "Category 2 is required.";
      isValid = false;
    }

    if (!newFormData.Category3) {
      newErrors.Category3 = "Category 3 is required.";
      isValid = false;
    }

    if (!ktCodes) {
      newErrors.Code = "Code is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (validateForm()) {
        setButtonLoading(true);
        let query = "res";
        await getDataFromSQLSp({
          chartSPs: true,
          spDetails: [
            {
              id: query,
              spName: "JLCHART.Usp_UpdateCategory",
              parameters: {
                Category1: newFormData.Category1 || "",
                Category2: newFormData.Category2 || "",
                Category3: newFormData.Category3 || "",
                PBI_ID: newFormData.PBI_ID || "",
              },
            },
          ],
        });
        setButtonLoading(false);
        EmptyUpdate();
        handleCloseForm(true);
      }
    } catch {
      setButtonLoading(false);
    }
  };
  const handleCancel = () => {
    handleCloseForm(false);
  };
  const handleCategory1Change = async (event, newValue) => {
    if (!newValue) {
      newValue = { Category1: "" };
    }
    setLoading((prevLoading) => ({ ...prevLoading, Category2: true }));
    setNewFormData((prevData) => ({
      ...prevData,
      Category1: newValue.Category1 || "",
      Category2: newValue.Category2 || "",
      Category3: newValue.Category3 || "",
    }));
    setKtCodes("");
    setErrors((prevErrors) => ({ ...prevErrors, Category1: "" }));
    await getCategoryData2(newValue.Category1 || "");
  };

  const handleCategory2Change = async (event, newValue) => {
    if (!newValue) {
      newValue = { MacroCategory: "" };
    }
    setLoading((prevLoading) => ({ ...prevLoading, Category3: true }));
    setNewFormData((prevData) => ({
      ...prevData,
      Category2: newValue.MacroCategory || "",
      Category3: newValue.Category3 || "",
    }));
    setKtCodes("");
    setErrors((prevErrors) => ({ ...prevErrors, Category2: "" }));
    await getCategoryData3(newValue.MacroCategory || "");
  };

  const handleCategory3Change = async (event, newValue) => {
    if (!newValue) {
      newValue = { MacroCategory: "" };
    }
    setLoading((prevLoading) => ({ ...prevLoading, Code: true }));
    setNewFormData((prevData) => ({
      ...prevData,
      Category3: newValue.MacroCategory || "",
    }));
    setErrors((prevErrors) => ({ ...prevErrors, Category3: "" }));
    await getKTCode(newValue.MacroCategory || "");
  };

  return (
    <>
      <div style={styles.formContainer}>
        <TextField
          label="Asset Name"
          name="Asset"
          value={newFormData.Asset}
          disabled={isEdited}
          margin="normal"
          style={styles.textField}
        />
        <TextField
          label="Change Detail"
          name="Change Details"
          value={newFormData["Change Details"]}
          disabled={isEdited}
          margin="normal"
          style={styles.textField}
        />
        <TextField
          label="Change Title"
          name="Change Title"
          value={newFormData["Change Title"]}
          disabled={isEdited}
          margin="normal"
          style={styles.textField}
        />
        <TextField
          label="Reason for Change"
          name="Reason For Change"
          value={newFormData["Reason For Change"]}
          disabled={isEdited}
          margin="normal"
          style={styles.textField}
        />
        <Autocomplete
          id="combo-box-category1"
          options={getCategory}
          getOptionLabel={(option) => option.Category1}
          style={{ width: "100%" }}
          value={
            getCategory.find(
              (option) => option.Category1 === newFormData.Category1
            ) || null
          }
          onChange={handleCategory1Change}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category 1*"
              name="Category1"
              value={newFormData.Category1}
              margin="normal"
              style={styles.textField}
              error={!!errors.Category1}
              helperText={errors.Category1}
            />
          )}
        />

        <Autocomplete
          id="combo-box-category2"
          options={getCategory2}
          getOptionLabel={(option) => option.MacroCategory}
          style={{ width: "100%" }}
          value={
            getCategory2.find(
              (option) => option.MacroCategory === newFormData.Category2
            ) || null
          }
          onChange={handleCategory2Change}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category 2*"
              name="Category2"
              value={newFormData.Category2}
              margin="normal"
              style={styles.textField}
              error={!!errors.Category2}
              helperText={errors.Category2}
            />
          )}
        />
        {/* Loading indicator for Category 2 */}
        {loading.Category2 && (
          <CircularProgress size={20} style={{ marginLeft: 10 }} />
        )}
        <Autocomplete
          id="combo-box-category3"
          options={getCategory3}
          getOptionLabel={(option) => option.MacroCategory}
          style={{ width: "100%" }}
          value={
            getCategory3.find(
              (option) => option.MacroCategory === newFormData.Category3
            ) || null
          }
          onChange={handleCategory3Change}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category 3*"
              name="Category3"
              value={newFormData.Category3}
              margin="normal"
              style={styles.textField}
              error={!!errors.Category3}
              helperText={errors.Category3}
            />
          )}
        />

        {/* Loading indicator for Category 3 */}
        {loading.Category3 && (
          <CircularProgress size={20} style={{ marginLeft: 10 }} />
        )}

        <TextField
          label="Code*"
          name="Code"
          value={ktCodes}
          disabled={true}
          margin="normal"
          style={styles.textField}
          error={!!errors.Code}
          helperText={errors.Code}
        />
        {loading.Code && (
          <CircularProgress size={20} style={{ marginLeft: 10 }} />
        )}
      </div>
      <div style={styles.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ ...styles.addButton, marginRight: "10px" }}
          endIcon={
            buttonLoading ? (
              <A_CircularIndeternment color="secondary" size={20} />
            ) : (
              <></>
            )
          }
        >
          {isEdited ? "Update" : "Add"}
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </>
  );
};

KtCodesMainForm.propTypes = {
  formData: PropTypes.shape({
    Code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    "Change Details": PropTypes.string.isRequired,
    "Change Title": PropTypes.string.isRequired,
    "Reason For Change": PropTypes.string.isRequired,
    Category1: PropTypes.string.isRequired,
    Category2: PropTypes.string.isRequired,
    Category3: PropTypes.string.isRequired,
    Asset: PropTypes.string.isRequired,
    PBI_ID: PropTypes.string.isRequired,
    toolbarFilterValue: PropTypes.string.isRequired,
  }).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleAddRow: PropTypes.func.isRequired,
  handleCloseForm: PropTypes.func.isRequired,
  isEdited: PropTypes.bool.isRequired,
  getCategory: PropTypes.any,
  toolbarFilterValue: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, toastActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(KtCodesMainForm);
