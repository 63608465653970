import { Skeleton } from "@material-ui/lab";
import React from "react";
import { getFileName } from "../../../Atoms/DataDisplay/DataGrid/helper/download.helper";
import selectedIcon from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/Icon.helpers";
import { connect } from "react-redux";
import * as previousUploadActions from "../../../../../store/actions/previousUploadActions";
import { bindActionCreators } from "redux";
import { useParams } from "react-router";
import {
  downloadDocument,
  onDeleteDocument,
} from "../../../Molecules/Common/Renderers/Views/PreviousTemplate/previousUpload.helper";
import {
  Grid,
  List,
  makeStyles,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@material-ui/core";
import M_ActionGroup from "../../../Molecules/Common/ActionGroup/M_ActionGroup";
import { epochToDateFormatHelper } from "../../../../../Utils/Helpers/dateUtils";
import propTypes from "prop-types";
import { executeJSString } from "../../../Pages/Common/Layouts/layout.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
const useStyles = makeStyles(() => ({
  listView: {
    overflowWrap: "anywhere",
    marginTop: "1rem",
    maxHeight: "50vh",
    overflow: "auto",
  },
  CustomCard: {
    maxHeight: "52vh",
    overflow: "scroll",
    overflowX: "hidden",
  },
  list: {
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer",
    },
  },
  avatar: {
    margin: "auto",
    paddingLeft: "10px",
  },
  heading: {
    marginTop: "10px",
  },
  ActionGroup: {
    paddingTop: "25px",
  },
  ListFlex: {
    display: "flex",
  },
}));
function O_HistoricalDocumentUpload(props) {
  const [fileList, setFileList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { crmId, pageUrl } = useParams();
  const classes = useStyles();
  const scrollDisable =
    props && props.content && props.content.scrollDisable ? false : true;
  const getDocument = async () => {
    let fileName =
      crmId == 0
        ? // eslint-disable-next-line react/prop-types
          props.content && props.content.fileName
          ? props.content.fileName
          : pageUrl
        : crmId;
    fileName =
      props.content && props.content.directory
        ? props.content.directory
        : fileName;
    let type =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    let data = await getFileName(fileName, type);
    setFileList(data);
  };
  const handleDownload = async (item) => {
    let container =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    let fileData = {
      file: item.file,
      fileDisplayName: item.file.split("/").pop(),
    };
    await downloadDocument(fileData, container);
  };
  const handleDelete = async (item) => {
    let container =
      props.content && props.content.containerType
        ? props.content.containerType
        : "generic";
    await onDeleteDocument(item, container);
  };
  React.useEffect(() => {
    try {
      getDocument();
      // eslint-disable-next-line no-empty
    } catch (e) {}
    setIsLoading(false);
  }, [props.previousUpload]);
  return (
    <div className={scrollDisable ? classes.CustomCard : ""}>
      {isLoading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <div>
          {Array.isArray(fileList) &&
            fileList.map((item, index) => (
              // eslint-disable-next-line react/jsx-key
              <ListItem key={index} className={classes.list}>
                <Grid container spacing={2}>
                  <Grid xs={11}>
                    <List
                      button
                      onClick={() => handleDownload(item)}
                      className={classes.ListFlex}
                    >
                      <ListItemAvatar className={classes.avatar}>
                        <Avatar
                          src={selectedIcon(item.file)}
                          variant="square"
                        />
                      </ListItemAvatar>

                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={item.file.split("/").pop()}
                          secondary={
                            "Uploaded on " +
                            epochToDateFormatHelper(
                              item.modifiedTime || item.creationTime,
                              getObjectFromJSONString(
                                props,
                                ".content.dateFormat"
                              ) || "DD-MM-YYYY"
                            )
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  {props.currentPage &&
                  props.currentPage.pageConfig &&
                  props.currentPage.pageConfig.pageSecurity &&
                  props.currentPage.pageConfig.pageSecurity.Full === true &&
                  executeJSString(
                    getObjectFromJSONString(props, ".content.readOnlyTemplate"),
                    getObjectFromJSONString(props, ".currentPage.data")
                  ) != true ? (
                    <Grid xs={1} className={classes.ActionGroup}>
                      <div>
                        <M_ActionGroup
                          delete={props.content.delete}
                          onDelete={() => handleDelete(item)}
                          modify={true}
                        />
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </ListItem>
            ))}
        </div>
      )}
    </div>
  );
}
O_HistoricalDocumentUpload.propTypes = {
  content: propTypes.any,
  currentPage: propTypes.any,
  previousUpload: propTypes.any,
};
function mapStateToProps(state) {
  return {
    previousUpload: state.previousUpload,
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, previousUploadActions), dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(O_HistoricalDocumentUpload);
