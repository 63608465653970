export const typeJsonData = [
  {
    questionanswertypeId: 1,
    name: "Numeric",
  },
  {
    questionanswertypeId: 2,
    name: "Text",
  },
  {
    questionanswertypeId: 3,
    name: "Boolean",
  },
];
export const isResponseSubmitted = false;

export const unitJsonData = [
  {
    measurementunitid: 1,
    name: "GwH",
  },
  {
    measurementunitid: 2,
    name: "TwH",
  },
  {
    measurementunitid: 3,
    name: "MwH",
  },
];

export const logicData = [
  {
    logicId: 1,
    name: "Yes",
  },
  {
    logicId: 2,
    name: "No",
  },
  {
    logicId: 3,
    name: "Required",
  },
];

/*
 1 - Numeric
 2 - Text
 3 - Boolean 
*/
export const questionsLayoutData = [
  {
    guidance:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    question: "How much non renewable enery generated by Asset?",
    type: ["Oil", "Natural Gas", "Coal", "Nuclear"],
    unit: "GwH",
    previousYear: [],
  },
  {
    guidance:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
    question: "What you ate yesterday?",
    type: [],
    unit: "",
    previousYear: [],
  },
];
export const SurveyStatus = [
  {
    id: 0,
    Drafted: 0,
  },
  {
    id: 1,
    Publish: 1,
  },
  {
    id: 2,
    Closed: 2,
  },
];
export const SurveyApproval = [1];
export const tableHeader = [
  "Pillar",
  "Sub-Pillar",
  "Maturity-Assessment",
  "Question",
  "Breakdown",
  "Question Answer Type",
  "Guidance",
  "Unit",
  "PAI Framework",
  "Data Convergence Framework",
  "New Question/Import Historic Data",
  "Inherit",
  "Rating",
];
export const AssetRole = {
  AssetManager: "Asset Manager",
  RegionalAssetDirector: "Regional Asset Director",
};
