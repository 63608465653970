import * as React from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { Box } from "@material-ui/core";
import { HistoryRounded } from "@material-ui/icons";
import { ArrowForward, ArrowBack, Close } from "@material-ui/icons";
import * as httpCall from "../../../../../Utils/apis/apis";
import { useParams } from "react-router";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import propTypes from "prop-types";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import { makeStyles } from "@material-ui/core/styles";
import {
  changeButtonLabel,
  changeButtonValue,
  dealStatusEnums,
} from "./dealStatus.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import { connect } from "react-redux";
import * as currentPageActions from "../../../../../store/actions/currentPageActions";
import { bindActionCreators } from "redux";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import M_ManageEntityForm from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm";
import { enhanceEvalString } from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCardHelper";
const useStyles = makeStyles(() => ({
  Button: {
    margin: "0.3rem 0",
  },
  box: {
    display: "flex",
    padding: "1.2rem 0",
    flexWrap: "wrap",
  },
  maindiv: {
    paddingTop: "20px",
  },
}));
function O_DealStatus(props) {
  const classes = useStyles();
  const [preButton, setPre] = React.useState();
  const [nextButton, setNext] = React.useState();
  const { crmId, pageUrl } = useParams();
  const [killPopup, setKillPopup] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [disable, setDisable] = React.useState(false);
  const [dealStatusButton, setDealStatusButton] = React.useState({});
  const [currentStateObject, setCurrentStateObject] = React.useState({});
  const [reason, setReason] = React.useState("");
  const [optionSetDetails, setOptionSetDetails] = React.useState([]);
  const [optionSetValue, setOptionSetValue] = React.useState({});
  const [formStage,setFormStage] = React.useState(null);
  const [formDetails,setFormDetails] = React.useState([])
  React.useLayoutEffect(() => {
    setButton();
    getOptionSetDetails();
  }, []);
  const getOptionSetDetails = () => {
    let options = [];
    try {
      let id = getObjectFromJSONString(props, ".content.adOns.id");
      let pageConfig =
        getObjectFromJSONString(props, ".currentPage.pageConfig") || {};
      let optionSetId = getObjectFromJSONString(
        pageConfig,
        `.fields.find(({ name }) => name == "${id}").optionSet`
      );
      options = getObjectFromJSONString(
        pageConfig,
        `.optionSets.find(({ Id }) => Id == ${optionSetId}).options`
      );
    } catch (error) {
      // Please log error in time of debugging
    }
    setOptionSetDetails(options);
    return options;
  };
  const getDealStatusButtons = async () => {
    const response = await httpCall.httpPost(
      `/CRMData/DealPermission?currentStatus=${props.data.currentStatus}`
    );
    if (response) setDealStatusButton(response);
  };

  React.useLayoutEffect(() => {
    getDealStatusButtons();
  }, [props.data.currentStatus]);
  const getData = async () => {
    const response = await httpCall.httpGet(
      `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
    );
    let responseData = await response;

    for (var i = 0; i < responseData.length; i++) {
      if (
        responseData[i].dealChecklist &&
        responseData[i].dealChecklist.checklistCompleted === false &&
        props.data.currentStatus == dealStatusEnums.COMPLETION
      ) {
        return true;
      }
    }
    return false;
  };
  const checkApplicableState = (currentState, stateToVerify) => {
    if (
      getObjectFromJSONString(currentState, `.allowedOperations`) &&
      Array.isArray(currentState.allowedOperations)
    ) {
      return currentState.allowedOperations.includes(stateToVerify);
    }
    return true;
  };
  let updateStatus = async (status,formData={}) => {
    setDisable(true);
    try {
      let {extraPayload} = formStage||{}
      let parsedString = getObjectFromJSONString(enhanceEvalString(
        JSON.stringify(extraPayload),
        formData
      ),"");
      let payload = {
        id: crmId,
        currentStatus: status,
        ...parsedString
      };

      await httpCall.httpPost(
        `/CRMData/updateDealStatus?pageUrl=${pageUrl}`,
        payload
      );
      //eslint-disable-next-line
      if (!!reason.trim()) {
        let reasonPayload = {
          id: crmId,
          label: pageUrl,
          properties: {
            reasonToDecline: reason,
            preCurrentStaus:
              props &&
              props.currentPage &&
              props.currentPage.data &&
              props.currentPage.data.currentStatus
                ? props.currentPage.data.currentStatus
                : "",
          },
        };
        if (getObjectFromJSONString(props, ".content.adOns.id")) {
          reasonPayload.properties[props.content.adOns.id] =
            JSON.stringify(optionSetValue);
        }
        //eslint-disable-next-line
        if (!!getObjectFromJSONString(props, ".content.reasonDetails.field")) {
          reasonPayload.properties[props.content.reasonDetails.field] = reason;
        }

        await httpCall.httpPut("/CRMData/update", reasonPayload);
      }
      if (status.toLowerCase() == "completion") {
        if (
          props.content &&
          props.content.isPE &&
          props.content.isPE === true
        ) {
          props.getCurrentPage({ pageUrl, crmId });
          return;
        }
        const response = await httpCall.httpGet(
          `/DealChecklist/GetDealChecklist/${crmId}?WorkFlow=Closing`
        );
        let responseData = await response;
        if (responseData.length > 0) {
          props.getCurrentPage({ pageUrl, crmId });
          return;
        }
        let newPayload = {
          dealId: crmId,
          dealWorkFlowState: "closing",
          action: "insert",
        };
        await httpCall.httpPost(`/DealChecklist/Insert`, newPayload);
      }
      //TO-Do added location.reload due to getViewTemplate API is throwing historical data not updated data.
      location.reload()
    } catch (error) {
      //Please log if needed in time of debugging
    }
    setDisable(false);
  };
const openFormForDealStatus = (stage)=>{
  let details = data.find((forms)=>forms.state.toLowerCase() === stage.toLowerCase() && forms.formConfig)
  if(details){
    setFormStage(details)
  }else{
    updateStatus(stage)
  }
}
  let setButton = async () => {
    var isDisabled = false;
    if (props.data.currentStatus == dealStatusEnums.COMPLETION) {
      isDisabled = await getData();
    }
    let payload =
      props.data && props.data.statusPipeline ? props.data.statusPipeline : "";
    let response = await httpCall.httpGet(
      `/Common/GetDealWorkFlow?WorkFlowID=${payload}`
    );
    let pipelineData = response && response.items ? response.items : [];
    setData(pipelineData);
    setFormDetails((response &&response.forms)||[])
    for (let index in pipelineData) {
      let currentPipeline = pipelineData[index];
      if (
        currentPipeline.state &&
        props.data &&
        props.data.currentStatus &&
        props.data.currentStatus == currentPipeline.state
      ) {
        setCurrentStateObject(currentPipeline);
        if (
          index > 0 &&
          checkApplicableState(currentPipeline, pipelineData[index - 1].state)
        ) {
          setPre({
            state: pipelineData[index - 1].state,
          });
        }

        if (pipelineData && index < pipelineData.length) {
          let nextIndex = +index + 1;
          if (
            checkApplicableState(currentPipeline, pipelineData[nextIndex].state)
          )
            setNext({
              state: pipelineData[nextIndex].state,
              isDisabled: isDisabled,
            });
        }
        break;
      }
    }
  };

  const renderConfirmPopup = () => {
    return (
      <div>
        {props.content.adOns && !!optionSetDetails.join("").trim() && (
          <A_Select
            key={props.content.adOns.id}
            fullWidth={true}
            variant="standard"
            label={getObjectFromJSONString(props, ".content.adOns.label") || ""}
            helperText={
              getObjectFromJSONString(props, ".content.adOns.helperText") || ""
            }
            fieldHelperText={
              getObjectFromJSONString(
                props,
                ".content.adOns.fieldHelperText"
              ) || ""
            }
            native={true}
            options={optionSetDetails}
            onChange={(selectedValue) => {
              //eslint-disable-next-line
              setOptionSetValue(!!selectedValue.value ? selectedValue : {});
            }}
            {...props.content.adOns}
          />
        )}
        <A_TextField
          label={
            //eslint-disable-next-line
            !!getObjectFromJSONString(props, ".content.reasonDetails.field")
              ? getObjectFromJSONString(props, ".content.reasonDetails.field")
              : dealStatusEnums.REASON_LABEL
          }
          multiline={true}
          fullWidth={true}
          onChange={(event) => {
            setReason(event.target.value);
          }}
        />
        <div className={classes.maindiv}>
          <A_Button
            onClick={() => {
              openFormForDealStatus(changeButtonValue(dealStatusEnums.KILLED, props.content));
            }}
            disabled={
              !reason.trim() ||
              (props.content.adOns ? !optionSetValue.value : false) ||
              disable
            }
            color="primary"
            label="Yes"
            // disabled={downloading}
          />
          <A_Button onClick={handleKillPopup} color="default" label="Close" />
        </div>
      </div>
    );
  };
  let handleKillPopup = () => {
    setDisable(true);
    let data = !killPopup;
    setKillPopup(data);
    setDisable(false);
  };
  return (
    <Box>
      {dealStatusButton && dealStatusButton.IsButtonVisible ? (
        <Box className={classes.box}>
          {/* {data.map} */}
          {props.data &&
          props.data.currentStatus &&
          (props.data.currentStatus ==
            changeButtonValue(dealStatusEnums.TRACKING, props.content) ||
            (props.data.currentStatus === props.initialStage
              ? props.initialStage
              : "")) &&
          data &&
          data.length > 0 &&
          checkApplicableState(currentStateObject, data[0].state) ? (
            <A_Button
              className={classes.Button}
              onClick={() => {
                openFormForDealStatus(data[0].state);
              }}
              endIcon={<ArrowForward />}
              label={changeButtonLabel(data[0].state, props.content)}
              disabled={disable}
              color="primary"
            />
          ) : props.data &&
            props.data.currentStatus &&
            props.data.currentStatus !=
              changeButtonValue(dealStatusEnums.KILLED, props.content) &&
            props.data.currentStatus !=
              changeButtonValue(dealStatusEnums.DEAD, props.content) &&
            dealStatusButton.BackwardMovementEnable &&
            checkApplicableState(
              currentStateObject,
              changeButtonValue(dealStatusEnums.TRACKING, props.content)
            ) ? (
            <A_Button
              className={classes.Button}
              onClick={() => {
                openFormForDealStatus(
                  changeButtonValue(dealStatusEnums.TRACKING, props.content)
                );
              }}
              startIcon={<HistoryRounded />}
              label={changeButtonLabel(
                props.initialStage
                  ? props.initialStage
                  : changeButtonValue(dealStatusEnums.TRACKING, props.content),
                props.content
              )}
              disabled={disable}
            />
          ) : (
            ""
          )}
          {preButton && dealStatusButton.BackwardMovementEnable ? (
            <A_Button
              disabled={disable}
              className={classes.Button}
              onClick={() => {
                openFormForDealStatus(preButton.state);
              }}
              startIcon={<ArrowBack />}
              label={changeButtonLabel(
                preButton.state ? preButton.state : "",
                props.content
              )}
              color="primary"
            />
          ) : (
            ""
          )}
          {nextButton ? (
            <A_Button
              className={classes.Button}
              onClick={() => {
                openFormForDealStatus(nextButton.state ? nextButton.state : "");
              }}
              endIcon={<ArrowForward />}
              label={changeButtonLabel(
                nextButton.state ? nextButton.state : "",
                props.content
              )}
              color="primary"
              disabled={nextButton.isDisabled || disable}
            />
          ) : (
            ""
          )}
          {props.data &&
          props.data.currentStatus &&
          (props.data.currentStatus ==
            changeButtonValue(dealStatusEnums.KILLED, props.content) ||
            props.data.currentStatus ==
              changeButtonValue(dealStatusEnums.DEAD, props.content)) &&
          data &&
          data.length > 0
            ? dealStatusButton.IsRevive &&
              (checkApplicableState(currentStateObject, data[0].state) ? (
                <A_Button
                  className={classes.Button}
                  onClick={() => {
                    openFormForDealStatus(data[0].state);
                  }}
                  disabled={disable}
                  startIcon={<Close />}
                  label={changeButtonLabel("Revive", props.content)}
                />
              ) : (
                <></>
              ))
            : checkApplicableState(
                currentStateObject,
                changeButtonValue(
                  changeButtonValue(dealStatusEnums.KILLED, props.content)
                )
              ) && (
                <A_Button
                  className={classes.Button}
                  onClick={() => {
                    handleKillPopup();
                  }}
                  disabled={disable}
                  startIcon={<Close />}
                  label={changeButtonLabel("Kill", props.content)}
                />
              )}
        </Box>
      ) : (
        <></>
      )}
      <A_Divider></A_Divider>
      <A_SimpleDialog
        open={killPopup}
        title={
          getObjectFromJSONString(props, ".content.killedConfirmation")
            ? props.content.killedConfirmation
            : dealStatusEnums.CONFIRM_KILL
        }
        fullScreen={false}
        height="400px"
        fullwidth={true}
        maxWidth={"xs"}
        onClose={handleKillPopup}
        closePopUp={handleKillPopup}
        dialogContent={renderConfirmPopup()}
      />
      {formStage && (
          <M_ManageEntityForm
            isEditable={true}
            entity={formStage.formConfig || {}}
            crmData={props.currentPage.data}
            callBack={(formData)=>{
              updateStatus(formStage.state,formData)
            }}
            open={!!formDetails}
            refresh={false}
            formResize={false}
            onClose={() => {
              setFormStage(null);
            }}
            responseMessage={getObjectFromJSONString(
              props,
              ".content.responseMessage"
            )}
          />
        )}
    </Box>
  );
}

O_DealStatus.propTypes = {
  data: propTypes.object,
  content: propTypes.object,
  initialStage: propTypes.any,
  getCurrentPage: propTypes.any,
  currentPage: propTypes.any,
};

function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, currentPageActions), dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(O_DealStatus);
