import React from "react";
import PropTypes from "prop-types";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
//www.npmjs.com/package/react-summernote
import "bootstrap/js/dist/modal";
import * as injectJavascript from "./summernoteHelper";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import { makeStyles } from "@material-ui/core/styles";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";

const useStyles = makeStyles(() => ({
  labelSummerNote: {
    padding: 0,
    fontSize: "1rem",
    fontFamily: "inherit",
    fontWeight: 400,
    lineHeight: 1,
    marginTop: "16px",
    marginBottom: "10px",
  },
  reactSummernote: {
    color: "black !important",
    "& p": { marginBottom: 0, color: "black !important" },
    "& .note-btn.dropdown-toggle::after": {
      display: "none",
      color: "black !important",
    },
  },
}));
const A_SummerNote = (props) => {
  const inputFile = React.useRef(null);
  const classes = useStyles();
  let keyIndicator = "";
  let validateKeypress = false;
  let styledRemoved =
    (props && props.removeStyle == false) ||
    (props &&
      props.removeStyle &&
      typeof props.removeStyle == "string" &&
      props.removeStyle.toLowerCase() == "false")
      ? false
      : true;

  const [alertWarning, setalertWarning] = React.useState(
    `This field has a Character limit : ${
      props && props.inputMaxLength && Number(props.inputMaxLength)
    } , Exceeding characters will be trimmed`
  );
  function onBlurHandler() {
    localStorage.removeItem("pasted");
    props &&
      props.inputMaxLength &&
      Number(keyIndicator) === Number(props.inputMaxLength) &&
      onChangeHandler("", props);
  }

  // Additional keys to support
  const allowedKeys = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Backspace",
  ];
  function handleKeyPress(ev) {
    keyIndicator = keyIndicator <= 0 ? 0 : keyIndicator;
    if (Number(keyIndicator) > 0 && ev.key == "Backspace") {
      ev.returnValue = true;
    }
    if (props && Number(props.inputMaxLength) === 0) {
      ev.preventDefault();
    }
    //  if(props && props.inputMaxLength) {validateKeypress=true};
    //   if(props && props.inputMaxLength&&validateKeypress===true){viewValsetter=ev.currentTarget.innerText;};
    if (
      props &&
      props.inputMaxLength &&
      Number(keyIndicator) <= 0 &&
      ev.currentTarget.innerHTML.replace(/(<([^>]+)>)/gi, "") == ""
    ) {
      return true;
    }

    if (props && props.inputMaxLength) {
      if (
        Number(keyIndicator) === 0 &&
        ev.ctrlKey &&
        ev.key.toLowerCase() === "a"
      ) {
        return true;
      }

      if (Number(keyIndicator) === 0 && allowedKeys.includes(ev.key)) {
        return true;
      }
      Number(keyIndicator) === 0 &&
        ev.key != "Backspace" &&
        ev.preventDefault();
      Number(keyIndicator) < 0 && ev.key != "Backspace" && ev.preventDefault();
    }
  }
  React.useEffect(() => {
    if (props && props.inputMaxLength) {
      onChangeHandler(
        Number(props.inputMaxLength) && props.defaultValue != ""
          ? props.defaultValue
          : "",
        props
      );
    }
  }, [props.id]);
  function extractContent(s) {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  // const extractPlainText = (html) => {
  //   const tempElement = document.createElement("div");
  //   tempElement.innerHTML = html;

  //   // Remove all styles and classes from the element
  //   const allElements = tempElement.querySelectorAll("*");
  //   allElements.forEach((element) => {
  //     element.removeAttribute("style");
  //     element.removeAttribute("class");
  //   });

  //   return tempElement.textContent || tempElement.innerText;
  // };

  const handlePaste = (e) => {
    e.preventDefault();
    const plainTextData = e.originalEvent.clipboardData.getData("text/plain");
    let htmlTextsData = e.originalEvent.clipboardData.getData("text/html");
    let NewValue;
    if (htmlTextsData) {
      NewValue = styledRemoved
        ? injectJavascript.summernoteTagRemover(htmlTextsData)
        : htmlTextsData;
    } else {
      NewValue = plainTextData.replace(/\n/g, "<br>");
    }
    if (props.inputMaxLength) {
      let outerValue = e.target.outerHTML;
      outerValue = extractContent(outerValue);
      const remainingLength = Math.max(
        Number(props.inputMaxLength) - outerValue.length,
        0
      );

      if (remainingLength && htmlTextsData) {
        NewValue = injectJavascript.limitHtmlStringWithTags(
          NewValue,
          remainingLength
        );
      } else {
        NewValue = NewValue.substring(0, remainingLength);
      }
    }
    // Creating a range object to get the current selection
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);

    // Inserting the pasted content at the current selection point
    range.deleteContents();
    const pastedNode = document.createElement("div");
    pastedNode.innerHTML = NewValue;
    range.insertNode(pastedNode);

    // Createing a new range at the end of the pasted content
    const newRange = document.createRange();
    newRange.setStartAfter(pastedNode);
    newRange.collapse(true);

    // Removeing the existing selection and add the new range
    selection.removeAllRanges();
    selection.addRange(newRange);
  };

  const onChangeHandler = (value, props) => {
    let hexVal = props.inputMaxLength
      ? injectJavascript.rgbtoHex(value)
      : value;
    let regexValueRemove = props.inputMaxLength
      ? extractContent(hexVal)
      : value;
    props &&
      props.inputMaxLength &&
      regexValueRemove.length === 0 &&
      setalertWarning(
        `You have ${props.inputMaxLength} characters remaining , Maximum character limit : ${props.inputMaxLength}`
      );
    regexValueRemove =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.replace(
        /&[a-zA-Z]+;|&[a-zA-Z]+;/g,
        (match) =>
          ({
            "&lt;": "<",
            "&gt;": ">",
            "&quot;": '"',
            "&apos;": "'",
            "&nbsp;": " ",
            "&amp;": "&",
            "&copy;": "©",
            "&reg;": "®",
            "&trade;": "™",
            "&euro;": "€",
          }[match])
      );

    const textlength =
      props &&
      props.inputMaxLength &&
      regexValueRemove &&
      regexValueRemove.length;
    const inputVal = props && props.inputMaxLength && regexValueRemove;

    const valChange =
      props && props.inputMaxLength && Number(props.inputMaxLength);
    const providerVal =
      props && props.inputMaxLength && inputVal && inputVal.includes("&nbsp")
        ? props &&
          props.inputMaxLength &&
          inputVal.split("&nbsp;").join(" ").slice(0, valChange)
        : props && props.inputMaxLength && inputVal.slice(0, valChange);
    const num =
      props &&
      props.inputMaxLength &&
      inputVal &&
      inputVal.split("&nbsp;").join(" ");
    const differenceVal = props && props.inputMaxLength && num;

    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length > 0 &&
      setalertWarning(
        `You have ${
          valChange - differenceVal.length >= 0
            ? valChange - differenceVal.length
            : "no"
        } characters remaining , Maximum character limit : ${valChange}`
      );
    props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal &&
      differenceVal.length == 0 &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (
      props &&
      props.inputMaxLength &&
      valChange &&
      textlength &&
      typeof textlength != "string" &&
      differenceVal
    ) {
      if (differenceVal && differenceVal.length > 0) {
        keyIndicator = valChange - differenceVal.length;
      } else if (differenceVal && differenceVal.length == 0) {
        keyIndicator = valChange;
      }
    }
    props &&
      props.inputMaxLength &&
      typeof textlength === "string" &&
      setalertWarning(
        `You have ${valChange} characters remaining , Maximum character limit : ${valChange}`
      );
    if (props && props.inputMaxLength) {
      let dataMax = "";
      if (inputVal.length > props.inputMaxLength) {
        dataMax = injectJavascript.limitHtmlStringWithTags(
          hexVal,
          inputVal.length
        );
      } else {
        dataMax = hexVal;
      }

      dataMax = dataMax ? injectJavascript.rgbtoHex(dataMax) : dataMax;
      dataMax =
        dataMax && styledRemoved
          ? injectJavascript.cleanHTML(dataMax)
          : dataMax;
      dataMax = injectJavascript.removeEmptyTags(dataMax);
      props.onChange(
        props &&
          props.inputMaxLength &&
          inputVal &&
          providerVal &&
          validateKeypress === false &&
          dataMax,
        props.id
      );
    } else {
      value = value ? injectJavascript.rgbtoHex(value) : value;
      value = injectJavascript.removeEmptyTags(value);
      let CleanHtml =
        value && styledRemoved ? injectJavascript.cleanHTML(value) : value;
      props.onChange(CleanHtml, props.id);
    }
  };

  return (
    <>
      <label className={classes.labelSummerNote}>
        {props.required ? props.label + "*" : props.label}
      </label>
      <span>
        <A_FormHelper fieldHelperText={props.fieldHelperText} />
      </span>
      <ReactSummernote
        disabled={props.disabled}
        tag="textarea"
        className={classes.reactSummernote}
        options={{
          height: props.height,
          dialogsInBody: true,
          dragAndDrop: false,
          disableDragAndDrop: true,
          dialogsFade: true,
          toolbar: [
            ["style", ["bold", "italic", "underline", "clear"]],
            ["color", ["color", "reset"]],
            [
              "image",
              [
                "image",
                "insert",
                "dragImageHere",
                "dropImage",
                "selectFromFiles",
                "url",
              ],
            ],
            ["para", ["ul", "ol", "paragraph", "listStyles"]],
            ["history", ["undo", "redo"]],
            ["table", ["table"]],
          ],
        }}
        ref={inputFile}
        onPaste={(e) => handlePaste(e)}
        onBlur={(e) => onBlurHandler(e, props)}
        onChange={(e) => onChangeHandler(e, props)}
        onKeyDown={(ev) => {
          ev.stopPropagation(), handleKeyPress(ev);
        }}
      >
        {props.defaultValue}
      </ReactSummernote>
      {props && props.inputMaxLength && (
        <p style={{ fontSize: "0.75rem" }}>{alertWarning}</p>
      )}
    </>
  );
};
A_SummerNote.defaultProps = {
  label: "SummerNote",
};
A_SummerNote.propTypes = {
  label: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  height: PropTypes.string,
  inputMaxLength: PropTypes.any,
  id: PropTypes.any,
  required: PropTypes.any,
  fieldHelperText: PropTypes.string,
  removeStyle: PropTypes.any,
};
export default A_SummerNote;
