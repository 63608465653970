import React from "react";
import { Box } from "@material-ui/core";
import { useState } from "react";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_CheckBox from "../../../Atoms/Inputs/Checkboxes/A_Checkbox";
import A_CustomMultiSelectChip from "../../../Atoms/Inputs/MultiSelectChipCustom/A_CustomMultiSelectChip";
import PropTypes from "prop-types";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import {
  filterPayload,
  investorType,
  RelationShip,
} from "./FieldsForIRReports";
import { useEffect } from "react";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useDispatch } from "react-redux";
import { getIrReportValue, irReportToggleOn } from "../../../../../store/actions/irReportActions";
import { irRePortFormEnums } from "./irReport.helper";

function IrReportForm(props) {
  const { content } = props;
  const [formValue, setformValue] = useState({});
  const [filterValue, setFilterValue] = useState(filterPayload);
  const [country, setCountry] = useState([]);
  const [owner, setOwner] = useState([]);
  const dispatch = useDispatch();

  // TODO : To be used in future
  const getOptionValue = (value, name) => {
    let selectedValues = [];
    if (
      name === irRePortFormEnums.INVESTOR_TYPE_NAME ||
      name === irRePortFormEnums.RELATIONSHIP_NAME
    ) {
      value.map((val) => {
        selectedValues.push({ Id: val.sno, label: val.label, value: val.id });
      });
    }
    return selectedValues;
  };

  const handleSelectChange = (value, name) => {
    // setformValue({ ...formValue, [name]: value})
    // TODO : to be handled in future
    let selectedValues = [];
    if (
      name === irRePortFormEnums.INVESTOR_TYPE_NAME ||
      name === irRePortFormEnums.RELATIONSHIP_NAME
    ) {
      selectedValues = getOptionValue(value, name);
    } else {
      selectedValues = value.map((val) => val.id);
    }
    setformValue({ ...formValue, [name]: selectedValues });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setformValue({ ...formValue, [name]: value });
  };

  const handleCheckboxChange = (value, id, name) => {
    if (
      name === "ana_mail_pref_agm" ||
      name === "ana_mail_pref_announcements" ||
      name === "ana_mail_pref_legal" ||
      name === "ana_mail_pref_LPAC" ||
      name === "ana_investor_pe" ||
      name === "ana_investor_credit"
    ) {
      setformValue({ ...formValue, [name]: value });
    } else if (
      name === "Ana_InterestinPEFund" ||
      name === "CoinvestProbability" ||
      name === "Ana_InterestinDebtFund" ||
      name === "ana_InterestinCreditIncomeFund" ||
      name === "Ana_DebtConinvestProbability"
    ) {
      setformValue({ ...formValue, [name]: value ? "1" : "0" });
    } else {
      setformValue({ ...formValue, [name]: value ? 1 : 0 });
    }
  };

  const getOwnerOption = (owner) => {
    return (
      owner &&
      owner.map((option) => {
        return {
          id: option.id,
          label: `${option["First Name"]} ${option["Last Name"]}`,
        };
      })
    );
  };

  useEffect(async () => {
    const countryResponse = await httpPost(
      "/GenericGremlin/generic/getCountries"
    );
    const ownerResponse = await httpPost(
      "/GenericGremlin/generic/getCompanyOwners"
    );
    setCountry(countryResponse);
    owner && setOwner(getOwnerOption(ownerResponse));
  }, []);

  useEffect(() => {
    const { has_country, owner, ...rest } = formValue;
    Object.keys(rest).map((i) => {
      if (rest[i]) {
        if (Array.isArray(rest[i]) && rest[i].length == 0) {
          delete rest[i];
        }
      } else {
        delete rest[i];
      }
    });
    setFilterValue((prevVal) => ({
      ...prevVal,
      properties: rest || {},
      edges: [
        ...(has_country && has_country.length
          ? [
              {
                label: "has_country",
                guid: has_country,
                "two-way-label": null,
                inverse: false,
              },
            ]
          : []),
        ...(owner && owner.length
          ? [
              {
                label: "owner",
                guid: owner,
                "two-way-label": null,
                inverse: false,
              },
            ]
          : []),
      ],
    }));
  }, [formValue]);

  const handleSubmit = async () => {
    dispatch(irReportToggleOn());
    dispatch(getIrReportValue(filterValue));
  };
  return (
    <div>
      <A_CustomMultiSelectChip
        name={irRePortFormEnums.HAS_COUNTRY}
        label={irRePortFormEnums.COUNTRY}
        width={"100%"}
        options={country}
        onChange={handleSelectChange}
      />

      <A_CustomMultiSelectChip
        name={irRePortFormEnums.OWNER}
        label={irRePortFormEnums.COVERAGE}
        width={"100%"}
        options={owner}
        onChange={handleSelectChange}
      />

      <A_CustomMultiSelectChip
        name={irRePortFormEnums.INVESTOR_TYPE_NAME}
        label={irRePortFormEnums.INVESTOR_TYPE_LABEL}
        width={"100%"}
        options={investorType}
        onChange={handleSelectChange}
      />

      <A_CustomMultiSelectChip
        name={irRePortFormEnums.RELATIONSHIP_NAME}
        label={irRePortFormEnums.RELATIONSHIP_LABEL}
        width={"100%"}
        options={RelationShip}
        onChange={handleSelectChange}
      />

      {content.filters &&
        content.filters.map((val) => {
          return (
            <>
              {val.type == irRePortFormEnums.STRING && (
                <Box>
                  <A_TextField
                    name={val.name}
                    type="text"
                    fullWidth={true}
                    label={val.label}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {val.type == irRePortFormEnums.NUMERIC && (
                <Box>
                  <A_TextField
                    name={val.name}
                    type="number"
                    label={val.label}
                    onChange={handleInputChange}
                  />
                </Box>
              )}
              {val.type == irRePortFormEnums.HEADING && <h5>{val.label}</h5>}
              {val.type == irRePortFormEnums.CHECKBOX && (
                <A_CheckBox
                  name={val.name || val.label}
                  label={val.label}
                  onChange={handleCheckboxChange}
                  checked={false}
                />
              )}
            </>
          );
        })}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 0",
        }}
      >
        <A_Button label="Apply Filter" color="primary" onClick={handleSubmit}>
          submit
        </A_Button>
      </div>
    </div>
  );
}

IrReportForm.propTypes = {
  content: PropTypes.object,
};
export default IrReportForm;