import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import _ from "lodash";
import { ThemeProvider } from "@material-ui/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import Checkbox from "@material-ui/core/Checkbox";
import SearchIcon from "@material-ui/icons/Search";
import A_TextField from "../../../Atoms/Inputs/TextFields/A_TextField";
import A_Select from "../../../Atoms/Inputs/Selects/A_Select";
import theme from "../../../../Themes/defaultTheme";
import { withStyles } from "@material-ui/core/styles";
import * as common from "../../../../../Utils/Helpers/common";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import Rag from "../../../Molecules/Common/Rag/Rag";
import PopDealDetails from "../../../Molecules/Common/Rag/PopDealDetails";
import A_Button from "../../Inputs/Buttons/A_Button";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { httpPost } from "../../../../../Utils/apis/apis";
import { useSelector } from "react-redux";
import { Avatar, Popover } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { saveCheckboxData, approveSelected } from "./CheckboxService.helper";
import A_Snackbar from "../../Feedback/Snackbars/A_Snackbar";
import { renderTextField } from "../../Inputs/TextFields/A_RenderTextField";
import A_ReadMore from "./A_ReadMore";
import { getOnBlurData, isLinkTypeData, ShowHide } from "./A_TableChart.Helper";
import { FromEpochFormat } from "../../../../../Utils/Helpers/dateUtils";
import TableFunction from "./helper/TableFunction";
import descendingComparator from "./helper/descendingComparator";
import getBorderGroupBased from "./helper/getBorderGroupBased";
import getStyleBasedOnColumType from "./helper/getStyleBasedOnColumType";
import checkDefaultColumnConfiguration from "./helper/checkDefaultColumnConfiguration";
import useStyles from "./helper/useStyles";
import useToolbarStyles from "./helper/useToolbarStyles";
import getGroupArr from "./helper/getGroupArr";
import getColorObject from "./helper/getColorObject";
import columnDataHandle from "./helper/columnDataHandle";
import { Link } from "react-router-dom";
import M_InvoiceSaveButton from "../../../Molecules/Chart/InvoiceSaveButton/M_InvoiceSaveButton";
import { message } from "../../../../../Utils/config/messages";
import { budgetLinecalculations } from "./helperTexts";
import {
  TableChartRowKey,
  InsiderButtons,
} from "../../../../../Utils/config/config";
import M_ActionGroup from "../../../Molecules/Common/ActionGroup/M_ActionGroup";
import { useParams } from "react-router";
import getMonthYearForGroup from "./helper/dateFunctions";
import A_TableApproval from "./A_TableApproval";
import A_TablePdfDownload from "./A_TablePdfDownload";
import GetAppIcon from "@material-ui/icons/GetApp";
import LockIcon from "@material-ui/icons/Lock";
import { insiderButtonsOpertions, insiderPDFDownload } from "./Insider.helper";
import IconList from "../Lists/IconList";
import { handleOptionSetValue } from "../../../../../Utils/Helpers/optionSet.helper";
import { getCRMContactInfo } from "../../../../../Utils/Helpers/common";
import "./A_TableChart.css";
import { enhanceEvalString } from "../../../Molecules/Common/Renderers/Views/ResultCards/M_ResultCardHelper";
import genericDoc from "../../../../../Assets/Images/Defaults/genericDoc.png";
import { executeJSString } from "../../../Pages/Common/Layouts/layout.Helper";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, rowValue, dimensionName) {
  const arrayCheck = array && array.length > 0;
  let finalArray =
    rowValue &&
    arrayCheck &&
    array.filter(
      (item) => item[dimensionName] && item[dimensionName] !== rowValue
    );
  let removeArray =
    rowValue &&
    arrayCheck &&
    array.filter(
      (item) => item[dimensionName] && item[dimensionName] === rowValue
    );
  const stabilizedThis =
    removeArray && Array.isArray(removeArray) && removeArray.length === 1
      ? finalArray && finalArray.map((el, index) => [el, index])
      : array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  removeArray && Array.isArray(removeArray) && removeArray.length === 1
    ? stabilizedThis.push(removeArray)
    : "";
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    col,
    action,
    allowSelection,
    dimensionName,
    dimensionConfig,
    Groups,
    groupLabel,
    displayAction,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const renderColumnGroups = (Groups) => {
    return (
      <TableRow>
        {allowSelection &&
        (allowSelection.selectRow || allowSelection.selectAll) ? (
          <TableHeaderCell align="left" colSpan={1}></TableHeaderCell>
        ) : (
          ""
        )}
        {dimensionConfig && groupLabel ? (
          <TableHeaderCell align="left" colSpan={1}>
            {groupLabel ? groupLabel : ""}
          </TableHeaderCell>
        ) : (
          ""
        )}
        {Groups.map((item, index) => {
          return (
            <TableHeaderCell
              align={item.span > 1 ? "center" : "center"}
              colSpan={item.span}
              style={{ padding: item.padding ? item.padding : "" }}
              key={index}
            >
              {item.label === "" ? getMonthYearForGroup() : item.label}
            </TableHeaderCell>
          );
        })}

        {action && (action.edit || action.delete) && displayAction ? (
          <TableHeaderCell></TableHeaderCell>
        ) : (
          ""
        )}
      </TableRow>
    );
  };
  const renderSelectAllCheckBox = () => {
    return allowSelection &&
      (allowSelection.selectRow || allowSelection.selectAll) ? (
      <TableHeaderCell>
        {allowSelection.selectAll ? (
          <div className={classes.checkCustom}>
            <Checkbox
              color="primary"
              style={{ padding: "0px", margin: "0px" }}
              iconStyle={{ fill: "white" }}
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </div>
        ) : (
          ""
        )}
      </TableHeaderCell>
    ) : (
      ""
    );
  };
  const renderDimentionHeaderLabel = () => {
    if (dimensionConfig && dimensionConfig.hide) {
      return "";
    }
    const { groupArr } = props;
    let borderR = "";
    borderR = getBorderGroupBased(groupArr, 1, "dimention");
    let width =
      dimensionConfig && dimensionConfig.width ? dimensionConfig.width : "";
    let padding =
      dimensionConfig && dimensionConfig.padding ? dimensionConfig.padding : "";
    let position =
      dimensionConfig && dimensionConfig.columnPinning ? "sticky" : "";
    let left = dimensionConfig && dimensionConfig.columnPinning ? 0 : "";
    let dimensionConfigId =
      dimensionConfig && dimensionConfig.id
        ? dimensionConfig.id
        : dimensionName;
    let dimensionConfigLabel =
      dimensionConfig && dimensionConfig.label
        ? dimensionConfig.label
        : dimensionName;
    return dimensionName && dimensionConfig && !dimensionConfig.sorting ? (
      <TableHeaderCell
        style={{
          position: position,
          left: left,
          backgroundColor:
            dimensionConfig && dimensionConfig.columnPinning
              ? style.background
              : "",
          borderRight: borderR,
          padding: padding,
          minWidth: width,
        }}
        key={dimensionConfigId}
      >
        {dimensionConfigLabel}
      </TableHeaderCell>
    ) : (
      <TableHeaderCell
        key={dimensionConfigLabel}
        sortDirection={orderBy === dimensionConfigLabel ? order : false}
        style={{
          position: position,
          left: left,
          backgroundColor:
            dimensionConfig && dimensionConfig.columnPinning
              ? style.background
              : "",
          borderRight: borderR,
          padding: padding,
          minWidth: width,
        }}
      >
        <TableHeaderSortLabel
          // active={orderBy === col[headCell].id}
          direction={orderBy === dimensionConfigId ? order : "asc"}
          onClick={createSortHandler(dimensionConfigId)}
        >
          {dimensionConfigLabel}
          {orderBy === dimensionConfig && dimensionConfig.id ? (
            dimensionConfig.id
          ) : dimensionName ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableHeaderSortLabel>
      </TableHeaderCell>
    );
  };

  const TableHeaderCell = withStyles(() => ({
    root: {
      color: style && style.color ? style.color : "",
    },
  }))(TableCell);
  const TableHeaderSortLabel = withStyles(() => ({
    root: {
      "&:hover": {
        color: "white",
      },
    },
  }))(TableSortLabel);

  const renderColumHeader = (props) => {
    const { groupArr } = props;

    const sortArrbasedOnCategory = (col, props) => {
      return props.resultData &&
        props.resultData.categories &&
        props.resultData.categories.length > 0
        ? props.resultData.categories.filter((item) => col[item])
        : [];
    };
    return col
      ? sortArrbasedOnCategory(col, props).map((headCell, index) => {
          let borderR = "";
          borderR = getBorderGroupBased(groupArr, index + 1, null);
          // let textAlignHeader = col[headCell].textAlignHeader
          //   ? col[headCell].textAlignHeader
          //   : "";
          let padding = col[headCell].padding ? col[headCell].padding : "";
          let width = col[headCell].width ? col[headCell].width : "";
          return !col[headCell].hide ? (
            col[headCell].sorting ? (
              <TableHeaderCell
                key={col[headCell].id}
                sortDirection={orderBy === col[headCell].id ? order : false}
                style={{
                  // textAlign: textAlignHeader,
                  padding: padding,
                  minWidth: width,
                  ...getStyleBasedOnColumType(col[headCell]),
                  borderRight: borderR,
                }}
              >
                <TableHeaderSortLabel
                  // active={orderBy === col[headCell].id}
                  style={{ position: "unset" }}
                  direction={orderBy === col[headCell].id ? order : "asc"}
                  onClick={createSortHandler(col[headCell].id)}
                >
                  {col[headCell].label}
                  {orderBy === col[headCell].id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null}
                </TableHeaderSortLabel>
              </TableHeaderCell>
            ) : (
              <TableHeaderCell
                key={col[headCell].id}
                style={{
                  ...getStyleBasedOnColumType(col[headCell]),
                  borderRight: borderR,
                  // textAlign: textAlignHeader,
                  padding: padding,
                  minWidth: width,
                }}
              >
                {col[headCell].label}
              </TableHeaderCell>
            )
          ) : (
            ""
          );
        })
      : "";
  };

  const { chartTheme } = common.getTheme() ? common.getTheme() : {};
  const { TableChart } = chartTheme ? chartTheme : {};
  const { style } = TableChart && TableChart.header ? TableChart.header : {};

  const TableHeader = withStyles(() => ({
    root: {
      backgroundColor: style && style.background ? style.background : "",
      ...(props && props.configurations && props.configurations.headerFreeze
        ? {
            position: "sticky",
            zIndex: "1",
            top: "0",
            border: "0",
            backdropFilter: "blur(1pc)",
          }
        : {}),
    },
  }))(TableHead);

  return (
    <TableHeader>
      {Groups && Groups.length > 0 ? renderColumnGroups(Groups) : ""}

      <TableRow className={classes.checkBoxSelected}>
        {renderSelectAllCheckBox()}
        {renderDimentionHeaderLabel(props)}
        {renderColumHeader(props)}
        {action && (action.edit || action.delete) ? (
          action.columnPinning == true ? (
            <TableHeaderCell
              style={{
                position: "sticky",
                right: "0",
                backgroundColor:
                  style && style.background ? style.background : "",
              }}
            ></TableHeaderCell>
          ) : (
            <TableHeaderCell></TableHeaderCell>
          )
        ) : (
          ""
        )}
      </TableRow>
    </TableHeader>
  );
}
//To-Do fix lint issue
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  col: PropTypes.object.isRequired,
  allowSelection: PropTypes.any,
  action: PropTypes.any,
  dimensionName: PropTypes.object,
  dimensionConfig: PropTypes.object,
  Groups: PropTypes.object,
  groupLabel: PropTypes.any,
  groupArr: PropTypes.array,
  resultData: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  displayAction: PropTypes.any,
  configurations: PropTypes.object,
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { title } = props.config;
  const { searchable } = props.config;
  const { addButton } = props.config;
  const { filters } = props.config;
  const { data } = props;

  const handleChangeSearch = (event) => {
    // return results
    props.onChange(event, data);
  };

  const handleOnChangeFilter = (e, node) => {
    let value = e.value;
    let key = node;
    props.onFilterChange(key, value, filters);
  };

  return (filters && filters.length > 0) || searchable || title || addButton ? (
    <Toolbar style={{ float: "right", minWidth: "55%", height: 2 }}>
      <div style={{ minWidth: title ? "20%" : "10%" }}>
        <h3>{title}</h3>
      </div>
      <div
        style={{
          marginRight: "5%",
          display: "inline-flex",
          position: "relative",
          right: "12%",
          marginTop: "120",
          marginLeft: "10px",
        }}
      >
        {filters && filters.length > 0
          ? filters.map((item) => (
              <A_Select
                key={item}
                className={classes.selectBox}
                paramName={item.parameter}
                onChange={(e) => handleOnChangeFilter(e, item.parameter)}
                defaultValue={
                  props.parameters && props.parameters[item.parameter]
                    ? props.parameters[item.parameter]
                    : item.active
                }
                options={item.options}
              />
            ))
          : ""}
      </div>
      {searchable ? (
        <A_TextField
          className={classes.searchBox}
          onChange={handleChangeSearch}
          label=""
          type="search"
          variant="outlined"
          adornment={{ position: "start", value: <SearchIcon /> }}
        />
      ) : (
        ""
      )}
      {addButton ? (
        <AddIcon
          id="addButton"
          className={classes.addButton}
          onClick={props.handleAddButtonClick}
        />
      ) : (
        ""
      )}
    </Toolbar>
  ) : (
    ""
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  action: PropTypes.any,
  allowSelection: PropTypes.any,
  dimensionName: PropTypes.object,
  dimensionConfig: PropTypes.object,
  Groups: PropTypes.object,
  groupLabel: PropTypes.any,
  groupArr: PropTypes.array,
  resultData: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  parameters: PropTypes.any,
  handleAddButtonClick: PropTypes.func,
  configurations: PropTypes.any,
};

const A_TableChart = (props) => {
  let pdfName =
    props &&
    props.configurations &&
    props.configurations.pdfDownloadButton &&
    props.configurations.pdfDownloadButton.pdflabel
      ? props.configurations.pdfDownloadButton.pdflabel
      : "";
  let insiderLabel =
    props &&
    props.configurations &&
    props.configurations.confirmUptoDateButton &&
    props.configurations.confirmUptoDateButton.label
      ? props.configurations.confirmUptoDateButton &&
        props.configurations.confirmUptoDateButton.label
      : "";
  let fileName =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data[pdfName]
      ? `${props.currentPage.data[pdfName]}.pdf`
      : "newFile.pdf";
  const user = useSelector((state) => state.oidc.user);
  let { crmContactUserId } = getCRMContactInfo();
  const userid = useSelector((state) =>
    state &&
    state.getLoggedInUserReducer &&
    state.getLoggedInUserReducer.data &&
    state.getLoggedInUserReducer.data[0] &&
    state.getLoggedInUserReducer.data[0].id
      ? state.getLoggedInUserReducer.data[0].id
      : crmContactUserId
  );
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  var currentuser = loggedInUserData ? loggedInUserData : "";
  let buttonProperty =
    props &&
    props.configurations &&
    props.configurations.InsiderListClosed &&
    props.configurations.InsiderListClosed.buttonKey
      ? props.configurations.InsiderListClosed.buttonKey
      : "Insider List Closed";
  let buttonLabel =
    props &&
    props.currentPage &&
    props.currentPage.data &&
    props.currentPage.data[buttonProperty]
      ? props.currentPage.data[buttonProperty]
      : "";
  const classes = useStyles();
  let { pageUrl, crmId } = useParams();
  const columnConfig =
    props.configurations && props.configurations.columnConfig
      ? checkDefaultColumnConfiguration(
          props.configurations.columnConfig,
          props.resultData
        )
      : checkDefaultColumnConfiguration(undefined, props.resultData);
  const { configurations } = props;
  configurations["columnConfig"] = columnConfig;
  const {
    dimension,
    approvalOptions,
    multiSelectionOperations,
    showHeader,
    saveButton,
    Groups,
    groupLabel,
    action,
    allowSelection,
    striped,
    readOnlyTemplate = false,
  } = configurations;
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [selectedData, setSelectedData] = React.useState([]);
  const [changesSaved, setChangesSaved] = React.useState({
    status: false,
    msg: "",
    type: "success",
  });
  const [openPopOver, setOpenPopOver] = React.useState(false);
  const dense =
    props.configurations && props.configurations.condensed
      ? props.configurations.condensed
      : false;
  //this will make it uneditable for anyone except the fundaccountant, that is budget allocation tablechart is read only for every other users
  const fundAccountant = localStorage.getItem("fundaccountantEmail")
    ? localStorage.getItem("fundaccountantEmail")
    : "";
  const currentUseremail =
    user && user.profile && user.profile.email && user.profile.email;

  fundAccountant &&
  fundAccountant.includes(currentUseremail && currentUseremail)
    ? props &&
      props.configurations.columnConfig &&
      props.configurations.columnConfig[budgetLinecalculations.THISINVOICE]
      ? (props.configurations.columnConfig[
          budgetLinecalculations.THISINVOICE
        ].textField = true)
      : ""
    : props &&
      props.configurations.columnConfig &&
      props.configurations.columnConfig[budgetLinecalculations.THISINVOICE]
    ? (props.configurations.columnConfig[
        budgetLinecalculations.THISINVOICE
      ].textField = false)
    : "";
  const isBoolean = (val) => "boolean" === typeof val;
  const defaultPage =
    configurations &&
    configurations.paginate &&
    isBoolean(configurations.paginate)
      ? 5
      : configurations.paginate;
  const rowsOption = [5, 10, 25, 50];
  const value = rowsOption.includes(defaultPage) ? defaultPage : 5;
  const checkPaginate =
    (configurations && configurations.paginate) ||
    configurations.paginate === 0 ||
    configurations.paginate === "";
  const [rowsPerPage, setRowsPerPage] = React.useState(
    checkPaginate
      ? value
      : props.data && props.data.length > 5
      ? props.data.length + 1
      : 5
  );
  const [data, setData] = React.useState(props.data);
  const [payloadBudgetlines, setpayloadBudgetlines] = React.useState(null);
  const [invoiceAmountfix, setinvoiceAmountfix] = React.useState(null);
  const [invoiceHasDifference, setInvoiceHasDifference] = React.useState(null);
  const [invoiceHasDifferenceAmount, setInvoiceHasDifferenceAmount] =
    React.useState(null);
  const [isDisable, setIsDisable] = React.useState(false);
  const [buttonDisable, setButtonDisabled] = React.useState(false);
  const { backgroundColor } = props.configurations;
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const dataOrignal = props.data;
  let dimensionName =
    props.resultData &&
    props.resultData.dimensions &&
    props.resultData.dimensions.length > 0
      ? props.resultData.dimensions[0]
      : "";

  let groupArr = getGroupArr(Groups);

  useEffect(() => {
    if (dataOrignal.length == 1 && dataOrignal[0].Name == "Total") {
      setData([]);
    }
  }, [props.data]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handlePdfDownload = () => {
    insiderPDFDownload(crmId, fileName, setIsDisable);
  };

  const handleCloseInsider = (type) => {
    insiderButtonsOpertions(props, crmId, pageUrl, userid, callBack, type);
  };
  const handleConfirmButtonInInsider = (type) => {
    insiderButtonsOpertions(
      props,
      crmId,
      pageUrl,
      userid,
      callBack,
      type,
      setButtonDisabled
    );
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((data, index) => index);
      const newSelectedids = data.map(
        (data) =>
          data[
            approvalOptions && approvalOptions.id ? approvalOptions.id : "id"
          ]
      );
      setSelected(newSelecteds);
      setSelectedData(newSelectedids);
      return;
    }
    setSelected([]);
    setSelectedData([]);
  };
  async function handleUnselectAllClick(event) {
    let selectedButton = allowSelection.buttonOperations.filter((item) => {
      return (
        item.showButtonLabel.toLowerCase() ==
        event.currentTarget.innerText.toLowerCase()
      );
    });
    if (selectedData && selectedData.length > 0) {
      setSelected([]);
      await saveCheckboxData(selectedData, selectedButton, callBack);
      setSelectedData([]);
    }
  }
  const handleApproveClick = async (
    clickedBy,
    edgeLabel,
    properties,
    additionalPayloads = {},
    responseMessage = "",
    addOnDetails = {}
  ) => {
    if (selectedData && selectedData.length > 0) {
      let selectRowDetails = selectedData
        .map((crmId) => {
          try {
            let parsedString = enhanceEvalString(
              JSON.stringify(additionalPayloads),
              data.find((item) => item.id === crmId)
            );
            return JSON.parse(parsedString);
          } catch (error) {
            return null;
          }
        })
        .filter((item) => item);
      let selectRowAddOns = selectedData
        .map((crmId) => {
          try {
            let parsedString = enhanceEvalString(
              JSON.stringify(addOnDetails),
              data.find((item) => item.id === crmId)
            );
            return JSON.parse(parsedString);
          } catch (error) {
            return null;
          }
        })
        .filter((item) => item);
      await approveSelected(
        selectedData,
        invoiceApprovalCallback,
        clickedBy,
        edgeLabel,
        properties,
        selectRowDetails,
        responseMessage,
        selectRowAddOns
      );
      setSelected([]);
      setSelectedData([]);
    }
  };
  const invoiceApprovalCallback = (msg) => {
    setChangesSaved({
      status: true,
      msg: msg,
      type: "success",
    });
    props.callback && props.callback();
  };
  const callBack = (message) => {
    setChangesSaved({
      status: true,
      msg: message,
      type: "success",
    });
    setTimeout(location.reload.bind(location), 1000);
  };
  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    const value = selectedData.find(
      (item) =>
        item ===
        row[approvalOptions && approvalOptions.id ? approvalOptions.id : "id"]
    );
    if (value) {
      let index = selectedData.indexOf(
        row[approvalOptions && approvalOptions.id ? approvalOptions.id : "id"]
      );
      selectedData.splice(index, 1);
    } else {
      if (
        row[approvalOptions && approvalOptions.id ? approvalOptions.id : "id"]
      ) {
        selectedData.push(
          row[approvalOptions && approvalOptions.id ? approvalOptions.id : "id"]
        );
      }
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelectedData(selectedData);
    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    if (!event.target || !event.target.value || event.target.value == "") {
      setData(dataOrignal);
      return;
    }

    let valuSearch = event.target.value;
    let results = [];
    for (var i = 0; i < dataOrignal.length; i++) {
      for (let key in dataOrignal[i]) {
        if (
          dataOrignal[i][key] &&
          dataOrignal[i][key]
            .toString()
            .toLowerCase()
            .indexOf(valuSearch.toLowerCase()) != -1
        ) {
          results.push(dataOrignal[i]);
          break;
        }
      }
    }

    setData(results);
    setPage(0);
  };

  const loadChart = (event, config, data, row) => {
    let showDetail =
      props.configurations && props.configurations.showDetail
        ? props.configurations.showDetail
        : {};

    let cellConfiguration = config;
    props.handleClickableCell(event, cellConfiguration, showDetail, data, row);
  };
  const numberWithCommas = (num) => {
    if (num && typeof num === "string" && num.search("/") > -1) {
      return num;
    }
    if (num !== null) {
      let str = num.toString().split(".");
      str[0] = str[0] ? str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") : null;
      return str.join(".");
    } else {
      return num;
    }
  };

  const getOffsetObject = (config) => {
    return config.varianceFormat &&
      config.varianceFormat.thresholds &&
      config.varianceFormat.thresholds.length > 0
      ? config.varianceFormat.thresholds
      : [0, 0];
  };

  const getColorBasedOnConfigs = (res, offsetObject, offsetColorObject) => {
    let tempRes = null;
    if (res && !isNaN(res) && parseFloat(res)) {
      tempRes = parseFloat(res);
    } else {
      if (res && res === "-0") {
        return offsetColorObject[0];
      } else if (res && res === "0") {
        return offsetColorObject[1];
      }
    }
    return tempRes && tempRes >= offsetObject["1"]
      ? offsetColorObject["1"]
      : tempRes < offsetObject["0"]
      ? offsetColorObject["0"]
      : offsetColorObject["1"];
  };
  const validateNumber = (data, decimalPlaces = 0) => {
    if (data == " " && !isNaN(data) && typeof data == "string") {
      const d = Math.pow(10, decimalPlaces);
      let result = Math.round((parseFloat(data) + Number.EPSILON) * d) / d;
      if (result === 0) {
        result = result + ".";
        while (decimalPlaces--) {
          result += "0";
        }
      }
      return result;
    }
    return data;
  };
  const rangeColor = (rcolor, res) => {
    let color = "";
    if (res != null && rcolor && rcolor.sign) {
      for (const item of rcolor.sign) {
        const value = item.range ? item.range.split("-").map(Number) : [];
        const result = Number(res);
        if (result < 0) {
          color = "red";
          break;
        } else if (result >= value[0] && result <= value[1]) {
          color = item.color || "";
          break;
        }
      }
    }
    return color;
  };
  const formatDataBasedOnType = (
    data,
    config,
    variance,
    row,
    rowType = null,
    rowDataConfig = null
  ) => {
    let configuration = config;
    var decimalPlaces = 0;
    let color = "";
    let type = rowType ? rowType : configuration["type"];
    let offsetObject = { ...getOffsetObject(config) };
    let offsetColorObject = {
      ...getColorObject(config, props, row, dimensionName),
    };
    let colorCell = config.variance ? true : false;
    if (rowDataConfig) {
      let { decimal } = rowDataConfig;
      config["decimal"] = decimal ? decimal : config["decimal"];
    }

    if (configuration && configuration.clickable) {
      return (
        <a
          onClick={(event) => loadChart(event, config, data, row)}
          href="javascript:void(0)"
        >
          {" "}
          {data}
        </a>
      );
    } else if (configuration && type == "text") {
      return configuration && configuration.defaultValueshow
        ? columnDataHandle(data, configuration)
        : data;
    } else if (configuration && (type == "number" || type == "numeric")) {
      decimalPlaces = configuration.decimal ? configuration.decimal : 0;
      let res = data;
      color =
        colorCell && offsetObject && type == "number"
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      data = validateNumber(data, decimalPlaces);
      res = columnDataHandle(res, config);
      res = res ? res.toLocaleString("en-GB") : res;
      res = res ? numberWithCommas(res) : res;
      res = type == "number" ? TableFunction(res) : res;
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "multiple") {
      data = validateNumber(data);
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 2)
          : data;

      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 0)
          : data;
      res = res ? numberWithCommas(res) : res;
      res = res && res != "-" ? res + "x" : "";
      res = columnDataHandle(res, config);
      res = TableFunction(res);
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "date") {
      let date =
        typeof data === "string"
          ? Number(data)
          : data === undefined || data === 0
          ? ""
          : data;
      return configuration && configuration.dateFormat
        ? getFormattedDate(
            FromEpochFormat(date),
            config.dateFormat,
            configuration.dateFormat
          )
        : getFormattedDate(FromEpochFormat(date), config.dateFormat, "MMM-yy");
    } else if (
      configuration &&
      (type == "percentage" || type == "Percentage")
    ) {
      data = validateNumber(data);
      data = data ? data * (!config.scaling ? 100 : config.scaling) : data;
      let res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 1)
          : data;
      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : rangeColor(configuration, res);
      res = columnDataHandle(res, config, "%");
      res = numberWithCommas(res);
      res = TableFunction(res);

      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "financial") {
      decimalPlaces = configuration.decimal ? configuration.decimal : 0;
      let res = data;
      color =
        colorCell && offsetObject
          ? getColorBasedOnConfigs(res, offsetObject, offsetColorObject)
          : color;
      data = validateNumber(data, decimalPlaces);
      res =
        data && data.toFixed
          ? data.toFixed(config.decimal ? config.decimal : 0)
          : data;
      res = columnDataHandle(res, config);
      res = res ? res.toLocaleString("en-GB") : res;
      res = res ? numberWithCommas(res) : res;
      res =
        res === "" &&
        (row["Metric Name"] === "Total" || row["Metric Name"] == "total")
          ? ""
          : TableFunction(res, data);
      return <span style={{ color: color }}> {res}</span>;
    } else if (configuration && type == "html") {
      return isHtmlTypeData(row, configuration.id);
    } else if (configuration && type === "optionset") {
      return handleOptionSetValue(data);
    } else {
      return data;
    }
  };

  const isHtmlTypeData = (row, id) => {
    return (
      <div
        id="htmlText"
        dangerouslySetInnerHTML={{ __html: row && row[id] ? row[id] : "" }}
      ></div>
    );
  };

  const ragsFun = (row, key) => {
    return (
      <Rag
        style={{
          color:
            row[key] === "Green"
              ? "green"
              : row[key] === "Red"
              ? "red"
              : row[key] === "Amber"
              ? "#FFBF00"
              : row[key] === "Black"
              ? "#000000"
              : "",

          fontSize: 35,
        }}
      />
    );
  };
  const [popChartData, setPopChartData] = React.useState({});
  const openDealDetails = (row, key, commonKey, configurations) => {
    if (row[key] && row[key] !== "" && parseInt(row[key]) !== 0) {
      return (
        <>
          <span
            className={classes.popUpChart}
            onClick={() => {
              popUpOpen(row, key, commonKey, configurations);
            }}
            id="htmlText"
            dangerouslySetInnerHTML={{ __html: row[key] }}
          ></span>
        </>
      );
    } else {
      return (
        <>
          <span
            id="htmlText"
            dangerouslySetInnerHTML={{
              __html: defaultText(row[key], commonKey),
            }}
          ></span>
        </>
      );
    }
  };
  const popUpOpen = (row, key, commonKey, configurations) => {
    setPopChartData({
      row: row,
      key: key,
      commonKey: commonKey,
      configurations: configurations,
    });
    setOpenPopOver(true);
  };

  const isHighlighted = (row, key, configuration) => {
    return (
      <>
        {configuration.condition && configuration.condition === "pastDate" ? (
          <>
            {row[key] &&
            new Date(row[key]) &&
            new Date(row[key]).setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0) ? (
              <span
                style={{
                  background: configuration.hightlightColor,
                  color: "#fff",
                  padding: "5px",
                }}
              >
                {row[key]}
              </span>
            ) : (
              <span style={{ padding: "5px" }}>{row[key]}</span>
            )}
          </>
        ) : (
          <span
            style={{
              background: configuration.hightlightColor,
              color: "#fff",
              padding: "5px",
            }}
          >
            {row[key]}
          </span>
        )}
      </>
    );
  };

  const doneAndCloseIcons = (cellValue) => {
    if (cellValue === "true" || cellValue === true) {
      return <DoneIcon />;
    } else {
      return <CloseIcon />;
    }
  };
  const defaultText = (data, type) => {
    if (
      data == null ||
      data == "null" ||
      data === "undefined" ||
      data === undefined ||
      (data === "" && type && type.defaultValueshow)
    ) {
      return type.defaultValueshow;
    } else return data;
  };
  const renderPdf = (hasPdf, data) => {
    if (hasPdf) {
      return (
        <>
          {hasPdf.split(",").map((i) => (
            <A_TablePdfDownload key={i} filename={i}></A_TablePdfDownload>
          ))}
        </>
      );
    } else {
      return defaultText(hasPdf, data);
    }
  };

  const renderDocument = (hasDocument, data = {}) => {
    if (hasDocument) {
      return (
        <>
          {hasDocument.split(",").map((document) => (
            <Link
              key={document}
              onClick={() => {
                onDownload(document, data.container || "irc-checklist");
              }}
            >
              {data.showIcon ? (
                <Avatar src={genericDoc} variant="square" />
              ) : (
                document.split("/").pop()
              )}
              <br />
            </Link>
          ))}
        </>
      );
    } else {
      return defaultText(hasDocument, data);
    }
  };
  const onDownload = async (fileName, type) => {
    const url = `${process.env.DOCUMENT_HELPER}`;
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    let header = {
      filename: fileName,
      type: type,
      "file-operation": "download",
      "process-file": "yes",
      "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
      subdomain: subdomain,
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const response = await httpPost(url, "", {
      responseType: "blob",
      headers: header,
    });
    const url1 = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = url1;
    link.setAttribute("download", fileName.split("/").pop());
    document.body.appendChild(link);
    link.click();
  };

  //nested Table column
  const isChildItem = (list) => {
    return list.map((item) => {
      return (
        <>
          <TableRow key={item}>
            <TableCell>{item.label}</TableCell>
            <br></br>
          </TableRow>
        </>
      );
    });
  };

  const renderDimention = (
    row,
    dimentionObj,
    name,
    rowclasstype,
    groups,
    cellBackgroundColor,
    rowStyle
  ) => {
    if (dimentionObj && dimentionObj.hide) {
      return "";
    }
    let openInNewWindow = null;
    if (dimentionObj && dimentionObj["openInNewWindow"]) {
      openInNewWindow = dimentionObj["openInNewWindow"];
    }
    return Object.keys(row).map((key, index) => {
      if (key == name) {
        let border = "";
        border = getBorderGroupBased(groupArr, index, "dimention");
        let style = row && row.classes ? row.classes.split(",") : "";
        let width =
          dimentionObj && dimentionObj.width ? dimentionObj.width : "";
        let padding =
          dimentionObj && dimentionObj.padding ? dimentionObj.padding : "";
        const multipleStyle = style && style.map((item) => classes[item]);
        let position =
          dimentionObj && dimentionObj.columnPinning ? "sticky" : "";
        let left = dimentionObj && dimentionObj.columnPinning ? 0 : "";
        return (
          <TableCell
            key={""}
            style={{
              position: position,
              left: left,
              borderRight: border,
              background: cellBackgroundColor ? cellBackgroundColor : "white",
              minWidth: width,
              padding: padding,
              paddingTop: row.rowBreakBefore ? "1rem" : padding,
              rowStyle,
            }}
            className={
              rowclasstype && classes[rowclasstype]
                ? dense
                  ? clsx(
                      multipleStyle,
                      classes.densetable,
                      classes[rowclasstype]
                    )
                  : clsx(multipleStyle, classes[rowclasstype])
                : dense
                ? clsx(classes.densetable, multipleStyle)
                : ""
            }
          >
            {dimentionObj &&
            dimentionObj.link &&
            dimentionObj.type &&
            dimentionObj.type.toLowerCase() === "html" ? (
              <>
                <a
                  href={row[dimentionObj.link]}
                  target={openInNewWindow ? "_blank" : ""}
                  rel="noreferrer"
                >
                  <span
                    id="htmlText"
                    className=""
                    dangerouslySetInnerHTML={{
                      __html: row[key],
                    }}
                  ></span>
                </a>
              </>
            ) : (
              <>
                {dimentionObj && dimentionObj.link ? (
                  <a
                    rel="noreferrer"
                    style={{ ...rowStyle }}
                    href={row[dimentionObj.link]}
                    target={openInNewWindow ? "_blank" : ""}
                  >
                    {defaultText(row[key], dimentionObj)}
                  </a>
                ) : (
                  <>
                    {dimentionObj &&
                    dimentionObj.type &&
                    dimentionObj.type === "html" ? (
                      <div
                        id="htmlText"
                        dangerouslySetInnerHTML={{
                          __html: row && row[key] ? row[key] : "",
                        }}
                      ></div>
                    ) : (
                      <>
                        {dimentionObj && dimentionObj.ragsColor
                          ? ragsFun(row, key)
                          : defaultText(row[key], dimentionObj)}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </TableCell>
        );
      }
    });
  };
  const renderPagingnation = () => {
    return checkPaginate ? (
      <TablePagination
        rowsPerPageOptions={rowsOption}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    ) : (
      ""
    );
  };
  const renderFooter = () => {
    return configurations && configurations.footer ? (
      <>
        <div>
          <span
            id="htmlText"
            dangerouslySetInnerHTML={{ __html: configurations.footer }}
          ></span>
        </div>
      </>
    ) : (
      ""
    );
  };

  const renderNoDataText = () => {
    return (
      <TableRow>
        <TableCell colSpan={16}>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {message.NO_DATA_FOUND}
          </div>
        </TableCell>
      </TableRow>
    );
  };
  const renderCheckBoxRow = (
    cellBackgroundColor,
    isItemSelected,
    labelId,
    index,
    row
  ) => {
    return allowSelection &&
      (allowSelection.selectRow || allowSelection.selectAll) ? (
      <TableCell
        padding="checkbox"
        style={{
          background: cellBackgroundColor,
          borderBottom: dense ? "none" : "",
        }}
      >
        {allowSelection.selectRow ? (
          <Checkbox
            color="primary"
            onClick={(event) => handleClick(event, index, row)}
            checked={isItemSelected}
            data-testid="cbShowHide"
            inputProps={{ "aria-labelledby": labelId }}
          />
        ) : (
          ""
        )}
      </TableCell>
    ) : (
      ""
    );
  };

  const getRowKey = (props, row, name, key, defaultVal) => {
    let rowValue = _.get(row, name);
    return (
      rowValue &&
      _.get(
        props,
        ['configurations', 'rowFormats', rowValue, key].filter(Boolean),
        defaultVal
      )
    );
  }; 

  const getRowStyle = (configurations, index) => {
    let seriesName =
      props &&
      props.resultData &&
      props.resultData.series &&
      props.resultData.series[index] &&
      props.resultData.series[index].name
        ? props.resultData.series[index].name
        : "";
    if (seriesName != "") {
      return configurations &&
        configurations.rowStyle &&
        configurations.rowStyle[seriesName]
        ? configurations.rowStyle[seriesName]
        : {};
    } else {
      return {};
    }
  };

  const getRowConfig = (rowConfig, row, name) => {
    if (row && row[name] && rowConfig[row[name]] && rowConfig[row[name]].type) {
      return rowConfig[row[name]];
    } else {
      return null;
    }
  };

  const handleCloseDealPopUp = () => {
    setOpenPopOver(false);
  };

  const onBlurTextField = (e, row, conf, key, { rowIndex }) => {
    const invoiceAmount = props.currentPage.data.invoiceamount;
    row[budgetLinecalculations.THISINVOICE] = e.target.value;
    const obj = getOnBlurData(e, row, conf, key, { rowIndex }, data);
    const sum = data.reduce((accumulator, object) => {
      return accumulator + Number(object[budgetLinecalculations.THISINVOICE]);
    }, 0);
    const diffBudget = parseFloat(invoiceAmount) - parseFloat(sum);
    const diffrence = diffBudget.toFixed(2);
    setinvoiceAmountfix(sum);
    //find difference in total
    if (diffrence == 0) {
      setInvoiceHasDifference(false);
      setInvoiceHasDifferenceAmount("Matched");
      setpayloadBudgetlines(
        data.filter(function (item) {
          return item[budgetLinecalculations.THISINVOICE];
        })
      );
    }
    if (diffrence > 0) {
      setInvoiceHasDifference(true);
      setInvoiceHasDifferenceAmount(
        "You can allocate" + "   " + `${diffrence}`
      );
    }
    if (diffrence < 0) {
      setInvoiceHasDifference(true);
      setInvoiceHasDifferenceAmount(
        "Exceeds The Invoice Amount" +
          "   " +
          `${invoiceAmount}` +
          "   " +
          "Reduce the budget by" +
          "   " +
          Math.abs(diffrence) +
          "   " +
          "and allocate the amount"
      );
    }
    setData(obj);
  };
  const renderCellData = ({
    commonKey,
    configurations,
    key,
    row,
    rowDataType,
    rowDataConfig,
    rowDecimalConfig,
  }) => {
    let cellData = null;

    if (commonKey.link) {
      cellData = isLinkTypeData(row, configurations, key);
    } else if (Array.isArray(row[key])) {
      cellData = isChildItem(row[key]);
    } else if (commonKey.ragsColor) {
      cellData = ragsFun(row, key);
    } else if (commonKey.highlighted) {
      cellData = isHighlighted(row, key, commonKey);
    } else if (commonKey.clickable) {
      cellData = openDealDetails(row, key, commonKey, configurations);
    } else if (commonKey.doneIconView) {
      cellData = doneAndCloseIcons(row[key]);
    } else if (commonKey.pdfDownload) {
      cellData = renderPdf(row[key], commonKey);
    } else if (commonKey.questionLog) {
      cellData = renderDocument(row[key], commonKey);
    } else if (commonKey.textField) {
      cellData =
        row.Counterparty !== "Total"
          ? renderTextField(row, configurations, key, {
              onChange: onBlurTextField,
            })
          : row[key];
    } else if (commonKey.textLength) {
      cellData = (
        <>
          <A_ReadMore
            data={row[key]}
            limit={
              commonKey.textLength
                ? commonKey.textLength
                : row[key]
                ? row[key].length
                : 0
            }
          ></A_ReadMore>
        </>
      );
    } else {
      cellData = formatDataBasedOnType(
        row[key],
        commonKey,
        commonKey.variance,
        row,
        rowDataType,
        rowDataConfig
      );
    }
    if (
      rowDecimalConfig &&
      row[key] &&
      !isNaN(row[key]) &&
      row[key] &&
      row[key] % 1 !== 0
    ) {
      cellData = row[key] && row[key].toFixed(rowDecimalConfig);
    }
    cellData = handleOptionSetValue(cellData);
    return (
      <div
        style={
          commonKey.sorting
            ? { display: "inline-block", marginRight: "25px" }
            : {}
        }
      >
        {commonKey.defaultValueshow
          ? defaultText(cellData, commonKey)
          : cellData}
      </div>
    );
  };
  const renderCell = ({
    configurations,
    key,
    row,
    rowclasstype,
    rowDataType,
    rowDataConfig,
    borderR,
    cellBackgroundColor,
    rowStyle,
    rowDecimalConfig,
  }) => {
    const commonKey =
      configurations &&
      configurations.columnConfig &&
      configurations.columnConfig[key]
        ? configurations.columnConfig[key]
        : {};
    let cell = null;
    let width = commonKey && commonKey.width ? commonKey.width : "";
    let padding = commonKey && commonKey.padding ? commonKey.padding : "";
    if (_.isEmpty(commonKey) || commonKey.hide) {
      cell = "";
    } else {
      const multiStyleName =
        rowclasstype && rowclasstype !== "" ? rowclasstype.split(",") : "";
      const multiCellClassName =
        multiStyleName &&
        multiStyleName.length > 0 &&
        multiStyleName.map((item) => classes[item]);
      const cellClassName =
        rowclasstype &&
        rowclasstype != "indent" &&
        rowclasstype != "indent2" &&
        rowclasstype != "indent3"
          ? dense
            ? clsx(multiCellClassName, classes.densetable)
            : clsx(multiCellClassName)
          : dense
          ? classes.densetable
          : {};

      cell = (
        <TableCell
          className={`${cellClassName} ${
            commonKey && commonKey.highLight && commonKey.highLight === true
              ? classes.highlightChart
              : ""
          }${commonKey && commonKey.bold ? classes.bold : ""}`}
          key={key}
          style={{
            ...(commonKey.style,
            getStyleBasedOnColumType(commonKey, rowDataType)),
            borderRight: borderR,
            background: cellBackgroundColor,
            minWidth: width,
            padding: padding,
            paddingTop: row.rowBreakBefore ? "1rem" : padding,
            ...rowStyle,
          }}
        >
          {renderCellData({
            commonKey,
            configurations,
            key,
            row,
            rowDataType,
            rowDataConfig,
            rowDecimalConfig,
          })}
        </TableCell>
      );
    }
    return cell;
  };
  function getValue(row) {
    for (let i in row) {
      if (typeof row[i] == "string" && row[i].toLowerCase() == "total") {
        return true;
      }
    }
    return false;
  }
  const handleblur = (data, row) => {
    let fieldName =
      data && data.blur && data.blur.fieldName ? data.blur.fieldName : "";
    let condition =
      data && data.blur && data.blur.condition ? data.blur.condition : "";
    for (let key in row) {
      if (
        row[key] != undefined &&
        row[key] != null &&
        row[key].toString() == condition &&
        key === fieldName
      ) {
        return true;
      }
    }
    return false;
  };
  const handleDataChange = () => {
    props.callback && props.callback();
  };
  const rowValue =
    props &&
    props.configurations &&
    props.configurations.rowFormats &&
    Object.keys(props.configurations.rowFormats).length === 1 &&
    Object.keys(props.configurations.rowFormats)[0];
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            config={configurations}
            data={data}
            style={{ marginTop: "-1.2em" }}
            onChange={handleSearchChange}
            onFilterChange={props.onFilterChange}
            handleAddButtonClick={props.handleAddButtonClick}
            parameters={props.parameters}
          />
          <TableContainer
            className={`${
              props.header
                ? classes.tableContentWrapper
                : props &&
                  props.configurations &&
                  props.configurations.headerFreeze
                ? classes.headerFreeze
                : classes.tableContentWrapperToggle
            }`}
          >
            <Table
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              {showHeader == undefined || showHeader === true ? (
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  resultData={props.resultData}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                  col={columnConfig}
                  action={action}
                  Groups={Groups}
                  groupLabel={groupLabel}
                  allowSelection={allowSelection}
                  dimensionName={dimensionName}
                  dimensionConfig={dimension}
                  groupArr={groupArr}
                  {...props}
                  displayAction={
                    executeJSString(
                      readOnlyTemplate,
                      getObjectFromJSONString(props, ".currentPage.data")
                    ) != true
                  }
                />
              ) : (
                ""
              )}

              <TableBody>
                {data && data.length > 0
                  ? stableSort(
                      data,
                      getComparator(order, orderBy),
                      rowValue,
                      dimensionName
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index, completeData) => {
                        const currentData = row;
                        const rowFormatData =
                          props.configurations &&
                          props.configurations &&
                          props.configurations.rowFormats &&
                          props.configurations.rowFormats;
                        if (
                          row &&
                          row.rowBreakAfter &&
                          typeof row.rowBreakAfter == "boolean" &&
                          row.rowBreakAfter === true &&
                          index < completeData.length - 1
                        ) {
                          completeData[index + 1].rowBreakBefore = true;
                        }
                        if (action && (action.edit || action.delete)) {
                          for (const key in currentData) {
                            if (
                              rowFormatData &&
                              currentData[key] == Object.keys(rowFormatData)[0]
                            ) {
                              completeData[
                                completeData.length - 1
                              ].bottomSolid = true;
                            }
                          }
                        }
                        return row;
                      })
                      .map((row, index) => {
                        let actualIndex = page * rowsPerPage + index;
                        let cellBackgroundColor = "";
                        if (row && (row.id || row._partitionKey)) {
                          cellBackgroundColor =
                            striped && index % 2 === 0 ? "#f9f9f9" : "";
                        } else {
                          cellBackgroundColor = backgroundColor
                            ? backgroundColor
                            : striped && index % 2 === 0
                            ? "#f9f9f9"
                            : "";
                        }
                        const isItemSelected = isSelected(index);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        // Table column
                        const rowclasstype = getRowKey(
                          props,
                          row,
                          dimensionName,
                          TableChartRowKey.CLASSES,
                          ""
                        );
                        const rowDataType = getRowKey(
                          props,
                          row,
                          dimensionName,
                          TableChartRowKey.ROW_TYPE,
                          null
                        );
                        const rowDataConfig = getRowConfig(
                          props.configurations &&
                            props.configurations.rowFormats
                            ? props.configurations.rowFormats
                            : {},
                          row,
                          dimensionName
                        );

                        const rowStyle = getRowStyle(
                          configurations,
                          actualIndex
                        );
                        const rowDecimalConfig = getRowKey(
                          props,
                          row,
                          dimensionName,
                          TableChartRowKey.DECIMAL_PLACE_CONFIG,
                          ""
                        );
                        return (
                          <>
                            <TableRow
                              style={{ background: "#fafafa" }}
                              className={`${classes.checkBoxSelected} ${
                                getValue(row) ? classes.TopBottomBorder : ""
                              }${
                                handleblur(
                                  props.configurations &&
                                    props.configurations.rowFormats
                                    ? props.configurations.rowFormats
                                    : {},
                                  row
                                )
                                  ? classes.blurRow
                                  : ""
                              }`}
                              key={data.name}
                              tabIndex={-1}
                              aria-checked={isItemSelected}
                              rowclasstype={rowclasstype}
                            >
                              {/* select row check box  */}
                              {renderCheckBoxRow(
                                cellBackgroundColor,
                                isItemSelected,
                                labelId,
                                index,
                                row
                              )}

                              {/* render Dimention value  */}
                              {renderDimention(
                                row,
                                dimension,
                                dimensionName,
                                rowclasstype,
                                Groups,
                                cellBackgroundColor,
                                rowStyle
                              )}

                              {row
                                ? Object.keys(row).map((key, index) => {
                                    let borderR = "";
                                    borderR = getBorderGroupBased(
                                      groupArr,
                                      index,
                                      null
                                    );

                                    return renderCell({
                                      configurations,
                                      key,
                                      row,
                                      rowclasstype,
                                      rowDataType,
                                      rowDataConfig,
                                      borderR,
                                      cellBackgroundColor,
                                      rowStyle,
                                      rowDecimalConfig,
                                    });
                                  })
                                : ""}

                              {action && (action.edit || action.delete) ? (
                                <TableCell
                                  align="left"
                                  alignItems="center"
                                  style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                    position: action.columnPinning
                                      ? "sticky"
                                      : "",
                                    right: action.columnPinning ? 0 : "",
                                    background: cellBackgroundColor
                                      ? cellBackgroundColor
                                      : "white",
                                    padding: action.padding
                                      ? action.padding
                                      : "",
                                    borderBottom: row.bottomSolid
                                      ? "2px solid #888"
                                      : "",
                                  }}
                                >
                                  {(row.id || row._partitionKey) &&
                                    ShowHide(
                                      configurations,
                                      row,
                                      action.show ? true : false
                                    ) &&
                                    executeJSString(
                                      readOnlyTemplate,
                                      getObjectFromJSONString(
                                        props,
                                        ".currentPage.data"
                                      )
                                    ) != true && (
                                      <M_ActionGroup
                                        edit={action.edit}
                                        delete={action.delete}
                                        row={row}
                                        preview={action.preview}
                                        label={pageUrl}
                                        content={{
                                          editTemplateId:
                                            props.parameters.templateId,
                                        }}
                                        id={row.id}
                                        currentPage={props}
                                        handleDataChange={handleDataChange}
                                        hasContainer={
                                          props.parameters.containerName
                                            ? props.parameters.containerName
                                            : undefined
                                        }
                                        updatePayloadDocumentDb={
                                          props &&
                                          props.parameters &&
                                          props.parameters
                                            .updatePayloadDocumentDb
                                            ? props.parameters
                                                .updatePayloadDocumentDb
                                            : undefined
                                        }
                                        investmentChecklist={
                                          props &&
                                          props.parameters &&
                                          props.parameters.investmentChecklist
                                            ? props.parameters
                                                .investmentChecklist
                                            : undefined
                                        }
                                        excelProcessDates={
                                          props.parameters.excelProcessDates
                                            ? props.parameters.excelProcessDates
                                            : undefined
                                        }
                                        chartConfigurations={
                                          props.configurations
                                        }
                                        chartId ={props.chartId}
                                        SQLDelete = {action.SQLDeletion}
                                      />
                                    )}
                                </TableCell>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          </>
                        );
                      })
                  : renderNoDataText()}
              </TableBody>
            </Table>
          </TableContainer>
          {renderPagingnation()}
          {renderFooter()}
          {props &&
          props.configurations &&
          props.configurations &&
          props.configurations.pdfDownloadButton &&
          props.configurations.pdfDownloadButton.pdfDownload ? (
            <A_Button
              startIcon={<GetAppIcon />}
              label="Download Insider List"
              color="primary"
              disabled={isDisable}
              onClick={handlePdfDownload}
              variant="contained"
            />
          ) : (
            ""
          )}
          {props &&
          props.configurations &&
          props.configurations.confirmUptoDateButton &&
          props.configurations.confirmUptoDateButton.confirmButton ? (
            <A_Button
              startIcon={<DoneIcon />}
              label={insiderLabel}
              color="primary"
              disabled={buttonDisable}
              onClick={() => {
                handleConfirmButtonInInsider(InsiderButtons.CONFIRMBUTTON);
              }}
              variant="contained"
            />
          ) : (
            ""
          )}
          {allowSelection &&
            (allowSelection.selectRow || allowSelection.selectAll) &&
            allowSelection.buttonOperations &&
            allowSelection.buttonOperations.map((item) => (
              <>
                {item.showButton ? (
                  <A_Button
                    startIcon={<IconList icon={item.icon} />}
                    label={item.showButtonLabel ? item.showButtonLabel : ""}
                    color={item.color ? item.color : "primary"}
                    onClick={handleUnselectAllClick}
                    variant={item.variant ? item.variant : "contained"}
                  />
                ) : (
                  ""
                )}
              </>
            ))}
          {props &&
            props.configurations &&
            props.configurations.closeInsider &&
            props.configurations.complianceOfficer &&
            props.configurations.complianceOfficer.map((mail) => (
              <>
                {mail.toLowerCase() === currentuser.toLowerCase() ? (
                  <A_Button
                    startIcon={<LockIcon />}
                    label={
                      buttonLabel ? "Insider List closed" : "Close Insider List"
                    }
                    disabled={buttonLabel ? true : false}
                    color="error"
                    onClick={() => {
                      handleCloseInsider(InsiderButtons.CLOSEBUTTON);
                    }}
                    variant="outlined"
                  />
                ) : (
                  ""
                )}
              </>
            ))}
          {approvalOptions && approvalOptions.show ? (
            <A_TableApproval
              {...approvalOptions}
              onApprove={handleApproveClick}
              isEnable={selectedData.length > 0}
            />
          ) : (
            ""
          )}
          <div style={{ display: "flex" }}>
            {multiSelectionOperations &&
              Array.isArray(multiSelectionOperations) &&
              multiSelectionOperations.map((buttonDetails, index) => (
                <A_TableApproval
                  key={index}
                  {...buttonDetails}
                  onApprove={(userid, edgeLabel, properties) =>
                    handleApproveClick(
                      userid,
                      edgeLabel,
                      properties,
                      buttonDetails.params,
                      buttonDetails.responseMessage,
                      buttonDetails.addOns
                    )
                  }
                  isEnable={selectedData.length > 0}
                />
              ))}
          </div>
          {saveButton && (
            <M_InvoiceSaveButton
              payloadBudgetlines={payloadBudgetlines}
              disabled={invoiceHasDifference}
              invoiceAmount={invoiceAmountfix}
              invoiceDiffrence={invoiceHasDifferenceAmount}
            />
          )}
        </Paper>
      </div>

      <Popover
        id="testop"
        open={openPopOver}
        onClose={handleCloseDealPopUp}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        anchorReference={"none"}
        classes={{
          root: classes.popoverRoot,
        }}
      >
        <Box
          style={{
            width: "800px",
            height: "400px",
            marginRight: "1.5rem",
            marginLeft: "1.5rem",
          }}
        >
          <Typography
            onClick={handleCloseDealPopUp}
            style={{ textAlign: "right" }}
          >
            <CloseIcon
              style={{
                margin: "15px",
                fontSize: "22px",
                position: "fixed",
                marginLeft: "-22",
                marginTop: "5px",
                cursor: "pointer",
                zIndex: "9999",
              }}
            />
          </Typography>

          <PopDealDetails
            popChartData={popChartData}
            templates={props.currentPage && props.currentPage.templates}
          />
        </Box>
      </Popover>
      <A_Snackbar
        open={changesSaved.status}
        message={changesSaved.msg}
        autoHideDuration={2000}
        horizontal="center"
        type={changesSaved.type}
      />
    </ThemeProvider>
  );
};
A_TableChart.propTypes = {
  action: PropTypes.any,
  allowSelection: PropTypes.any,
  dimensionName: PropTypes.object,
  dimensionConfig: PropTypes.object,
  Groups: PropTypes.object,
  groupLabel: PropTypes.any,
  groupArr: PropTypes.array,
  resultData: PropTypes.object,
  onChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  parameters: PropTypes.any,
  configurations: PropTypes.any,
  data: PropTypes.any,
  handleAddButtonClick: PropTypes.func,
  handleClickableCell: PropTypes.func,
  handleDeleteButtonClick: PropTypes.func,
  handleEditButtonClick: PropTypes.func,
  currentPage: PropTypes.object,
  onDelete: PropTypes.func,
  handleDataChange: PropTypes.any,
  callback: PropTypes.any,
  configuration: PropTypes.bool,
  header: PropTypes.any,
  chartId:PropTypes.any
  // content: PropTypes,
};

A_TableChart.defaultProps = {
  configurations: {
    title: "Heading",
    noDataText: "No data available.",
    paginate: true,
    searchable: true,
    sortable: true,
    striped: true,
    allowSelection: {
      selectRow: true,
      selectAll: true,
    },
    groupLabel: "test",
    Groups: [
      {
        label: "test",
        span: "2",
      },
      {
        label: "test 1",
        span: "1",
      },
    ],
    columnConfig: {
      uname: {
        id: "uname",
        label: "User Name",
        sorting: false,
        hide: false,
      },

      ContactNo: {
        id: "ContactNo",
        label: "Contact No",
        sorting: true,
        hide: false,
      },

      email: {
        id: "email",
        label: "Email",
        sorting: true,
        hide: false,
      },
    },
  },
  data: [],
};

export default A_TableChart;
