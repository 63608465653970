import React from "react";
import { useHistory } from "react-router";
import propTypes from "prop-types";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import calendarIcon from "../../../../../../src/Assets/Images/Defaults/meetingDef.jpg";
import Grid from "@material-ui/core/Grid";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import { useSelector, useDispatch } from "react-redux";
import { resetManagePage } from "../../../../../store/actions/managePageActions";
import {
  FromEpochFormat,
  getFormattedDate,
} from "../../../../../Utils/Helpers/dateUtils";
import {
  groupBy,
  diff_hours,
  sortByKey,
  dateCheck,
} from "./O_MeetingSlotCard.helper";
import { makeStyles } from "@material-ui/core";
import { O_MeetingSlotCardCss } from "./O_MeetingSlotCardCss";
import { Box, Typography } from "@material-ui/core";
import { ircSlotEnums } from "./mettingSlotCard.Helper";
import O_OtherMail from "../IRC_Mail/O_OtherMail";
import { getObjectFromJSONString } from "../../../Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
const useStyles = makeStyles(O_MeetingSlotCardCss);
const IrcSlotComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  // default props
  const {
    IRCSlotDefault,
    IRCSlotsValues,
    content,
    setRecordId,
    setCurrentIRCDate,
    setCurrentTime,
    setCurrentDate,
    setIsOpen,
    setFormResize,
  } = props;
  const prevEntity = useSelector((state) => state.previousEntity.prevEntity);
  const dispatch = useDispatch();

  const IRCRenderData = () => {
    let slotMappingList = Array.isArray(IRCSlotDefault)
      ? IRCSlotDefault.map((slots) => {
          if (slots.properties && slots.properties.ircDate) {
            slots.ircDate = FromEpochFormat(
              slots.properties && slots.properties.ircDate
            );
          }
          return slots;
        })
      : [];
    if (IRCSlotsValues && Array.isArray(IRCSlotsValues)) {
      slotMappingList = IRCSlotDefault.map((slots) => {
        let syncSlotValue = IRCSlotsValues.find(
          (slotValue) => slotValue.id === slots.id
        );
        slots = { ...slots, ...(syncSlotValue || {}) };
        if (slots.properties && slots.properties.ircDate) {
          slots.ircDate = FromEpochFormat(
            slots.properties && slots.properties.ircDate
          );
        }
        let isApproved = getObjectFromJSONString(
          slots,
          `.properties.isApprovedData`
        );
        if (
          ((slots.deal ||
            (slots.properties.otherEmailPurpose &&
              slots.properties.otherEmailPurpose !== "")) &&
            (isApproved === true || isApproved === undefined)) ||
          slots.label === "ircslots"
        ) {
          return slots;
        }
      }).filter((item) => item);
    }
    let groupedByDate = groupBy(
      sortByKey(slotMappingList, ircSlotEnums.IRC_DATE),
      ircSlotEnums.IRC_DATE
    );
    let { closedAfter = 0, warningAfter = 0 } = content;
    return (
      groupedByDate &&
      Object.keys(groupedByDate)
        .filter((recordDate) => dateCheck(recordDate, props.content.noOfDays))
        .map((recordDate) => {
          let currentRecord = groupedByDate[recordDate];
          let difference = diff_hours(recordDate);
          let disableBook = difference <= closedAfter;
          let displayMessage =
            closedAfter <= warningAfter &&
            difference > closedAfter &&
            difference <= warningAfter;
          let deals = currentRecord.map((record) => {
            let {
              templateId = "",
              redirectUrl = "",
              redirectionId = "",
            } = content;
            let dealData = record.deal ? (
              <A_Button
                className={classes.margin}
                label={record.deal ? record.deal : ""}
                color="default"
                onClick={() => {
                  let dealId =
                    record && record[redirectionId]
                      ? record[redirectionId]
                      : "0";
                  history.push(`/${redirectUrl}/${dealId}/${templateId}`);
                }}
              />
            ) : record.properties && record.properties.otherEmailPurpose ? (
              <O_OtherMail
                label={record.properties.otherEmailPurpose}
                content={content}
                ircID={record.id}
                formData={{}}
              />
            ) : (
              ""
            );
            return dealData;
          });
          const handleBookIrcSlot = () => {
            if (prevEntity && prevEntity.templateId !== props.entity.templateId) {
              dispatch(resetManagePage());
            }
            setRecordId(currentRecord.id);
            setCurrentIRCDate(
              getFormattedDate(
                recordDate,
                ircSlotEnums.DATE_TIME_FORMAT
              )
            );
            setCurrentTime(
              getFormattedDate(recordDate, ircSlotEnums.TIME_FORMAT)
            );
            setCurrentDate(currentRecord[0].properties.ircDate);
            setIsOpen(true);
            setFormResize(true);
          };
          let detail = (
            <Box className={classes.detailFirstBox}>
              <Grid container spacing={8} alignItems="start">
                <Grid item xs={2}>
                  <img src={calendarIcon} alt="" />
                </Grid>
                <Grid item xs={6}>
                  <Box className={classes.detailSecondBox}>
                    <Typography className={classes.typographyStyle}>
                      {getFormattedDate(
                        recordDate,
                        ircSlotEnums.DATE_TIME_FORMAT
                      )}
                    </Typography>
                    <Box>{deals ? deals : ""}</Box>
                  </Box>
                </Grid>
                <Grid item xs={4} justifyContent="end" className="text-center">
                  <A_Button
                    onClick={handleBookIrcSlot}
                    color={displayMessage ? "secondary" : "primary"}
                    label="Book"
                    disabled={disableBook}
                  />{" "}
                  {!disableBook && displayMessage ? (
                    <span className={classes.timePeriod}>
                      {content &&
                      (content.closingText || content.closingText === "")
                        ? content.closingText
                        : ircSlotEnums.COMING_SOON}
                    </span>
                  ) : null}
                </Grid>
              </Grid>
              <Box style={{ paddingTop: "0.8rem" }}>
                <A_Divider />
              </Box>
            </Box>
          );
          return detail;
        })
    );
  };
  return <>{IRCRenderData()}</>;
};

IrcSlotComponent.propTypes = {
  IRCSlotDefault: propTypes.any,
  IRCSlotsValues: propTypes.any,
  content: propTypes.any,
  entity: propTypes.any,
  setRecordId: propTypes.any,
  setCurrentIRCDate: propTypes.any,
  setCurrentTime: propTypes.any,
  setCurrentDate: propTypes.any,
  setIsOpen: propTypes.any,
  setFormResize: propTypes.any,
};
export default IrcSlotComponent;
