import { SET_NUMBER } from "../actions/actionTypes";

let initialState = {
    number:0,
};
const numberReducer  = (state=initialState,action)=>{
    switch(action.type){
        case SET_NUMBER:
            return {
                ...state,
            number:action.payload,
            };
        default:
            return state;
    }
};

export default numberReducer;
