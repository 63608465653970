import React from "react";
import A_Divider from "../../../Atoms/DataDisplay/Dividers/A_Divider";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { httpGet, httpPost } from "../../../../../Utils/apis/apis";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useParams } from "react-router";
const useStyles = makeStyles(() => ({
  colorClass: {
    color: "#fff",
  },
  budgetApprovalCard: {
    padding: "0px 10px",
    fontFamily: "Roboto",
  },
  columnHeader: {
    backgroundColor: "black",
  },
  rowColor: {
    background: "#fafafa",
  },
  nextApproverName: {
    fontWeight: "bold",
    fontFamily: "Roboto",
  },
  textFieldRender: {
    marginLeft: "130px",
    marginTop: "-28px",
    fontFamily: "Roboto",
  },
  editIcon: {
    cursor: "pointer",
  },
  rejectButton: {
    marginLeft: "10px",
  },
  button: {
    marginTop: "10px",
  },
  closeIcon: {
    cursor: "pointer",
  },
  approveButtontwo: {
    display: "none",
  },
  approveButton: {
    display: "block",
  },
}));

// eslint-disable-next-line react/display-name
const BudgetApproval = React.memo((props) => {
  const { crmId, pageUrl } = useParams();
  const [fundaccountant, setfundaccountant] = React.useState(true);
  const [loading, setLoading] = React.useState();
  const [nextApproverData, setNextApproverData] = React.useState([]);
  const loggedInUserData = useSelector(
    (state) =>
      state.oidc.user &&
      state.oidc.user.profile &&
      state.oidc.user.profile.email
  );
  const [adminval, setadminval] = React.useState("");
  let resultDataValue = [];
  let Invoicereadystatus = [];

  const getInvoicereadystatus = async () => {
    // let config = commonFunctions.getHttpConfig();
    let urlforUpdate = `${process.env.API_URL}/Finance/CheckInvoiceReadyStatus?invoiceId=${crmId}`;
    Invoicereadystatus = await httpGet(urlforUpdate);
  };

  const approveAndReject = async () => {
    let urlforUpdates = `${process.env.API_URL}/Finance/InvoiceReady?invoiceId=${crmId}`;
    await httpPost(urlforUpdates, "").then(
      props.getCurrentPage({ pageUrl, crmId })
    );
  };

  React.useEffect(() => {
    getInvoicereadystatus();
    getApprovalsData();
    var currentuser = loggedInUserData ? loggedInUserData : "";

    props &&
      props.content &&
      props.content.fundAccountant &&
      props.content.fundAccountant.map((itemId) => {
        if (itemId.toLowerCase() == currentuser.toLowerCase()) {
          localStorage.setItem("fundaccountantEmail", itemId);
        }
      });

    props &&
      props.content &&
      props.content.fundAccountant &&
      props.content.fundAccountant.map((itemId) => {
        if (itemId.toLowerCase() == currentuser.toLowerCase()) {
          setfundaccountant(false);
        }
      });
  }, []);

  const classes = useStyles();

  const getApprovalsData = async () => {
    // let config = commonFunctions.getHttpConfig();
    let urlforUpdate = `${process.env.API_URL}/Finance/getApproverRecords?invoiceId=${crmId}`;
    resultDataValue = await httpGet(urlforUpdate);
    const indexVal =
      (await resultDataValue) &&
      resultDataValue &&
      resultDataValue.findIndex((object) => {
        return object.Approved === null || object.Approved != true;
      });
    for (let i = 0; i < 3; i++) {
      indexVal <= -1 &&
      resultDataValue[Math.abs(indexVal - 1)].Approved != true &&
      resultDataValue[Math.abs(indexVal + 1)].Approved != true &&
      resultDataValue[Math.abs(indexVal + 2)].Approved != true
        ? props.onChangeNextApprover(
            resultDataValue[0].Email,
            indexVal + 1,
            fundaccountant,
            resultDataValue[Math.abs(indexVal - 1)].Approved,
            Invoicereadystatus
          )
        : indexVal > -1 &&
          props.onChangeNextApprover(
            resultDataValue[indexVal].Email,
            indexVal + 1,
            fundaccountant,
            resultDataValue[Math.abs(indexVal - 1)].Approved,
            Invoicereadystatus
          );
      indexVal < 0 &&
        setadminval("Invoice is fully approved") &&
        setLoading(Invoicereadystatus);
      indexVal >= 0 &&
        setadminval(
          resultDataValue && indexVal >= 0 && resultDataValue[indexVal].Name
        );
      setLoading(Invoicereadystatus);
      setNextApproverData(resultDataValue);
      break;
    }
  };

  function generateTableCell(rowKey, rowData) {
    if (rowKey == "Name") {
      let name = rowData.Name;
      return (
        <TableCell key="Name" style={{ textAlign: "left" }}>
          {name}
        </TableCell>
      );
    }
    if (rowKey == "Approved") {
      //for now manually enabled
      return rowData.Approved == true ? (
        <TableCell key="Approved" style={{ textAlign: "center" }}>
          <CheckIcon style={{ color: "rgb(0 183 53)" }} />
        </TableCell>
      ) : rowData.Approved == false ? (
        <TableCell key="Approved" style={{ textAlign: "center" }}>
          <ClearIcon style={{ color: "red" }} />
        </TableCell>
      ) : (
        <TableCell key="Approved"></TableCell>
      );
    }
    if (rowKey == "Date") {
      let date = rowData.Date;
      const standarddate = new Date(date * 1000);
      let valDate = standarddate.toLocaleDateString("en-GB");

      return date == null ? (
        <TableCell key="Date" style={{ textAlign: "left" }}></TableCell>
      ) : (
        <TableCell key="Date" style={{ textAlign: "left" }}>
          {valDate}
        </TableCell>
      );
    }
    if (rowKey == "Stage") {
      let stage = rowData.Stage;
      return <TableCell key="Stage">{stage}</TableCell>;
    } else {
      return (
        <TableCell key={rowData[rowKey]} style={{ textAlign: "left" }}>
          {rowData[rowKey]}
        </TableCell>
      );
    }
  }
  const generateRows = (row) => {
    delete row.Email;
    let rowKeys = Object.keys(row);
    let rw = rowKeys.map((rowKey) => {
      return generateTableCell(rowKey, row);
    });
    return <TableRow>{rw}</TableRow>;
  };
  return fundaccountant === true ? (
    <>
      <Box className={classes.budgetApprovalCard}>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"small"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow className={classes.columnHeader}>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Stage
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Approved
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Date
                </TableCell>
                <TableCell
                  className={classes.colorClass}
                  style={{ textAlign: "left" }}
                >
                  Name
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {nextApproverData &&
                nextApproverData.map((row) => generateRows(row))}
            </TableBody>
          </Table>
        </TableContainer>

        <A_Divider />
        {
          <div className="adminControls">
            <strong>
              <h3
                style={{
                  marginTop: "40px",
                  marginBottom: "30px",
                  color: "rgb(182 149 98)",
                  fontSize: 15,
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                Next approver :{" "}
                <p
                  style={{
                    color: "grey",
                    marginTop: "5px",
                    paddingLeft: "5px",
                  }}
                >
                  {adminval}
                </p>
              </h3>
            </strong>
          </div>
        }
      </Box>
      {}
    </>
  ) : fundaccountant === false && !loading && loading != undefined ? (
    <>
      <Button
        className={classes.approveButton}
        style={{
          backgroundColor: "#4CAF50",
          textTransform: "none",
          color: "white",
          fontWeight: "600",
          width: "100%",
        }}
        onClick={() => {
          approveAndReject();
        }}
      >
        <DoneIcon />
        Approve
      </Button>
    </>
  ) : (
    <>
      <>
        <Box className={classes.budgetApprovalCard}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"small"}
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow className={classes.columnHeader}>
                  <TableCell
                    className={classes.colorClass}
                    style={{ textAlign: "left" }}
                  >
                    Stage
                  </TableCell>
                  <TableCell
                    className={classes.colorClass}
                    style={{ textAlign: "left" }}
                  >
                    Approved
                  </TableCell>
                  <TableCell
                    className={classes.colorClass}
                    style={{ textAlign: "left" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    className={classes.colorClass}
                    style={{ textAlign: "left" }}
                  >
                    Name
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {nextApproverData &&
                  nextApproverData.map((row) => generateRows(row))}
              </TableBody>
            </Table>
          </TableContainer>

          <A_Divider />
          {
            <div className="adminControls">
              <strong>
                <h3
                  style={{
                    marginTop: "40px",
                    marginBottom: "30px",
                    color: "rgb(182 149 98)",
                    fontSize: 15,
                    display: "flex",
                    alignItems: "baseline",
                  }}
                >
                  Next approver :{" "}
                  <p
                    style={{
                      color: "grey",
                      marginTop: "5px",
                      paddingLeft: "5px",
                    }}
                  >
                    {adminval}
                  </p>
                </h3>
              </strong>
            </div>
          }
        </Box>
        {}
      </>
    </>
  );
});

BudgetApproval.propTypes = {
  content: PropTypes.any,
  accessEmails: PropTypes.array,
  map: PropTypes.func,
  getCurrentPage: PropTypes.func,
  onChangeNextApprover: PropTypes,
};

export default BudgetApproval;
