/* eslint-disable */
import * as crmEngineApis from "../../../../../../Utils/Helpers/crmDataApis/crmDataApis";
import {
  schedulerFrequency,
  schedulerRemainderTemplate,
} from "../../../../../../Utils/Helpers/common";
import { httpPost } from "../../../../../../Utils/apis/apis";
import { FromEpochFormat } from "../../../../../../Utils/Helpers/dateUtils";
import { enhanceEvalString } from "../../Renderers/Views/ResultCards/M_ResultCardHelper";
import { handleOptionSetValue } from "../../../../../../Utils/Helpers/optionSet.helper";
import { getCRMContactInfo } from "../../../../../../Utils/Helpers/common";

export const getEcpochDateOfOptionSetMonth = (date) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  try {
    let formDate = JSON.parse(date);
    let str = formDate.value;
    str = str.split(" ");
    const d = new Date();
    let month = str[0] ? months.indexOf(str[0]) : d.getMonth();
    let year = str[1] ? str[1] : d.getFullYear();
    let day = d.getDate();
    let newdate = Math.floor(
      new Date(year, month, day, 12, 0, 0).getTime() / 1000.0
    );
    return newdate;
  } catch {
    return Math.round(Date.now() / 1000);
  }
};
export const createUpdatedObject = (
  jsonData,
  oldValuesfilledwithform,
  newFilledvalues
) => {
  try {
    const resultObject = {};

    jsonData.stepper.forEach((step) => {
      step.content.forEach((content) => {
        const fieldId = content.id;

        if (
          newFilledvalues &&
          typeof newFilledvalues === "object" &&
          newFilledvalues !== null &&
          fieldId in newFilledvalues
        ) {
          // If newFilledvalues has a value for the fieldId, use it
          resultObject[fieldId] = newFilledvalues[fieldId];
        } else if (
          oldValuesfilledwithform &&
          typeof oldValuesfilledwithform === "object" &&
          oldValuesfilledwithform !== null &&
          fieldId in oldValuesfilledwithform
        ) {
          // If newFilledvalues doesn't have a value, but oldValuesfilledwithform has, use it
          resultObject[fieldId] = oldValuesfilledwithform[fieldId];
        } else {
          // If neither newFilledvalues nor oldValuesfilledwithform have a value, set to empty string
          resultObject[fieldId] = "";
        }
      });
    });

    return resultObject;
  } catch {
    return {};
  }
};
function replaceValueswithPageData(mapping, data, crmId, pageUrl) {
  try {
    const result = {};

    for (const [key, value] of Object.entries(mapping)) {
      if (typeof value === "string" && value.startsWith("=")) {
        const originalKey = value.substring(1);
        result[key] = data[originalKey] || "";
        if (originalKey.toLocaleLowerCase() == "crmid") {
          result[key] = crmId || "";
        } else if (originalKey.toLocaleLowerCase() == "pageurl") {
          result[key] = pageUrl || "";
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  } catch {
    return {};
  }
}
export const CRMEdgeVertexEngine = async (
  payload,
  restParams,
  crmId,
  pageUrl
) => {
  try {
    payload && payload.properties
      ? (payload.properties["parentId"] = crmId)
      : "";
    let res = null;
    let EA =
      restParams.entity &&
      restParams.entity.pageName &&
      restParams.entity.pageName.toLowerCase() == "ea access"
        ? true
        : false;
    if (EA) {
      try {
        let _destinationVertexId =
          payload.edges[0] && payload.edges[0].guid && payload.edges[0].guid[0];
        let _sourceVertexId =
          payload.edges[1] && payload.edges[1].guid && payload.edges[1].guid[0];
        let url = `/GenericGremlin/generic/addEAEdge`;
        let requestPayload = {
          "@sourceId": _sourceVertexId,
          "@EAId": _destinationVertexId,
        };
        res = await httpPost(url, requestPayload);
        res["isEA"] = true;
        const { crmContactUserEmail } = getCRMContactInfo();
        if (crmContactUserEmail) {
          let apiPayload = {
            label: "contact",
            properties: {
              email: crmContactUserEmail,
            },
          };
          const res = await httpPost(
            `${process.env.API_URL}/CRMData/getRecordByProperty`,
            apiPayload
          );
          let contact_crm = "";
          if (res && res.length > 0 && res[0] && res[0].id) {
            contact_crm = res[0];
          }
          if (contact_crm) {
            localStorage.setItem("contact_crm", JSON.stringify(contact_crm));
          }
        }
        return res;
      } catch {
        res["message"] = "error occurred";
        return res;
      }
    }

    let actionType =
      restParams.entity &&
      restParams.entity.parameters &&
      restParams.entity.parameters.actionType
        ? restParams.entity.parameters.actionType
        : "";
    switch (actionType) {
      case "add":
        res = await InsertVertex(payload, restParams);
        break;
      case "link":
        res = await AddEdge(payload, restParams);
        break;
      case "inverse":
        res = await InsertWithAddEdgeInverse(payload, restParams);
        break;

      default:
        res = await InsertWithAddEdge(payload, restParams, crmId, pageUrl);
        break;
    }
    return res;
  } catch (e) {
    console.log(
      "Error occured in CRMEdgeVertexEngine while saving form: " + e.message
    );
    return { message: e.message };
  }
};

const AddEdge = async (payload, params) => {
  //==When in the view form parameter, sourceVertexId is available then
  //==linking suppose to done with addEdge api
  //==DestinationVertexId will be selected lookup value in the form
  try {
    let destinationEdgeLabel =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.destinationLabel
        ? params.entity.parameters.destinationLabel
        : "";
    destinationEdgeLabel =
      destinationEdgeLabel === "" &&
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.edgeLabel
        ? params.entity.parameters.edgeLabel
        : "";
    let edgeLabel = params.edgeLabel ? params.edgeLabel : "";
    let props =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.isDealTeam
        ? {
            role:
              payload && payload.properties && payload.properties.role
                ? payload.properties.role
                : "",
            userRole:
              payload && payload.properties && payload.properties.userRole
                ? payload.properties.userRole
                : "",
            user:
              payload && payload.properties && payload.properties.dealTeamRadio
                ? payload.properties.dealTeamRadio
                : "",
            companyRole:
              payload && payload.properties && payload.properties.companyRole
                ? payload.properties.companyRole
                : "",
          }
        : payload.properties;
    let _sourceVertextId = params.entity.parameters.sourceVertexId;
    let _destinationVertextId = getDestinationVertexIdBasedOnDestinationLabel(
      payload.edges,
      destinationEdgeLabel
    );
    let submitToStoredProc =
      payload && payload.submitToStoredProc ? payload.submitToStoredProc : "";
    //edgeLabel
    let _edgeLabel = getEdgeLableData(
      params.showPopup,
      params.isAddEdge,
      edgeLabel
    );

    _edgeLabel = _edgeLabel === "" ? destinationEdgeLabel : "";

    const addEdgePayLoad = {
      sourceVertexId: _sourceVertextId,
      destinationVertexId: _destinationVertextId,
      edgeLabel: _edgeLabel,
      properties: { ...props },
      submitToStoredProc: submitToStoredProc,
    };
    let res = await crmEngineApis.AddEdge(addEdgePayLoad);
    return res;
  } catch (e) {
    console.log("Error occured in AddEdge while saving form: " + e.message);
    return { message: e.message };
  }
};

const getDestinationVertexIdBasedOnDestinationLabel = (
  edge,
  destinationLabel
) => {
  try {
    let destinationVId = "";
    for (let i in edge) {
      if (edge[i].label === destinationLabel) {
        destinationVId = edge[i].guid[0];
      }
    }
    destinationVId = destinationVId !== "" ? edge[0].guid[0] : "";
    return destinationVId;
  } catch (e) {
    console.log(
      "Error occured in getDestinationVertexIdBasedOnDestinationLabel: " +
        e.message
    );
    return { message: e.message };
  }
};

export const getEdgeLableData = (showPopup, isAddEdge, edge, edgeLabel) => {
  try {
    let edgelabelData = "";
    if (showPopup == true || isAddEdge === true) {
      if (isAddEdge === true) {
        for (let i in edge) {
          edgelabelData = edge[i].label ? edge[i].label : "";
          break;
        }
      }
      edgelabelData = edgelabelData
        ? edgelabelData
        : edgeLabel
        ? edgeLabel
        : "";
    }
    return edgelabelData;
  } catch (e) {
    console.log("Error occured in getEdgeLableData: " + e.message);
    return { message: e.message };
  }
};

export const getDefaultValues = (steps) => {
  try {
    let elementsWithDefaultValues = [];
    for (let i = 0; i < steps.length; i++) {
      let step = steps[i];
      if ("content" in step) {
        let elements = step.content.filter((ele) => {
          return ele.defaultValue && ele.defaultValue !== "";
        });
        if (elements.length > 0) {
          elementsWithDefaultValues =
            elementsWithDefaultValues.concat(elements);
        }
      }
    }
    return elementsWithDefaultValues;
  } catch (e) {
    console.log("Error occured in getDefaultValues: " + e.message);
    return { message: e.message };
  }
};

export const setEdgeLabelData = (newFormData) => {
  try {
    let edgearr = [];
    for (const key in newFormData) {
      if (
        key &&
        newFormData[key] &&
        typeof newFormData[key] == "object" &&
        newFormData[key]["fieldType"] &&
        newFormData[key]["fieldType"] == "lookup"
      ) {
        let arrayValue = [];
        if (newFormData[key].data.length > 0) {
          newFormData[key].data.forEach((val) => {
            arrayValue.push(val.id);
          });
        } else {
          arrayValue.push(newFormData[key].data.id);
        }
        let resEdgeObject = {
          label: newFormData[key]["edgeLabel"],
          guid: arrayValue[0] != undefined ? arrayValue : (arrayValue[0] = []),
        };
        edgearr.push(resEdgeObject);
        newFormData[key] = undefined;
      }
    }
    return edgearr;
  } catch (e) {
    console.log("Error occured in setEdgeLabelData: " + e.message);
    return { message: e.message };
  }
};

const InsertVertex = async (payload, params = {}) => {
  try {
    let res = await crmEngineApis.CreateDestinationVertex(payload, params);
    return res;
  } catch (e) {
    console.log("Error occured in InsertVertex: " + e.message);
    return { message: e.message };
  }
};
const InsertWithAddEdge = async (payload, params, crmId, pageUrl) => {
  try {
    if (
      params &&
      params.managePage &&
      params.managePage.data &&
      params.managePage.data.template &&
      params.managePage.data.template.templateType &&
      params.managePage.data.template.templateType == "SQL form"
    ) {
      let convertedFormData = { ...payload.properties };
      let templateJson = params.managePage.data.template.templateJson;
      let arr = templateJson ? JSON.parse(templateJson) : [];
      let sqlData = createUpdatedObject(
        arr,
        params.managePage.data.data,
        convertedFormData
      );

      if (sqlData && sqlData.parentId) {
        delete sqlData.parentId;
      }
      let filterParams = params && params.entity && params.entity.FilterParams;
      if (filterParams.id) {
        delete filterParams.id;
      }
      let extraParameters = params && params.entity && params.entity.parameters;
      if (extraParameters && extraParameters["templateId"]) {
        delete extraParameters["templateId"];
      }
      let convertedValues;
      if (extraParameters && params.currentPage && params.currentPage.data) {
        convertedValues = replaceValueswithPageData(
          extraParameters,
          params.currentPage.data,
          crmId,
          pageUrl
        );
      }
      let latestData = {
        spName: params.managePage.data.template.addSpName
          ? params.managePage.data.template.addSpName
          : "",
        parameters: {
          ...sqlData,
          ...filterParams,
          ...convertedValues,
        },
      };
      let sendAsJsonstring =
        params.managePage.data &&
        params.managePage.data.template &&
        params.managePage.data.template.sendAsJsonstring
          ? params.managePage.data.template.sendAsJsonstring
          : false;
      if (sendAsJsonstring) {
        // Convert parameters to JSON string if sendAsJsonstring is true from admin
        latestData["parametersAsJsonString"] = JSON.stringify(
          latestData.parameters
        );
      }
      let response = await httpPost(
        `/SQLBasedForm/SQLFormOperations`,
        latestData,
        "",
        true
      );
      if (response && typeof response === "object" && response.status) {
        if (response.status >= 200 && response.status < 300) {
          response["isEA"] = true;
        } else {
          response["message"] = "error occurred";
        }
      }

      return response;
    }
    let res = await crmEngineApis.CreateDestinationVertex(payload, params);
    let sourceVertexId =
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.sourceVertexId
        ? params.entity.parameters.sourceVertexId
        : "";
    if (sourceVertexId) {
      const addEdgePayLoad = {
        sourceVertexId: sourceVertexId,
        destinationVertexId: res.id,
        edgeLabel: params.entity.parameters.edgeLabel
          ? params.entity.parameters.edgeLabel
          : res && res.edges && res.edges.length > 0 && res.edges[0].label
          ? res.edges[0].label
          : "",
        properties: payload.properties ? payload.properties : {},
      };
      await crmEngineApis.AddEdge(addEdgePayLoad);
    }

    return res;
  } catch (e) {
    console.log("Error occured in InsertWithAddEdge: " + e.message);
    return { message: e.message };
  }
};

export const getStepContent = (fields, index, steps) => {
  let stepElements = [];
  let elementsProps = [];
  let contentRaw = steps[index];
  if (contentRaw) {
    if ("content" in contentRaw) {
      stepElements = contentRaw.content;
    } else if ("sections" in contentRaw) {
      stepElements = contentRaw.sections;
    } else if ("fields" in contentRaw) {
      stepElements = contentRaw.fields;
    }
    elementsProps = stepElements.map((element) => {
      let elementProps = {};
      let fieldProperties = fields.filter((field) => {
        return element.fieldId === field.id;
      })[0];

      if (fieldProperties !== undefined) {
        elementProps = { ...fieldProperties, ...element };
      } else {
        elementProps = element;
      }
      return elementProps;
    });
  }

  elementsProps.push({
    fieldType: "permissions",
  });

  return elementsProps;
};
export const schedulerCustomPayload = async (payload, crmId, entity) => {
  let data = await payload.properties;
  for (let i in data) {
    if (i === "frequency") {
      for (let char in schedulerFrequency) {
        if (char === data[i]) {
          data.FrequencyUnit = schedulerFrequency[char];
          break;
        }
      }
    } else if (i === "ReminderTemplateName") {
      for (let char in schedulerRemainderTemplate) {
        if (char === data[i]) {
          data.ReminderTemplateId = schedulerRemainderTemplate[char];
          break;
        }
      }
    }
  }
  entity &&
    entity.parameters &&
    entity.parameters.excelProcessDates &&
    entity.parameters.excelProcessDates.split(",").map((property) => {
      if (data && data[property]) {
        let dateEpoch = FromEpochFormat(data[property]);
        let date = new Date(dateEpoch);
        data[property] = date.toISOString();
      }
    });
  data.PortFolioCompanyID = crmId;
  let Payload = {
    id: crmId,
    label:
      entity && entity.parameters && entity.parameters.pageUrl
        ? entity.parameters.pageUrl
        : payload && payload.label
        ? payload.label
        : "",
    properties: {
      _partitionKey: crmId,
    },
  };
  let resultData = await httpPost(`/CRMData/getRecordByProperty`, Payload);

  if (resultData && resultData.length > 0) {
    data.Portfolio = resultData[0].properties["Investment Name"]
      ? resultData[0].properties["Investment Name"]
      : resultData[0].properties.projectName;
  }
  data.isDeleted = false;
  data.parameters = {
    user: data.user
      ? data.user
      : entity && entity.formData && entity.formData.user
      ? entity.formData.user
      : "",
    company: data.company
      ? data.company
      : entity && entity.formData && entity.formData.company
      ? entity.formData.company
      : "",
    link: data.link
      ? data.link
      : entity && entity.formData && entity.formData.link
      ? entity.formData.link
      : "",
    buttonText: data.buttonText
      ? data.buttonText
      : entity && entity.formData && entity.formData.buttonText
      ? entity.formData.buttonText
      : "",
  };
  data.parameters = JSON.stringify(data.parameters);

  payload.properties = data;
  return payload;
};

const InsertWithAddEdgeInverse = async (payload, params) => {
  try {
    let res = await crmEngineApis.CreateDestinationVertex(payload, params);
    let sourceVertexId = "";
    if (
      params &&
      params.entity &&
      params.entity.parameters &&
      params.entity.parameters.sourceVertexLookupLabel
    ) {
      for (let i in payload.edges) {
        if (
          payload.edges[i].label ===
          params.entity.parameters.sourceVertexLookupLabel
        ) {
          sourceVertexId = payload.edges[i].guid[0];
        }
      }
    }
    if (sourceVertexId) {
      const addEdgePayLoad = {
        sourceVertexId: sourceVertexId,
        destinationVertexId: res.id,
        edgeLabel: params.entity.parameters.edgeLabel
          ? params.entity.parameters.edgeLabel
          : res && res.edges && res.edges.length > 0 && res.edges[0].label
          ? res.edges[0].label
          : "",
        properties: payload.properties ? payload.properties : {},
      };
      await crmEngineApis.AddEdge(addEdgePayLoad);
    }

    return res;
  } catch (e) {
    console.log("Error occured in InsertWithAddEdgeInverse: " + e.message);
    return { message: e.message };
  }
};

export const getIdsFromEdges = (edges, edgeLabels) => {
  try {
    let IDsCollection = [];
    edges.forEach((edge) => {
      if (edgeLabels.includes(edge.label)) {
        IDsCollection = [...IDsCollection, ...edge.guid];
      }
    });
    return IDsCollection;
  } catch (error) {
    return [];
  }
};
// This function is applicable to get any key from any json string or object this code can handle null checks as well
export const getObjectFromJSONString = (
  JSONData,
  key,
  defaultValue = false
) => {
  let evaluatedValue = defaultValue;
  try {
    let inputStringAfterParsing =
      typeof JSONData === "string" ? JSON.parse(JSONData) : JSONData;
    evaluatedValue = eval(`inputStringAfterParsing${key}`);
  } catch (error) {}
  return evaluatedValue;
};
export const cleanValuesFromDataSet = (value, dataset = {}) => {
  let enhancedValueString = value.map((item) =>
    enhanceEvalString(
      item,
      {
        ...dataset,
      },
      false
    )
  );
  return processString(enhancedValueString);
};
const processString = (value) => {
  let processValues = [];
  try {
    if (Array.isArray(value)) {
      processValues = value.flatMap((valueItems) => {
        let valueItemClean = !!getObjectFromJSONString(valueItems, "")
          ? getObjectFromJSONString(valueItems, "")
          : "";
        return Array.isArray(valueItemClean)
          ? valueItemClean.map(
              (lookupValue) =>
                lookupValue.id || handleOptionSetValue(lookupValue)
            )
          : handleOptionSetValue(valueItemClean);
      });
    }
  } catch (error) {}

  return processValues;
};
