import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(
  asset,
  completion,
  am,
  amsubmitteddate,
  rad,
  radsubmitteddate
) {
  return { asset, completion, am, amsubmitteddate, rad, radsubmitteddate };
}

const rows = [
  createData("Melbourne Metro", "What is consumption?", 1, 24, 0, 5),
  createData("NGR", "What is energy?", "-", 37, 1, 6),
  createData("Horath", "What is coal?", 1, 24, "-", 7),
  createData("Live Oak", "What is petroleum?", "-", 67, 0, 8),
  createData("Akatsuki", "What is natural gas?", 0, 49, 1, 9),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const ESGReport = () => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" colSpan={2}>
              Asset Name
            </StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>
              2021
            </StyledTableCell>
            <StyledTableCell align="center" colSpan={2}>
              2022
            </StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell>Section 1</StyledTableCell>
            <StyledTableCell align="center">Climate Change</StyledTableCell>
            <StyledTableCell align="center">Answer</StyledTableCell>
            <StyledTableCell align="center">Comment</StyledTableCell>
            <StyledTableCell align="center">Answer</StyledTableCell>
            <StyledTableCell align="center">Comment</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.asset}>
              <StyledTableCell component="th" scope="row">
                {row.asset}
              </StyledTableCell>
              <StyledTableCell align="center">{row.completion}</StyledTableCell>
              <StyledTableCell align="center">{row.am}</StyledTableCell>
              <StyledTableCell align="center">
                {row.amsubmitteddate}
              </StyledTableCell>
              <StyledTableCell align="center">{row.rad}</StyledTableCell>
              <StyledTableCell align="center">
                {row.radsubmitteddate}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ESGReport;
