import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import A_Button from "../../../../../Atoms/Inputs/Buttons/A_Button";
import { httpPost } from "../../../../../../../Utils/apis/apis";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => ({
  buttons: {
    textAlign: "right",
  },
}));
const M_CreateOptionset = (props) => {
  const classes = useStyles();
  const labelName = props.label;
  const Name = props.data;
  const title = typeof props.title === "string" ? props.title : props.title[0];
  const closeIT = () => {
    props.close();
  };

  const SaveData = async () => {
    let payload = {
      id: "",
      label: labelName,
      properties: {
        [title]: Name,
      },
      edges: [],
      duplicateDetection: [[null]],
      security: {
        read: {
          groupids: [],
          users: [],
        },
        update: {
          groupids: [],
          users: [],
        },
        full: {
          groupids: [],
          users: [],
        },
        privateRecord: true,
      },
      inVProperty: null,
    };

    await httpPost(`/CRMData/insert`, payload);
    props.lookupCallBack(Name);
  };
  return (
    <div>
      <div>
        <h6>Really want to create this record {props.data}</h6>
      </div>
      <div className={classes.buttons}>
        {" "}
        <A_Button color="primary" label="OK" onClick={SaveData} />
        <A_Button color="" label="Cancel" onClick={closeIT} />
      </div>
    </div>
  );
};

M_CreateOptionset.propTypes = {
  label: PropTypes.string,
  data: PropTypes.string,
  close: PropTypes.func,
  lookupCallBack: PropTypes.func,
  title: PropTypes.object,
};
export default M_CreateOptionset;
