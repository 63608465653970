import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useParams } from "react-router";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  overflow: {
    overflow: "auto",
  },
  img: {
    width: "100%",
    height: "45rem",
  },
  message: {
    paddingLeft: "43%",
  },
});

const ImageCard = () => {
  const classes = useStyles();
  let { crmId } = useParams();
  const user = useSelector((state) => state.oidc.user);
  const [Image, setImage] = useState("");
  const [message, setMessage] = useState("Loading...");

  const getImage = (id) => {
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(`${process.env.DOCUMENT_HELPER}`, {
      method: "GET",
      headers: {
        filename: id + "/image",
        type: "image",
        "file-operation": "download",
        "process-file": "yes",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.blob())
      .then((data) => {
        if (data.size == 0) {
          setImage("");
          setMessage("WE ARE SORRY, NO IMAGE FOUND!");
        } else {
          const imageAsUrl = URL.createObjectURL(data);
          setImage(imageAsUrl);
        }
      });
  };
  useEffect(() => {
    getImage(crmId);
  }, []);

  return (
    <Box sx={{ p: "0 0" }} className={classes.overflow}>
      {Image == "" ? (
        <p className={classes.message}> {message}</p>
      ) : (
        <img className={classes.img} src={Image} alt={"image"} />
      )}
    </Box>
  );
};

export default ImageCard;
