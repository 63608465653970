import { getObjectFromJSONString } from "../../../components/Factory/Molecules/Common/Navigations/AddEntity/M_ManageEntityForm.helper";
import { httpPost, httpPut } from "../../apis/apis";
//Add
export const CreateDestinationVertex = async (payload, params = {}) => {
  let url = !!getObjectFromJSONString(
    params,
    ".entity.parameters.externalInsiderList"
  )
    ? "/Compliance/insiderList/insert"
    : "/CRMData/insert";
  const res = await httpPost(url, payload);
  return res;
};
//Edit
export const EditDestinationVertex = (payload) => {
  httpPost(`/CRMData/edit`, payload);
};
//addEdge
export const AddEdge = async (payload) => {
  const res = await httpPut(`/CRMData/addEdge`, payload);
  return res;
};
