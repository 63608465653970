export const toastSeverity = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  INFO: "info",
};
export const toastType = {
  DELETE_CRM: "deletecrm",
  CRM_FORM_UPDATE: "crm_form_update",
  PREVIOUS_UPLOAD_SAGA: "previous_upload_saga",
  DELETE_EDGE: "DELETE_EDGE",
  DELETE_RECORD: "DELETE_RECORD",
  EDGE_ADDED: "Record Added",
};
