import React from "react";
import { TextField } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import DoneIcon from "@material-ui/icons/Done";
import "./ESG.css";
import { SurveyApproval } from "./ESG_Config";
import { getSurveyBySurveyId } from "./ESG_API";
import { CircularProgress } from "@material-ui/core";
import {
  getInvestmentByCrmId,
  getInvestmentById,
  getResponseApprovalByAssetId,
  postResponseApproval,
} from "./ESG_API";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { Alert } from "@material-ui/lab";

const ESG_Approval = () => {
  const [loading, setIsLoading] = React.useState(true);
  const [isEligible, setIsEligible] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const [investmentId, setInvestmentId] = React.useState(null);
  const [comment, setComment] = React.useState("");
  const [successPost, setSuccessPost] = React.useState(false);
  const [failure, setFailure] = React.useState(false);
  const [errMessage, setErrorMessage] = React.useState("");

  const user = useSelector((state) => state.oidc.user);
  let surveyId = localStorage.getItem("Survey");
  let { crmId } = useParams();

  const handleClose = () => {
    setSuccessPost(false);
    setFailure(false);
  };

  const checkUserEligible = (investmentData) => {
    const userEmail = user.profile.email;
    let status = false;
    let data = [];
    if (userEmail && investmentData) {
      for (let key in investmentData) {
        if (investmentData[key].email === userEmail) {
          status = true;
          data.push(investmentData[key].role);
        }
      }
      return { status, data };
    }
    return { status, data };
  };

  const checkApprovedStatus = (eligibleData, submittedResponse) => {
    let flag = false;
    if (submittedResponse.length >= 2) {
      return flag;
    } else {
      let roleMatch = false;
      for (let i = 0; i < eligibleData.length; i++) {
        //  console.log(eligibleData[i],"inside....role1")
        let role = eligibleData[i];
        for (let j = 0; j < submittedResponse.length; j++) {
          if (role === submittedResponse[j].role) {
            roleMatch = true;
            break;
          }
          //  console.log(role,"inside....roleMatch",submittedResponse[i].role, roleMatch)
        }
        // if not match for AM/RAM any
        if (!roleMatch) {
          flag = true;
        }
      }
      return flag;
    }
  };

  const getSurveyData = async (surveyId) => {
    const crmData = await getInvestmentByCrmId(crmId);
    setInvestmentId(crmData.investmentId ? crmData.investmentId : null);
    const investmentData = await getInvestmentById(crmData.investmentId);
    const getEligibility = checkUserEligible(investmentData);
    // if user is eligible to approve
    if (getEligibility.status) {
      setIsEligible(true);
      const [data, submittedResponse] = await Promise.all([
        getSurveyBySurveyId(surveyId),
        getResponseApprovalByAssetId(crmData.investmentId, surveyId),
      ]);
      // add  other condition
      if (
        data &&
        SurveyApproval.includes(data.status) &&
        checkApprovedStatus(getEligibility.data, submittedResponse)
      ) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
    } else {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleOnChange = (event) => {
    let commentText = event.target.value;
    setComment(commentText ? commentText : "");
  };

  const postSubmission = async () => {
    const res = await postResponseApproval(comment, investmentId, surveyId);
    // console.log(res, res.status);
    if (res && res.statusMessage && res.statusMessage === "Success") {
      setSuccessPost(true);
      window.location.reload();
    } else {
      if (res && res.response && res.response.data) {
        setFailure(true);
        setErrorMessage(res.response.data.errorMsg);
      }
    }
    setComment("");
    setIsDisable(false);
  };

  React.useEffect(() => {
    if (surveyId) {
      getSurveyData(surveyId);
    } else {
      setIsLoading(false);
    }
  }, [surveyId]);

  return (
    <div className="esg-approval">
      <A_Snackbar
        open={successPost}
        message={"Successfully Approved"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={errMessage}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : isEligible ? (
        <>
          <h1>Comments</h1>
          <p>My Comments:</p>
          <form className="add-comment" noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              label="+Add"
              value={comment}
              fullWidth
              disabled={isDisable}
              onChange={handleOnChange}
            />
            <A_Button
              label="Confirm Submission"
              size={"large"}
              style={{
                marginTop: "10px",
                textTransform: "none",
                backgroundColor: `${isDisable ? "#6b7280" : "#16a34a"}`,
                color: "white",
              }}
              disabled={isDisable}
              startIcon={<DoneIcon />}
              onClick={() => {
                setIsDisable(true);
                postSubmission();
              }}
            />
          </form>
        </>
      ) : (
        <Alert severity="info">You are not authorized to approve this.</Alert>
      )}
    </div>
  );
};

export default ESG_Approval;
