import React, { useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import * as previousUploadActions from "../../../../../../../store/actions/previousUploadActions";
import * as previousUploadDeleteActions from "../../../../../../../store/actions/previousUploadDeleteActions";
import PropTypes from "prop-types";
import { downloadDocument } from "./previousUpload.helper";
import { getFileName } from "../../../../../Atoms/DataDisplay/DataGrid/helper/download.helper";

const useStyles = makeStyles(() => ({
  CustomCard: {
    maxHeight: "52vh",
    overflow: "auto",
  },
  list: {
    borderBottom: "1px solid lightgrey",
    "&:hover": {
      backgroundColor: "#f9f9f9",
      cursor: "pointer",
    },
  },
  avatar: {
    margin: "auto",
    paddingLeft: "10px",
  },
  heading: {
    marginTop: "10px",
  },
  ActionGroup: {
    paddingTop: "25px",
  },
  ListFlex: {
    display: "flex",
  },

  documentHead: {
    paddingTop: "1.2rem",
    fontSize: "1.8rem",
    color: "#9c8662",
  },
  documentFilename: {
    fontSize: "1.2rem",
    color: "#6ea1cc",
  },

  document: {
    cursor: "pointer",
  },
}));

const ResultDocDownload = (props) => {
  const classes = useStyles();
  const [reports, setReports] = useState([]);
  let crmId = props.crmId;
  React.useEffect(() => {
    setReports([]);
    getDocument();
  }, [crmId]);
  const getDocument = async () => {
    let container = props.document && props.document.container;
    let data = await getFileName(crmId, container);
    setReports(data);
  };

  const handleClick = async (e) => {
    let container = props.document && props.document.container;
    downloadDocument(e, container);
  };
  var documentName = _.get(props, "content.name");
  return (
    <>
      {documentName ? (
        <div className={classes.heading}>
          <h5>{documentName} </h5>
        </div>
      ) : (
        ""
      )}

      <div className={classes.CustomCard}>
        {/* TODO - correct logic as per new implementation (saga) */}
        {/* {isLoading && (
          <>
            <Skeleton variant="text" width={80} height={80} />
            <Skeleton variant="text" width={"100%"} height={80} />
            <Skeleton variant="rect" width={"100%"} height={118} />
          </>
        )} */}
        <div>
          {(reports && reports.length > 0) ||
          (props.document &&
            props.document.defaultDocument &&
            typeof props.document.defaultDocument === "string") ? (
            <Typography className={classes.documentHead}>Documents</Typography>
          ) : (
            ""
          )}
        </div>
        {props.document &&
        props.document.defaultDocument &&
        props.document.defaultBaseURL &&
        typeof props.document.defaultDocument === "string"
          ? props.document.defaultDocument.split("|").map((item, index) => (
              <div key={index}>
                <Typography className={classes.documentFilename}>
                  <a
                    className={`${classes.document} ${classes.documentFilename}`}
                    href={`${props.document.defaultBaseURL}/${item}`}
                    target="_blank" rel="noreferrer"
                  >
                    {item.split("/").pop()}
                  </a>
                </Typography>
              </div>
            ))
          : ""}
        {reports && reports.length > 0
          ? reports.map((item, index) => {
              return (
                <div key={index}>
                  <Typography className={classes.documentFilename}>
                    <span
                      className={classes.document}
                      onClick={() =>
                        handleClick({
                          ...item,
                          fileDisplayName: item.file.split("/").pop(),
                        })
                      }
                    >
                      {item.file.split("/").pop()}
                    </span>
                  </Typography>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};
ResultDocDownload.propTypes = {
  previousUpload: PropTypes.any,
  getPreviousUpload: PropTypes.any,
  getPreviousUploadDelete: PropTypes.any,
  delfile: PropTypes.func,
  currentPage: PropTypes.any,
  content: PropTypes.any,
  crmId:PropTypes.any,
  document:PropTypes.any,
};

function mapStateToProps(state) {
  return {
    previousUpload: state.previousUpload,
    previousUploadDelete: state.previousUploadDelete,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, previousUploadActions, previousUploadDeleteActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultDocDownload);
