import React, { useEffect, useState } from "react";
import M_ChartDataEntry from "../../../Molecules/Common/ChartData/M_ChartDataEntry";
import * as httpCall from "../../../../../Utils/apis/apis";
import { convertToEntryMetrics } from "./ChartDataEntryMapper";
import { useParams } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import { connect } from "react-redux";
import { filterRange } from "../../../../../Utils/config/config";
import PropTypes from "prop-types";
import { chatDataEnums } from "./chartData.Helper";

const O_ChartDataEntry = (props) => {
  const [dataPoints, setDataPoints] = useState(undefined);
  const [chartConfig, setChartConfig] = useState(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { crmId, templateId } = useParams();
  const pageFilters = props.toolbarSelections.filters;
  if (props.chart.parameters.investment == "=crmId") {
    props.chart.parameters.investment = crmId;
  }
  const fetchChartCallback = async () => {
    let chartApiData = await getChart(props.chart);

    const chartMetriPoints = convertToEntryMetrics(
      chartApiData.data,
      props.formName
    );
    setDataPoints(chartMetriPoints);
    setIsLoading(false);
  };

  useEffect(() => {
    setChartConfig({
      investmentId: props.chart.parameters.investment,
      chartId: chatDataEnums.DEFAULT_CHARTID,
      assetId: props.chart.parameters.investment,
      date: chatDataEnums.DEFAULT_DATE,
      seriesName: chatDataEnums.DEFAULT_SERIES,
    });
  }, []);
  // useEffect(() => {
  //   fetchChartCallback();
  // }, []);

  useEffect(() => {
    setDataPoints(undefined);
    setIsLoading(true);
    if (
      hasPageFilter(props.currentPage) &&
      pageFilters.length === props.filterLength
    ) {
      fetchChartCallback();
    } else if (!hasPageFilter(props.currentPage)) {
      fetchChartCallback();
    }
  }, [pageFilters]);

  //commented code maight be required for future use
  // const getStaticChart = async () => {
  //   const request = {
  //     chartId: chartConfig.chartId,
  //     parameters: {
  //       dt: chartConfig.date,
  //       investment: chartConfig.investmentId,
  //     },
  //   };
  //   return await getChart(request);
  // };

  const updateChartData = async (updatedRows) => {
    const object = {};
    pageFilters &&
      pageFilters.forEach((item) => {
        object[item.filterName] = item.value;
      });
    setIsSaving(true);
    let url = `${process.env.PortalAdminAPIUrl}/api/ManualUpdate/approval-data/insert`;
    const requetPayload = updatedRows.map((item) => {
      return {
        assetId: chartConfig.assetId,
        ...item,
        ...object,
      };
    });
    try {
      await httpCall.httpPost(url, requetPayload);
      window.location.reload();
    } finally {
      setIsSaving(false);
    }
  };
  const hasPageFilter = (currentPage) => {
    // check if page has the parameters
    let result = true;
    if (
      currentPage &&
      currentPage.pageConfig &&
      currentPage.pageConfig.toolbar
    ) {
      let toolbar = JSON.parse(currentPage.pageConfig.toolbar);
      let filters =
        toolbar && toolbar.primary && toolbar.primary.filters
          ? toolbar.primary.filters
          : [];
      let tampletFilters = [];
      filters.forEach((templets) => {
        templets.templateIds.forEach((id) => {
          if (id === templateId) {
            tampletFilters.push(id);
          }
        });
      });

      if (!(tampletFilters.length > 0)) {
        result = false;
      }
    } else {
      result = false;
    }
    return result;
  };
  const getChart = async (chartRequest) => {
    const url = "/ChartData/getchartdata";
    const requestPayload = chartRequest;
    if (pageFilters.length > 0 && hasPageFilter(props.currentPage)) {
      pageFilters.forEach((pageFilter) => {
        if (pageFilter.type && pageFilter.type === filterRange.RANGE) {
          requestPayload.parameters[chatDataEnums.TO] = pageFilter.to;
          requestPayload.parameters[chatDataEnums.FILTER_ON_DATE] =
            pageFilter && pageFilter.filterOnDateField
              ? pageFilter.filterOnDateField
              : undefined;
        }
        requestPayload.parameters[pageFilter.filterName] = pageFilter.value;
      });
    }
    const result = await httpCall.httpPost(url, requestPayload);
    return result;
  };

  const onSaveUpdates = (updatedRows) => {
    updateChartData(updatedRows);
    //console.log(updatedRows);
  };
  const onCancelUpdates = () => {
    fetchChartCallback();
  };

  return (
    <div>
      {chartConfig && dataPoints ? (
        <M_ChartDataEntry
          data={dataPoints}
          onSaveUpdates={onSaveUpdates}
          isSaving={isSaving}
          onCancelUpdates={onCancelUpdates}
          seriesName={chartConfig.seriesName}
        />
      ) : isLoading ? (
        <>
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
        </>
      ) : (
        <p>WE ARE SORRY, NO CONTENT FOUND!</p>
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    currentPage: state.currentPage,
    toolbarSelections: state.manageToolbarSelections,
    filterLength: state.manageToolbarSelections.filterLength,
  };
}
O_ChartDataEntry.propTypes = {
  toolbarSelections: PropTypes.any,
  filterLength: PropTypes.any,
  chart: PropTypes.any,
  formName: PropTypes.any,
  currentPage: PropTypes.object,
};
export default connect(mapStateToProps, undefined)(O_ChartDataEntry);
