import React, { useState } from "react";
// import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import { Chip, CircularProgress, Button } from "@material-ui/core";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import { message } from "../../../../../Utils/config/messages";
import Backdrop from "@material-ui/core/Backdrop";
import { unitJsonData, tableHeader } from "./ESG_Config";
import { useEffect } from "react";
import * as esgApi from "./ESG_API/index";
import SurveyFilter from "./components/SurveyFilter";
import SurveyFilterYear from "./components/SurveyFilterYear";
import { organizeQuestions } from "./utils";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { Alert } from "@material-ui/lab";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
  fixedHeader: {
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  inputcontrol: { minWidth: "20ch" },
  selectData: {
    minwidth: "20ch",
  },
  iconButton: {
    padding: "4px",
  },

  iconLabel: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginLeft: "4px",
    cursor: "pointer",
  },
  iconLabelLarge: {
    fontSize: "16px",
    "& > *": {
      marginBottom: "6px",
    },
  },
  columnHeader: {
    color: "#fff",
  },
  iconButtonLabel: {
    fontSize: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      marginBottom: "4px",
    },
    iconButtonMainContainer: {
      display: "flex",
      justifyContent: "space-evenly",
    },
  },
});

const ESG_QuestionnaireSetupView = (props) => {
  const classes = useStyles();
  // const [isChecked, setIsChecked] = useState(false);
  const [openRowIndex, setOpenRowIndex] = useState([]);
  // const [openSubRowIndex, setOpenSubRowIndex] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [allquestion, setallQuestion] = useState([]);
  const [allfilterData, setAllfilterData] = useState([]);
  // const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [surveyquestion, setSurveyQuestion] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishedDisabled, setIsPublishedDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [failure, setFailure] = useState(false);
  const [questionItem, setQuestionItem] = useState({});
  const [erromsg, setErrorMsg] = useState("");
  const [isPublishOpen, setIsPublishOpen] = useState(false);
  const { singleSurvey, setSingleSurvey } = props;
  const [allStates, setAllStates] = useState({
    pillar: [],
    subpillar: [],
    maturityassessment: [],
    paiframework: [],
    dataconvergenceframework: [],
    questionanstype: [],
    publishsurvey: [],
  });
  const [deleted, setDelete] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [surveydata, setSurveyData] = useState([]);
  const {
    pillar,
    // subpillar,
    maturityassessment,
    paiframework,
    dataconvergenceframework,
    questionanstype,
    // publishsurvey,
  } = allStates;
  // const { setOpen, setEdit } = props;
  const addedForm = localStorage.getItem("QuestionFormAdded")
    ? localStorage.getItem("QuestionFormAdded")
    : false;
  const [subPillardata, setSubPillardata] = useState([]);
  useEffect(() => {
    if (surveydata) {
      setSingleSurvey(
        Object.assign(
          {},
          surveydata.filter((item) => {
            return item.surveyId == selectedSurveyId;
          })
        )
      );
    }
  }, [selectedSurveyId, surveydata]);
  //Get All Pillar Data
  const getAllPillar = async () => {
    const response = await esgApi.getAllPillar();
    response.sort((a, b) => a.name.localeCompare(b.name));
    setAllStates((prevStates) => ({ ...prevStates, pillar: response }));
  };

  //Get SubPillar Data
  const getsubPillar = async () => {
    const resp = await esgApi.getSubPillar();
    resp.sort((a, b) => a.name.localeCompare(b.name));
    setSubPillardata(resp);
  };

  // Get All Maturity Assessment
  const getAllMaturityAssessment = async () => {
    const response = await esgApi.getAllMaturityAssessment();
    setAllStates((prevStates) => ({
      ...prevStates,
      maturityassessment: response,
    }));
  };

  //Get All PAI Framwork
  const getAllPaiFramework = async () => {
    const response = await esgApi.getAllPaiFramework();
    setAllStates((prevStates) => ({
      ...prevStates,
      paiframework: response,
    }));
  };
  //Get All Data Convergence Framwork
  const getAllDataConvergenceframework = async () => {
    const response = await esgApi.getAllDataConvergenceframework();
    setAllStates((prevStates) => ({
      ...prevStates,
      dataconvergenceframework: response,
    }));
  };
  //Get Question Answer Type
  const getQuestionAnstype = async () => {
    const response = await esgApi.getQuestionAnstype();
    setAllStates((prevStates) => ({
      ...prevStates,
      questionanstype: response,
    }));
  };

  //Publish Survey
  const publishSurvey = async () => {
    setIsLoading(true);
    try {
      const response = await esgApi.publishSurvey(selectedSurveyId);
      if (response && response.statusCode != 200) {
        setFailure(true);
        setErrorMsg(
          response && response.errorMsg
            ? response && response.errorMsg
            : "Some issue occured during publish"
        );
        setIsLoading(false);
        setIsPublishedDisabled(false);
      } else {
        setSuccess(true);
        setIsPublishedDisabled(true);
        window.location.reload();
      }
      setAllStates((prevStates) => ({
        ...prevStates,
        publishsurvey: response,
      }));
    } catch (error) {
      console.error("Failed to publish survey:", error);
    }
  };

  //Get All Question
  const getAllQuestion = async () => {
    const response = await esgApi.getAllQuestion();
    setallQuestion(organizeQuestions(response));
  };

  //Get Question By Survey Id
  const getQuestionsBySurveyId = async () => {
    const survey = JSON.parse(localStorage.getItem("Survey"));
    const resp = await esgApi.getQuestionsBySurvey(survey);
    if (resp) {
      setSurveyQuestion(resp.questionId);
    }
  };
  //Get All Survey
  const getAllSurvey = async () => {
    let response = await esgApi.getSurvey();
    setSurveyData(response);
  };

  const sortOnPillarAndSubPillar = (allQues) => {
    const pillarIndexMap = new Map(pillar.map((pillar, index) => [pillar.questioncategoryid, index]));
    const subPillarIndexMap = new Map(subPillardata.map((subPillar, index) => [subPillar.questionsubcategoryid, index]));

    // Sort filter data based on the sorted pillar array
    allQues.sort((a, b) => {
      const pillarAIndex = pillarIndexMap.get(a.questionCategoryId);
      const pillarBIndex = pillarIndexMap.get(b.questionCategoryId);

      const subPillarAIndex = subPillarIndexMap.get(a.questionSubCategoryId);
      const subPillarBIndex = subPillarIndexMap.get(b.questionSubCategoryId);

      if (pillarAIndex !== pillarBIndex) {
        return pillarAIndex - pillarBIndex;
      }

      return subPillarAIndex - subPillarBIndex;
    });
  }

  useEffect(() => {
    getAllPillar();
    getsubPillar();
    getAllMaturityAssessment();
    getAllPaiFramework();
    getAllDataConvergenceframework();
    getQuestionsBySurveyId();
    getAllQuestion();
    getQuestionAnstype();
    getAllSurvey();
  }, []);

  useEffect(() => {
    if (selectedSurveyId) {
      getQuestionsBySurveyId();
    }
  }, [selectedSurveyId]);

  useEffect(() => {
    if (allquestion.length > 0 && surveyquestion) {
      const allQues = allquestion.filter((question) =>
        surveyquestion.includes(question.questionId)
      );
      setIsLoading(false);
      if (surveyquestion) {
        sortOnPillarAndSubPillar(allQues)
        setAllfilterData(allQues);
        setIsLoading(false);
      } else setAllfilterData([]);
    }
    setIsLoading(false);
  }, [allquestion, surveyquestion]);

  useEffect(() => {
    if (addedForm === true || deleted === true) {
      getAllPillar();
      getAllMaturityAssessment();
      getAllPaiFramework();
      getAllDataConvergenceframework();
      getAllQuestion();
      getQuestionAnstype();
    }
  }, [addedForm, deleted]);

  //Edit Toggle PopOver
  const handleRowToggle = (parentIndex) => {
    const currentIndex = openRowIndex.indexOf(parentIndex);
    const newOpenRowIndex = [...openRowIndex];

    if (currentIndex === -1) {
      newOpenRowIndex.push(parentIndex);
    } else {
      newOpenRowIndex.splice(currentIndex, 1);
    }

    setOpenRowIndex(newOpenRowIndex);
  };

  // const handleChildToggle = (index) => {
  //   setOpenChildRowIndex((prevOpenRowIndices) =>
  //     prevOpenRowIndices.includes(index)
  //       ? prevOpenRowIndices.filter((i) => i !== index)
  //       : [...prevOpenRowIndices, index]
  //   );
  // };

  // const handleSubRowToggle = (index) => {
  //   setOpenSubRowIndex(openSubRowIndex === index ? null : index);
  // };

  const handleDelete = async () => {
    // API call to delete mapping
    const res = await esgApi.deleteSurveyQuestionMap(
      questionItem.questionId,
      JSON.parse(localStorage.getItem("Survey"))
    );

    if (res && res.statusMessage && res.statusMessage === "Success") {
      setSuccessDelete(true);
      window.location.reload();
    }

    if (
      res &&
      res.response &&
      res.response.data &&
      res.response.data.errorMsg
    ) {
      setFailure(true);
      setErrorMsg(res.response.data.errorMsg);
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
  };

  const handlePublish = () => {
    setIsPublishedDisabled(true);
    publishSurvey();
  };

  const handleConfirmDelete = async () => {
    const deleteid = localStorage.getItem("DeleteId");

    let res = await esgApi.deleteQuestion(deleteid);

    if (res.statusCode == 200) {
      localStorage.setItem("addedForm", true);
      setDelete(true);
    }
    window.location.reload();
    setIsOpen(false);
  };

  const getActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="OK"
          autoFocus
          onClick={handleConfirmDelete}
        />
        <A_Button color="default" label="Cancel" onClick={handleCancelDelete} />
      </>
    );
  };

  const handlePublishConfirmPublish = () => {
    handlePublish();
  };

  const handleDeleteConfirm = () => {
    handleDelete();
  };

  const handlePublishCancelPublish = () => {
    setIsPublishOpen(false);
  };

  let handlePublishPopup = () => {
    setIsPublishOpen(true);
  };

  let handleDeletePopUp = () => {
    setDeletePopUp(true);
  };

  const handleDeletePopUpCancel = () => {
    setDeletePopUp(false);
  };

  const getPublishActionItems = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handlePublishConfirmPublish}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handlePublishCancelPublish}
        />
      </>
    );
  };

  const getDeleteActionItem = () => {
    return (
      <>
        <A_Button
          color="primary"
          label="Yes"
          autoFocus
          onClick={handleDeleteConfirm}
        />
        <A_Button
          color="default"
          label="No"
          onClick={handleDeletePopUpCancel}
        />
      </>
    );
  };

  const handleCancelDelete = () => {
    setIsOpen(false);
  };

  let handlePopup = () => {
    setIsOpen(true);
  };
  useEffect(() => {
    if (allfilterData.length > 0) {
      setIsLoading(false);
    } else if (!selectedSurveyId && !surveydata) {
      setIsLoading(false);
    }
    // else setIsLoading(true);
  }, [allfilterData]);
  // const handlePopoverClose = () => {
  //   setOpenPopoverIndex(null);
  // };

  // const handleEdit = (data) => {
  //   setOpen(true);
  //   setEdit(true);
  //   localStorage.setItem("SubQuestion", JSON.stringify(data));
  // };

  const [scrolling, setScrolling] = useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    setScrolling(offset > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderNestedList = (item, parentIndex) => {
    return (
      <Collapse
        in={openRowIndex.includes(parentIndex)}
        timeout="auto"
        unmountOnExit
        key={parentIndex}
      >
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            {item.children && item.children.length > 0 && (
              <TableBody>
                {item.children.map((child, subIndex) => {
                  if (true) {
                    const Pillar = pillar.find(
                      (element) =>
                        element.questioncategoryid === child.questionCategoryId
                    );

                    const SubPillar = subPillardata.find(
                      (element) =>
                        element.questionsubcategoryid ===
                        child.questionSubCategoryId
                    );
                    const MaturityAssessment = maturityassessment.find(
                      (element) =>
                        element.maturityassessmentid ===
                        child.maturityAssessmentId
                    );
                    const QuesAnsType = questionanstype.find(
                      (element) =>
                        element.questionanswertypeid ===
                        child.questionAnswerTypeId
                    );
                    const Unit = unitJsonData.find(
                      (element) =>
                        element.measurementunitid == child.measurementUnitId
                    );
                    const PaiFramework = paiframework.find(
                      (element) =>
                        element.paiframeworkid === child.paiFrameworkId
                    );
                    const DataConvergenceFramework =
                      dataconvergenceframework.find(
                        (element) =>
                          element.dataConvergenceframeworkid ===
                          child.dataConvergenceFrameworkId
                      );
                    return (
                      <React.Fragment
                        key={`subIndex${subIndex}${child.questionCategoryId}`}
                      >
                        {Pillar &&
                          SubPillar &&
                          MaturityAssessment &&
                          QuesAnsType &&
                          // Unit &&
                          paiframework &&
                          DataConvergenceFramework ? (
                          <>
                            <StyledTableRow
                              key={`subIndex${subIndex}${child.questionCategoryId}`}
                            >
                              <StyledTableCell style={{ width: "4ch" }}>
                                {child.children &&
                                  child.children.length > 0 && (
                                    <IconButton
                                      aria-label="expand row"
                                      size="small"
                                      onClick={() =>
                                        handleRowToggle(
                                          `subIndex${subIndex}${child.questionCategoryId}`
                                        )
                                      }
                                    >
                                      {openRowIndex.includes(
                                        `subIndex${subIndex}${child.questionCategoryId}`
                                      ) ? (
                                        <KeyboardArrowUpIcon />
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </IconButton>
                                  )}
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{Pillar && Pillar.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{SubPillar && SubPillar.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>
                                  {MaturityAssessment &&
                                    MaturityAssessment.name}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{child.questionText}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                {child.hasSubQuestions == true
                                  ? child.subQuestion.map((subques, index) => {
                                    return (
                                      <span key={index}>
                                        <Chip
                                          label={subques.text}
                                          variant="outlined"
                                        />
                                      </span>
                                    );
                                  })
                                  : "-"}
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{QuesAnsType && QuesAnsType.name}</div>
                              </StyledTableCell>
                              <StyledTableCell style={{ width: "19ch" }}>
                                <div>{child.guidance}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.measurementUnitId}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{PaiFramework && PaiFramework.name}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>
                                  {DataConvergenceFramework &&
                                    DataConvergenceFramework.name}
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.importHistoricData.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.isStatic.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "19ch" }}
                              >
                                <div>{child.rating.toString()}</div>
                              </StyledTableCell>
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ width: "12ch" }}
                              ></StyledTableCell>
                            </StyledTableRow>
                            <TableRow>
                              <TableCell
                                style={{
                                  paddingBottom: 0,
                                  paddingTop: 0,
                                }}
                                colSpan={17}
                                className={classes.table}
                              >
                                {child.children &&
                                  child.children.length > 0 &&
                                  renderNestedList(
                                    child,
                                    `subIndex${subIndex}${child.questionCategoryId}`
                                  )}
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={8}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                No Child Found
                              </span>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  }
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Collapse>
    );
  };

  return (
    <div>
      <div>
        <SurveyFilter
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
        />
      </div>
      <div>
        <SurveyFilterYear
          selectedSurveyId={selectedSurveyId}
          filtersurveyyear={filtersurveyyear}
          setSelectedSurveyId={setSelectedSurveyId}
          setSurveyFilterYear={setSurveyFilterYear}
        />
      </div>
      <div>
        <A_Snackbar
          open={successDelete}
          message={"Successfully Deleted"}
          autoHideDuration={1000}
          horizontal="center"
          handleClose={handleClose}
        />
        <A_Snackbar
          open={failure}
          message={erromsg}
          autoHideDuration={2000}
          horizontal="center"
          handleClose={handleClose}
          type="error"
        />
        <div>
          {singleSurvey &&
            singleSurvey[0] &&
            (singleSurvey[0].status == 1 || singleSurvey[0].status == 2) && (
              <Alert severity="info">
                This questionnaire has already been published/closed. Hence the
                questions cannot be modified or removed.
              </Alert>
            )}
        </div>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead className={scrolling ? classes.fixedHeader : ""}>
              <TableRow>
                <StyledTableCell> </StyledTableCell>
                {tableHeader.map((item, index) => {
                  return (
                    <StyledTableCell key={index}>
                      <div
                        style={{ width: "25ch", position: "sticky", zIndex: 1 }}
                      >
                        {item}
                      </div>
                    </StyledTableCell>
                  );
                })}
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allfilterData && allfilterData.length > 0 ? (
                allfilterData.map((item, index) => {
                  const Pillar = pillar.find(
                    (element) =>
                      element.questioncategoryid === item.questionCategoryId
                  );

                  const SubPillar = subPillardata.find(
                    (element) =>
                      element.questionsubcategoryid ===
                      item.questionSubCategoryId
                  );
                  const MaturityAssessment = maturityassessment.find(
                    (element) =>
                      element.maturityassessmentid === item.maturityAssessmentId
                  );
                  const QuesAnsType = questionanstype.find(
                    (element) =>
                      element.questionanswertypeid === item.questionAnswerTypeId
                  );
                  const Unit = unitJsonData.find(
                    (element) =>
                      element.measurementunitid == item.measurementUnitId
                  );
                  const PaiFramework = paiframework.find(
                    (element) => element.paiframeworkid === item.paiFrameworkId
                  );
                  const DataConvergenceFramework =
                    dataconvergenceframework.find(
                      (element) =>
                        element.dataConvergenceframeworkid ===
                        item.dataConvergenceFrameworkId
                    );
                  return (
                    <React.Fragment key={`index${index}`}>
                      {Pillar &&
                        SubPillar &&
                        MaturityAssessment &&
                        QuesAnsType &&
                        paiframework &&
                        DataConvergenceFramework ? (
                        <>
                          <StyledTableRow key={`index${index}`}>
                            <StyledTableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => handleRowToggle(`index${index}`)}
                              >
                                {openRowIndex.includes(`index${index}`) ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{Pillar && Pillar.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{SubPillar && SubPillar.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>
                                {MaturityAssessment && MaturityAssessment.name}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{item.questionText}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              {item.hasSubQuestions == true
                                ? item.subQuestion.map((subques, index) => {
                                  return (
                                    <span key={index}>
                                      <Chip
                                        label={subques.text}
                                        variant="outlined"
                                      />
                                    </span>
                                  );
                                })
                                : "-"}
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{QuesAnsType && QuesAnsType.name}</div>
                            </StyledTableCell>
                            <StyledTableCell>
                              <div>{item.guidance}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.measurementUnitId}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{PaiFramework && PaiFramework.name}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>
                                {DataConvergenceFramework &&
                                  DataConvergenceFramework.name}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.importHistoricData.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.isStatic.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>{item.rating.toString()}</div>
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              <div>
                                {" "}
                                <span>
                                  {" "}
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    p={2}
                                  >
                                    <div
                                      className={
                                        classes.iconButtonMainContainer
                                      }
                                    >
                                      <Button
                                        onClick={() => {
                                          setDeletePopUp(true),
                                            setQuestionItem(item);
                                        }}
                                        variant="contained"
                                        color="secondary"
                                        disabled={
                                          singleSurvey &&
                                          singleSurvey[0] &&
                                          (singleSurvey[0].status == 1 ||
                                            singleSurvey[0].status == 2)
                                        }
                                      >
                                        Remove
                                      </Button>
                                      {/* <IconButton
                                        aria-label="delete"
                                        onClick={() => {
                                          handleDelete(item);
                                        }}
                                        className={`${classes.iconButton} ${classes.deleteIconButton}`}
                                      >
                                        <DeleteIcon
                                          className={classes.iconLabelLarge}
                                        />
                                      </IconButton> */}
                                    </div>
                                  </Box>
                                </span>
                              </div>
                            </StyledTableCell>
                          </StyledTableRow>
                          <TableRow>
                            <TableCell
                              style={{
                                paddingBottom: 0,
                                paddingTop: 0,
                              }}
                              colSpan={15}
                              className={classes.table}
                            >
                              {item.children &&
                                item.children.length > 0 &&
                                renderNestedList(item, `index${index}`)}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell colSpan={8}>
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              No Data Found
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <span style={{ display: "flex", justifyContent: "center" }}>
                      No Data Found
                    </span>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Backdrop style={{ color: "#fff", zIndex: 999 }} open={isLoading}>
        <CircularProgress
          color="inherit"
          style={{ color: "#fff", zIndex: 999 }}
        />
      </Backdrop>
      <A_SimpleDialog
        id="2"
        open={isOpen}
        closePopUp={handlePopup}
        title="Delete"
        dialogContent={message.DELETE_WARNING}
        dialogActions={getActionItems()}
        onClose={handleCancelDelete}
      />
      {allfilterData.length > 0 && (
        <div style={{ textAlign: "right" }}>
          <A_Button
            textAlign={"right"}
            label={"Publish"}
            style={{ margin: "6px 0px" }}
            color={"primary"}
            onClick={handlePublishPopup}
            disabled={
              isPublishedDisabled ||
              (singleSurvey &&
                singleSurvey[0] &&
                (singleSurvey[0].status == 1 || singleSurvey[0].status == 2))
            }
          ></A_Button>
        </div>
      )}
      <A_Snackbar
        open={success}
        message={"Successfully publish"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={erromsg}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
      <A_SimpleDialog
        open={deletePopUp}
        dialogContent=""
        closePopUp={handleDeletePopUp}
        title={`Are you sure you want to remove this question from this questionnaire?`}
        dialogActions={getDeleteActionItem()}
        onClose={handleDeletePopUpCancel}
      />
      <A_SimpleDialog
        id="2"
        open={isPublishOpen}
        dialogContent=""
        closePopUp={handlePublishPopup}
        title={`Are you sure you want to publish the questionnaire for the year ${filtersurveyyear} across all the assets?`}
        dialogActions={getPublishActionItems()}
        onClose={handlePublishCancelPublish}
      />
    </div>
  );
};

ESG_QuestionnaireSetupView.propTypes = {
  open: PropTypes.any,
  setOpen: PropTypes.any,
  setEdit: PropTypes.any,
  singleSurvey: PropTypes.any,
  setSingleSurvey: PropTypes.any,
};

export default ESG_QuestionnaireSetupView;
