import React, { useEffect } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Gallery from "react-grid-gallery";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import propTypes from "prop-types";
import { Box } from "@material-ui/core";
import GridView from "./GridView";


const ImageGallery = (props) => {
  const user = useSelector((state) => state.oidc.user);
  const [imageData, setImageData] = React.useState([]);
  let { crmId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(
    Math.floor(Math.random() * (999 - 100 + 1) + 100)
  );

  useEffect(() => {
    setLoading(true);
    let subdomain = new URL(window.location.href).hostname.split(".")[0];
    fetch(`${process.env.BULK_DOCUMENT_HELPER}`, {
      method: "POST",
      body: JSON.stringify([{ items: [crmId], type: "gallery" }]),
      headers: {
        "file-operation": "blob-items-link",
        "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
        subdomain: subdomain,
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then((success) => success.json())
      .then((data) => {
        setLoading(false);
        const imagePayload =
          data &&
          data.gallery &&
          data.gallery.length > 0 &&
          data.gallery.map((item) => {
            return {
              src: item.link,
              thumbnail: item.link,
              thumbnailWidth: 320,
              thumbnailHeight: 174,
              isSelected: false,
              caption: "",
            };
          });
        setImageData(imagePayload);
      });
  }, [status]);

  return (
    <Box className="ImageGall">
      <div>
        <GridView content={props.content} setStatus={setStatus} />
      </div>
      {loading ? (
        <>
          <Skeleton variant="text" width={80} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="rect" width={"100%"} height={118} />
        </>
      ) : (
        <>
          {/* https://benhowell.github.io/react-grid-gallery/ */}

          {imageData.length > 0 ? (
            <Gallery
              images={imageData}
              enableImageSelection={false}
              showLightboxThumbnails={true}
            />
          ) : (
            ""
          )}
        </>
      )}
    </Box>
  );
};

ImageGallery.propTypes = {
  content: propTypes.any,
};
export default ImageGallery;
