import Moment from "moment";
import { dateConstants } from "../config/config";
import { handleOptionSetValue } from "./optionSet.helper";

export const getFormattedDate = (
  anyDate,
  anyFormat,
  fallBackFormat = "MMM-yy-dd"
) => {
  if (anyDate === undefined || anyDate === 0) return "";
  const newDate = Moment(anyDate).format(
    anyFormat ? anyFormat : fallBackFormat
  );
  return newDate == "Invalid date" ? "" : newDate;
};

export const customDateFormat = (anyDate, format) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let CopyDate = anyDate;
  try {
    if (anyDate && anyDate != 0) {
      typeof anyDate === "string" ? (anyDate = new Date(anyDate)) : "";
      var halfMonth = months[anyDate.getUTCMonth()].substring(0, 3);
      var fullMonth = months[anyDate.getUTCMonth()];
      var fullYear = anyDate.getFullYear();
      var numericMonth = ("0" + (anyDate.getUTCMonth() + 1)).slice(-2);
      var utcDate = ("0" + anyDate.getUTCDate()).slice(-2);
      var utcMonth = ("0" + (anyDate.getUTCMonth() + 1)).slice(-2);
      switch (format) {
        case 1:
          return utcDate + "/" + utcMonth + "/" + fullYear;
        case 2:
          return halfMonth + "-" + fullYear;
        case "MMM-YYYY":
          return halfMonth + "-" + fullYear;
        case "DD/MM/YYYY":
          return utcDate + "/" + numericMonth + "/" + fullYear;
        case "DD/MM/YY":
          return utcDate + "/" + numericMonth + "/" + (fullYear % 100);
        case "MM/DD/YY":
          return numericMonth + "/" + utcDate + "/" + (fullYear % 100);
        case "YY/MM/DD":
          return (fullYear % 100) + "/" + numericMonth + "/" + utcDate;
        case "DD MMMM YYYY":
          return utcDate + " " + fullMonth + " " + fullYear;
        case "DD-MMMM-YYYY":
          return utcDate + "-" + fullMonth + "-" + fullYear;
        case "DD/MMMM/YYYY":
          return utcDate + "/" + fullMonth + "/" + fullYear;
        case "DD MMM YYYY":
          return utcDate + " " + halfMonth + " " + fullYear;
        case "DD-MMM-YYYY":
          return utcDate + "-" + halfMonth + "-" + fullYear;
        case "DD/MMM/YYYY":
          return utcDate + "/" + halfMonth + "/" + fullYear;
        case "MMMM YYYY":
          return fullMonth + " " + fullYear;
        case "YYYY":
          return fullYear;
        default:
          return utcDate + "/" + utcMonth + "/" + fullYear;
      }
    }
  } catch {
    try {
      let date;
      if (typeof CopyDate == "number") {
        let epochfiltered = FromEpochFormat(CopyDate);
        date = getFormattedDate(epochfiltered, format);
      } else {
        let newDateJSON = CopyDate ? JSON.parse(CopyDate) : "";
        let newDate = new Date(newDateJSON.dateTime + "Z");
        date = customDateFormat(newDate, format);
      }
      return date;
    } catch {
      return CopyDate;
    }
  }
};

export const FromEpochFormat = (dateValue) => {
  return typeof dateValue === "number" && dateValue > 0
    ? new Date(dateValue * 1000)
    : dateValue;
};

export const ChangeTime = (inputTime) => {
  const date = new Date(inputTime);
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};

export const toEpochFormat = (dateValue, condition = true) => {
  let value = condition ? ChangeTime(dateValue) : dateValue;
  let convertedValue = Math.floor(value.getTime() / 1000.0);
  return convertedValue ? convertedValue : dateValue;
};

export const timeReplaceFromEpoch = (dateValue, time) => {
  let timeValue = handleOptionSetValue(time);
  let newDateValue =
    typeof dateValue === "number" ? new Date(dateValue * 1000) : dateValue;
  if (typeof newDateValue === "object") {
    let tempDate = new Date(
      newDateValue.getFullYear(),
      newDateValue.getMonth(),
      newDateValue.getDate(),
      timeValue.split(":")[0],
      timeValue.split(":")[1]
    );
    newDateValue = toEpochFormat(tempDate, false);
  }
  return newDateValue;
};

// New helper function that can format dates into most of the date formats if anyone wants to use date formatting from epoch to date please use it it will take 2 parameters 1st as an integer and 2nd as a string which is the date format
export const epochToDateFormatHelper = (
  dateInEpoch = 0,
  formatInString = ""
) => {
  try {
    if (dateInEpoch === "") {
      return dateInEpoch;
    }
    let dateStringInEpoch = dateInEpoch;
    let dateTimeString = new Date(
      typeof dateStringInEpoch === "string"
        ? dateStringInEpoch
        : typeof dateStringInEpoch === "number"
        ? dateStringInEpoch * 1000
        : ""
    );
    const months = dateConstants.MONTHS;
    let day = dateTimeString.getDate().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let month = dateTimeString.getMonth();
    let year = dateTimeString.getFullYear();
    let hour = dateTimeString.getHours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let minute = dateTimeString.getMinutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let second = dateTimeString.getSeconds().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let monthDisplay = (month + 1).toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    let monthShort = months[month].slice(0, 3);
    let monthLong = months[month];
    let format = formatInString;
    const formattedDate = format
      .replace("YYYY", year)
      .replace("YY", year.toString().slice(-2))
      .replace("MMMM", monthLong)
      .replace("MMM", monthShort)
      .replace("MM", monthDisplay)
      .replace("DD", day)
      .replace("HH", hour)
      .replace("mm", minute)
      .replace("ss", second);
    return formattedDate;
  } catch (e) {
    return dateInEpoch;
  }
};

// converting number epoch to the formate (DD/MM/YYYY)
export const convertEpochToDateString = (epoch) => {
  // Convert epoch to milliseconds
  var timestamp = epoch * 1000; // Create a new Date object with the timestamp
  var date = new Date(timestamp); // Extract the date components (day, month, year) from the local date
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero-based
  var year = date.getFullYear(); // Format the date components into DD/MM/YYYY format
  var formattedDate = `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;

  return formattedDate;
};
// Converting Current Epoch Date to Date in the Foramt (DDMMYYY)
export const convertCurrentEpochToDateFormat = () => {
  const currentTimestamp = Date.now();
  // Create a Date object with the current timestamp
  const currentDate = new Date(currentTimestamp);
  // Format the date as "14 Nov 2023"
  const formattedDate = currentDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const dateObject = new Date(formattedDate);
  // Extract day, month, and year
  const day = dateObject.getDate();
  const month = dateObject.getMonth() + 1;
  const year = dateObject.getFullYear();
  // Format with leading zeros if needed
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  // Create the final formatted date
  const formattedDates = formattedDay + formattedMonth + year;
  return formattedDates;
};
