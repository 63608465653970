import * as httpCall from "../../../../../Utils/apis/apis";
export const lookupDefaultValue = (data, crmId, currentPage) => {
    var title;
    try {
        var NewData = JSON.parse(currentPage[data.prePopulate])
        title = NewData[0].title;
    } catch (e) {
        title = currentPage[data.prePopulate]
    }
    var id = crmId;
    var label = data && data.destinationVertex ? data.destinationVertex : "";
    var email = "";
    var reqObject = {
        data: {
            id: id,
            label: label,
            title: title,
            email: email,
        },
        edgeLabel: data && data.edgeLabel ? data.edgeLabel : "",
        fieldType: data && data.fieldType ? data.fieldType : "",
    };
    return reqObject;
};
export const asyncCheckbox = {
    fields:[
        "status",
        "Rationale for operational rating"
      ],
      filterOnDateField: "Date",
      label: "asset",
      status:"status",
      Rationale:"Rationale for operational rating"
  };
export const updateStatus = async (d, status, pageUrl) => {
    let payload = {
      id: d,
      currentStatus: status,
    };
    await httpCall.httpPost(
      `/CRMData/updateDealStatus?pageUrl=${pageUrl}`,
      payload
    );
  };