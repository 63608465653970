import React, { useState } from "react";
import { Tooltip, IconButton, withStyles } from "@material-ui/core";
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    paddingTop:"30px",
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    minWidth: 50,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    whiteSpace: "normal",
    textAlign: "center",
    position: 'relative',
  },
}))(Tooltip);

const CopyableTooltip = (props) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleCopy = ()=> {
    navigator.clipboard.writeText(props.comment);
    handleTooltipClose();
  }

  return (
    <HtmlTooltip
      open={isTooltipOpen}
      title={
        <React.Fragment>
        {props.comment}
          <label
            style={{
              position: 'absolute',
              top: '5px', 
              right: '5px', 
            }}
          > 
          <FileCopyTwoToneIcon onClick={handleCopy}/>
           </label>
        </React.Fragment>
      }
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      interactive
      arrow
    >
      <IconButton aria-label="info" style = {{fontSize:"15px",fontWeight:"bold"}}>
        {props.answer}
      </IconButton>
    </HtmlTooltip>
  );
};

export default CopyableTooltip;
