export const rgbtoHex = (value) => {
  let htmlString = value;

  let regex =
    /(?:rgba?\(([\d., ]+)\)|hsla?\(([\d., ]+)\)|rgb\(([\d., ]+)\)|hsl\(([\d., ]+)\))/gi;

  // Function to convert RGB/RGBA to hex color
  function rgbConverter(r, g, b, a) {
    let alpha =
      typeof a !== "undefined" && isNaN(a) == false
        ? Math.round(a * 255)
            .toString(16)
            .padStart(2, "0")
        : "";
    return `#${r.toString(16).padStart(2, "0")}${g
      .toString(16)
      .padStart(2, "0")}${b.toString(16).padStart(2, "0")}${alpha}`;
  }

  // Function to convert HSL/HSLA to hex color
  function hslConverter(h, s, l, a) {
    let alpha =
      typeof a !== "undefined"
        ? Math.round(a * 255)
            .toString(16)
            .padStart(2, "0")
        : "";
    let rgbColor = hslToRgb(h, s, l);
    return `#${rgbColor.r.toString(16).padStart(2, "0")}${rgbColor.g
      .toString(16)
      .padStart(2, "0")}${rgbColor.b.toString(16).padStart(2, "0")}${alpha}`;
  }

  // Helper function to convert HSL to RGB
  function hslToRgb(h, s, l) {
    h /= 360;
    s /= 100;
    l /= 100;

    let r, g, b;

    if (s === 0) {
      r = g = b = l;
    } else {
      let hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      r = Math.round(hue2rgb(p, q, h + 1 / 3) * 255);
      g = Math.round(hue2rgb(p, q, h) * 255);
      b = Math.round(hue2rgb(p, q, h - 1 / 3) * 255);
    }

    return { r, g, b };
  }

  // Replace color codes with hex color in the HTML string
  htmlString = htmlString.replace(regex, (match, colorValues) => {
    let values = colorValues
      .split(",")
      .map((value) => parseFloat(value.trim()));
    let colorType = match.split("(")[0];
    let hexColor;

    switch (colorType) {
      case "rgb":
      case "rgba":
        hexColor = rgbConverter(...values);
        break;
      case "hsl":
      case "hsla":
        hexColor = hslConverter(...values);
        break;
      default:
        hexColor = match;
    }

    return hexColor;
  });

  return htmlString;
};

export const styleReplacer = (htmlString) => {
  // Create a temporary element to parse the HTML string
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;

  // Find all elements with inline styles
  const elementsWithStyles = tempElement.querySelectorAll("[style]");

  // Loop through each element and check for 'position: absolute'
  elementsWithStyles.forEach((element) => {
    const style = element.getAttribute("style");
    if (style.includes("position: absolute")) {
      // Replace 'position: absolute' with 'position: relative'
      const updatedStyle = style.replace(
        "position: absolute",
        "position: relative"
      );
      element.setAttribute("style", updatedStyle);
    }
  });

  // Return the modified HTML string
  return tempElement.innerHTML;
};

export const cleanHTML = (htmlString) => {
  const regexPattern = /<[^>]*(>|$)|\n/g;
  const cleanHtml = htmlString.replace(regexPattern, (match) => {
    if (match === "\n") {
      return match; // Retain line breaks
    } else if (match.toLowerCase().includes("<button")) {
      return ""; // Remove <button> tags
    } else if (
      match.toLowerCase().includes("<img") ||
      match.toLowerCase().includes('<input type="radio"') ||
      match.toLowerCase().includes('<input type="checkbox"')
    ) {
      return ""; // Remove <img>,<input type="radio">, and <input type="checkbox"> tags
    } else {
      return match;
    }
  });
  return cleanHtml.replace(/<h([1-6])([^>]*)>(.*?)<\/h\1>/g, "<p>$3</p>");
};

export const filterHtmlOnPaste = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  function removeAttributesRecursively(element) {
    if (element.nodeType === Node.ELEMENT_NODE) {
      if (element.tagName.toLowerCase() === "a") {
        const attributes = element.attributes;
        for (let i = attributes.length - 1; i >= 0; i--) {
          try {
            const attribute = attributes[i];
            if (attribute.name.toLowerCase() === "style") {
              element.removeAttribute("style");
              if (i == 0) break;
            }
            if (attribute.name.toLowerCase() === "class") {
              element.removeAttribute("class");
              if (i == 0) break;
            }
          } catch {
            break;
          }
        }
      } else {
        while (element.attributes.length > 0) {
          element.removeAttribute(element.attributes[0].name);
        }
      }
    }
    for (let i = 0; i < element.childNodes.length; i++) {
      removeAttributesRecursively(element.childNodes[i]);
    }
  }
  removeAttributesRecursively(doc.body);
  let finalHtmlString = removeInlineStyles(doc.body.innerHTML);
  return finalHtmlString;
};
export const summernoteTagRemover = (htmlString) => {
  let data = htmlString.replace(
    /<html>|<\/html>|<body>|<\/body>|<!--StartFragment-->|<!--EndFragment-->|<!--.*?-->/g,
    ""
  );
  data = filterHtmlOnPaste(data);
  return cleanHTML(data);
};
export const removeInlineStyles = (htmlString) => {
  // Remove inline styles within <style> tags (Internal styles)
  return htmlString.replace(
    /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi,
    ""
  );
};

export function limitHtmlStringWithTags(htmlString, limit) {
  htmlString =
    htmlString &&
    htmlString.replace(
      /&[a-zA-Z]+;|&[a-zA-Z]+;/g,
      (match) =>
        ({
          "&lt;": "<",
          "&gt;": ">",
          "&quot;": '"',
          "&apos;": "'",
          "&nbsp;": " ",
          "&amp;": "&",
          "&copy;": "©",
          "&reg;": "®",
          "&trade;": "™",
          "&euro;": "€",
        }[match])
    );
  htmlString = removeEmptyTags(htmlString);
  let count = 0;
  let truncatedHtml = "";
  let flag = false;
  for (let i = 0; i < htmlString.length; i++) {
    if (htmlString[i] === "<") {
      flag = true;
      truncatedHtml += htmlString[i];
    } else if (!flag) {
      if (count != limit) {
        truncatedHtml += htmlString[i];
        count++;
      }
    } else if (htmlString[i] === ">") {
      flag = false;
      truncatedHtml += htmlString[i];
    } else {
      truncatedHtml += htmlString[i];
    }
  }
  // Remove empty tags
  truncatedHtml = removeEmptyTags(truncatedHtml);
  return truncatedHtml;
}
export function removeEmptyTags(html) {
  // Remove empty self-closing tags <></>
  let cleanedHtml = html.replace(/<[^>]+\/>/g, "");

  // Remove empty opening tags <></> and their corresponding closing tags
  cleanedHtml = cleanedHtml.replace(/<(\w+)[^>]*>\s*<\/\1>/g, "");

  // Handle nested empty tags using a loop
  while (/<(\w+)[^>]*>\s*<\/\1>/g.test(cleanedHtml)) {
    cleanedHtml = cleanedHtml.replace(/<(\w+)[^>]*>\s*<\/\1>/g, "");
  }
  cleanedHtml = styleReplacer(cleanedHtml);
  return cleanedHtml;
}

export function removeLineHeightStylesFromHtml(htmlString) {
  const doc = new DOMParser().parseFromString(htmlString, "text/html");
  const removeStyles = (element) => {
    if (element.style) {
      element.style.removeProperty("line-height");
      element.style.removeProperty("position");
    }
    for (const child of element.children) {
      removeStyles(child);
    }
  };

  removeStyles(doc.body);

  return new XMLSerializer().serializeToString(doc);
}
