import React from "react";
import PropTypes from "prop-types";
import M_MeetingSyncs from "../../MeetingSync/M_MeetingSync";
import M_MeetingsResultTemplate from "../../MeetingSync/M_MeetingsResultTemplate";
import M_MeetingSyncEA from "../../MeetingSync/M_MeetingSyncEA";

export default function M_CustomCards(props) {
  const { item } = props;
  const customCardName = item && item.content && item.content.customCardName;
  let cardComponent;

  switch (customCardName) {
    case "meetingSync":
      cardComponent = <M_MeetingSyncs content={props} />;
      break;
    case "MeetingsResultTemplate":
      cardComponent = (
        <M_MeetingsResultTemplate
          content={props.content}
          stateObj={props.state}
          item={props.item}
        />
      );
      break;
    case "meetingSyncEA":
      cardComponent = <M_MeetingSyncEA content={props} />;
      break;
    default:
      cardComponent = "";
      break;
  }

  return <div>{cardComponent}</div>;
}
M_CustomCards.propTypes = {
  item: PropTypes.shape({
    content: PropTypes.shape({
      customCardName: PropTypes.string,
    }),
  }),
  content: PropTypes.object,
  state: PropTypes.object,
};
