import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Cross, Tick } from "./SVGs";
// import { isResponseSubmitted } from "./ESG_Config";
import {
  getAllInvestment,
  getResponseApprovalBySurveyId,
  getSurveyBySurveyId,
  closeSurvey,
  getQuestionResponseCount,
} from "./ESG_API";
import { SurveyFilter, SurveyFilterYear } from "./components";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import { getDate } from "./utils";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#757474",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(
  asset,
  completion,
  am,
  amsubmitteddate,
  rad,
  radsubmitteddate
) {
  return { asset, completion, am, amsubmitteddate, rad, radsubmitteddate };
}

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  errorMessage: {
    background: "rgb(253, 237, 237)",
    borderRadius: "4px",
    textAlign: "center",
    color: "rgb(102, 60, 0)",
    marginBottom: "1%",
    marginTop: "-2%",
    display: "flex",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%,0%)",
    zIndex: "1",
  },
});

const ESGSubmissionSummary = () => {
  const classes = useStyles();
  const [assetData, setAssetData] = useState([]);
  const [investmentNames, setInvestmentNames] = useState([]);
  const [approvalData, setApprovalData] = useState([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [filtersurveyyear, setSurveyFilterYear] = useState("");
  const [showSummary, setShowSummary] = useState(true);
  const [isDisable, setIsDisable] = useState(false);
  const [showError, setShowError] = useState(false);
  const [failure, setFailure] = useState(false);
  const [success, setSuccess] = useState(false);
  const [count, setCount] = useState([]);

  useEffect(() => {
    const getAssetData = async () => {
      const data = await getAllInvestment();
      setAssetData(data);
      if (data) {
        const uniqueInvestmentNames = [
          ...new Set(data.map((dta) => dta.investmentName)),
        ];
        setInvestmentNames(uniqueInvestmentNames);
      }
    };

    const getReponseApprovalBySurvey = async () => {
      const data = await getResponseApprovalBySurveyId(selectedSurveyId);
      setApprovalData(data);
    };

    getAssetData();
    getReponseApprovalBySurvey();
  }, []);

  useEffect(() => {
    const getSurveyById = async () => {
      const res = await getSurveyBySurveyId(selectedSurveyId);
      if (res.status == 0) {
        setShowSummary(false);
      } else if (res.status == 1) {
        setShowSummary(true);
        setIsDisable(false);
        setShowError(false);
      } else if (res.status == 2) {
        setShowSummary(true);
        setIsDisable(true);
        setShowError(true);
      }
    };

    const getReponseApprovalBySurvey = async () => {
      const data = await getResponseApprovalBySurveyId(selectedSurveyId);
      if (data && data.length) {
        setApprovalData(data);
      }
    };

    const getCount = async () => {
      let res = await getQuestionResponseCount(selectedSurveyId);
      setCount(res);
    };

    getCount();
    getSurveyById();
    getReponseApprovalBySurvey();
  }, [selectedSurveyId]);

  const handleFreezeResponse = async () => {
    setIsDisable(true);
    let response = await closeSurvey(selectedSurveyId);
    if (response.messasge) {
      setIsDisable(false);
      setFailure(true);
    } else {
      setSuccess(true);
    }
    window.location.reload();
  };

  const handleClose = () => {
    setSuccess(false);
    setFailure(false);
  };

  const haveAssetManager = (row) => {
    let date = "-";
    if (approvalData) {
      for (let i = 0; i < approvalData.length; i++) {
        if (
          row == approvalData[i].investmentName &&
          approvalData[i].role == "Asset Manager"
        ) {
          let date = getDate(approvalData[i].updatedAt);
          return date;
        }
      }
    }
    return date;
  };

  const haveRegionalManager = (row) => {
    let date = "-";
    if (approvalData) {
      for (let i = 0; i < approvalData.length; i++) {
        if (
          row == approvalData[i].investmentName &&
          approvalData[i].role == "Regional Asset Director"
        ) {
          date = getDate(approvalData[i].updatedAt);
          return date;
        }
      }
    }
    return date;
  };

  const countPercent = (row) => {
    if (count && count.responseCount && count.responseCount.length > 0) {
      for (let i = 0; i < assetData.length; i++) {
        for (let j = 0; j < count.responseCount.length; j++) {
          if (
            row == assetData[i].investmentName &&
            count.responseCount[j].investmentId == assetData[i].investmentId
          ) {
            return Math.floor(
              (count.responseCount[j].responseCount / count.questionCount) * 100
            );
          }
        }
      }
    }
    return 0;
  };

  return (
    <div>
      <SurveyFilter
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
      />
      <SurveyFilterYear
        selectedSurveyId={selectedSurveyId}
        filtersurveyyear={filtersurveyyear}
        setSelectedSurveyId={setSelectedSurveyId}
        setSurveyFilterYear={setSurveyFilterYear}
      />

      {showSummary ? (
        <>
          {showError && <Alert severity="error">Survey Closed</Alert>}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Assets</StyledTableCell>
                  <StyledTableCell align="center">% Completion</StyledTableCell>
                  <StyledTableCell align="center">AM</StyledTableCell>
                  <StyledTableCell align="center">
                    AM Submitted Date
                  </StyledTableCell>
                  <StyledTableCell align="center">RAD</StyledTableCell>
                  <StyledTableCell align="center">
                    RAD Submitted Date
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {investmentNames.map((row, index) => {
                  let AMdate = haveAssetManager(row);
                  let RMdate = haveRegionalManager(row);
                  let percent = countPercent(row);
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell component="th" scope="row">
                        {row}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {`${percent}%`}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {AMdate !== "-" ? <Tick /> : <Cross />}
                      </StyledTableCell>
                      <StyledTableCell align="center">{AMdate}</StyledTableCell>
                      <StyledTableCell align="center">
                        {RMdate !== "-" ? <Tick /> : <Cross />}
                      </StyledTableCell>
                      <StyledTableCell align="center">{RMdate}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <A_Button
            color="primary"
            style={{
              marginTop: "20px",
              marginLeft: "80%",
            }}
            label="Freeze Responses"
            onClick={handleFreezeResponse}
            disabled={isDisable}
          />
        </>
      ) : (
        <Alert severity="info">
          There is no response to show, either the survey is not yet published
          or response is not submitted yet
        </Alert>
      )}
      <A_Snackbar
        open={success}
        message={"Successfully closed"}
        autoHideDuration={1000}
        horizontal="center"
        handleClose={handleClose}
      />
      <A_Snackbar
        open={failure}
        message={"Failed to close"}
        autoHideDuration={3000}
        horizontal="center"
        handleClose={handleClose}
        type="error"
      />
    </div>
  );
};

export default ESGSubmissionSummary;
