/* eslint-disable */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as loggedInUserIdActions from "../../../../../store/actions/loggedInUserIdActions";
import * as toastActions from "../../../../../store/actions/toastActions";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import { getCRMContactInfo } from "./../../../../../Utils/Helpers/common";
import * as httpCall from "./../../../../../Utils/apis/apis";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router";
import A_CircularIndeternment from "../../../Atoms/Feedback/ProgressIndicators/A_CircularIndeternment";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  color: {
    color: "black",
  },
  list: {
    maxHeight: "35vh",
    overflowY: "auto",
    borderRadius: "10p",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    margin: "5px 0px 20px 0px",
  },

  noDomains: {
    textAlign: "center",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    float: "left",
    width: "200px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  container: {
    overflow: "auto",
    height: "137px",
    margintop: "15px",
  },
  dekstopView: {
    float: "right",
    height: "40px",
    margin: "10px",
  },
  mobileView: {
    height: "40px",
    margin: "10px",
  },
  buttonsContainer: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    textAlign: "right ",
  },
  button: {
    margin: theme.spacing(1),
    textAlign: "right ",
  },
  mobile: {
    display: "flow-root",
  },
  label: {
    margin: "8px 12px -11px 0px",
    minWidth: "190px",
    float: "right",
    fontWeight: 500,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));
const preference = ["Synchronise", "Let Me Choose", "Don't Synchronise"];
const Filterpreference = [
  "All",
  "Synchronise",
  "Let Me Choose",
  "Don't Synchronise",
];
function updatePreferences(domainset) {
  const updatedDomainset = domainset.map((item) => {
    switch (item.preference) {
      case "Let Me Choose":
        return { ...item, preference: "select" };
      case "Don't Synchronise":
        return { ...item, preference: "none" };
      case "Synchronise":
        return { ...item, preference: "all" };
      default:
        return item;
    }
  });

  return updatedDomainset;
}
function updatePreferencesReverse(domainset) {
  const updatedDomainset = domainset.map((item) => {
    switch (item.preference.toLowerCase()) {
      case "select":
        return { ...item, preference: "Let Me Choose" };
      case "none":
        return { ...item, preference: "Don't Synchronise" };
      case "all":
        return { ...item, preference: "Synchronise" };
      default:
        return item;
    }
  });

  return updatedDomainset;
}

const UserPreferences = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPreference, setSelectedPreference] = useState("All");
  const [domainList, setDomainList] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const { crmContactUserEmail } = getCRMContactInfo();
  const [expanded, setExpanded] = React.useState(false);
  const [checkBox, setCheckBox] = React.useState(false);
  if (window.location.pathname == "/dashboard") {
    history.push(`/${"contact"}/${props.LogedUserId}/`);
  }
  useEffect(() => {
    fetchData();
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSeeMeeting = () => {
    history.push(`/${"contact"}/${props.LogedUserId}/`);
    props.close();
  };
  const handleChangeAccordian = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const message = (
    <span>
      Preferences Saved Successfully.{" "}
      <Link onClick={handleSeeMeeting} color="inherit" underline="always">
        Review Synced Meeting
      </Link>
    </span>
  );

  const fetchData = async () => {
    setLoading(true);
    let apiPayload = {
      label: "contact",
      properties: {
        email: crmContactUserEmail,
      },
    };
    try {
      const res = await httpCall.httpPost(
        `${process.env.API_URL}/CRMData/getRecordByProperty`,
        apiPayload
      );
      if (
        res &&
        res.length > 0 &&
        res[0] &&
        res[0].properties &&
        res[0].properties.domains
      ) {
        let domainList = JSON.parse(res[0].properties.domains);
        domainList = updatePreferencesReverse(domainList);
        setDomainList(domainList);
        setCheckBox(areAllPreferencesSynchronised(domainList));
        setLoading(false);
        setButtonLoading(false);
      } else {
        setDomainList([]);
        setLoading(false);
        setButtonLoading(false);
      }
    } catch (error) {
      setDomainList([]);
      setLoading(false);
      setButtonLoading(false);
    }
  };
  const getIndexofSelectedObj = (domainArray, name) => {
    let index = 0;
    domainArray.forEach((item, ind) => {
      if (item.name == name) {
        index = ind;
      }
    });
    return index;
  };
  const handleChange = (event, name) => {
    const updatedDomainList = [...domainList];
    let index = getIndexofSelectedObj(updatedDomainList, name);
    updatedDomainList[index].preference = event.target.value;
    setDomainList(updatedDomainList);
    setUnsavedChanges(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePreferenceChange = (event) => {
    setSelectedPreference(event.target.value);
  };

  const filteredDomainList = domainList.filter((domainItem) => {
    const isMatchingName = domainItem.name
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const isMatchingPreference =
      selectedPreference === "All" ||
      domainItem.preference === selectedPreference;
    return isMatchingName && isMatchingPreference;
  });

  const handleSave = async () => {
    setUnsavedChanges(false);
    setButtonLoading(true);
    setLoading(true);
    try {
      let data = [...domainList];
      const updatedDomainset = updatePreferences(data);
      let convertedFormData = {
        id: props.LogedUserId,
        label: "contact",
        properties: {
          domains: JSON.stringify(updatedDomainset),
        },
      };
      await httpCall.httpPut(
        `/CRMData/update?pageUrl=contact`,
        convertedFormData
      );
      props.setToast({
        type: "success",
        severity: "success",
        message: message,
        status: true,
      });
      await fetchData();
      props.close();
    } catch (error) {
      props.setToast({
        type: "error",
        severity: "error",
        message: error,
        status: true,
      });
      setLoading(false);
      setButtonLoading(false);
    }
  };
  function areAllPreferencesSynchronised(domains) {
    if (domains.length === 0) {
      return false;
    }
    return domains.every((domain) => domain.preference === "Synchronise");
  }
  function updatePreferencesCheckBox(domains, checkboxStatus) {
    try {
      domains.forEach((item) => {
        item.preference = checkboxStatus ? "Synchronise" : "Don't Synchronise";
      });
      return domains;
    } catch (error) {
      return domains;
    }
  }
  const handleCheckBoxChange = (event) => {
    setUnsavedChanges(true);
    setCheckBox(event.target.checked);
    let list = JSON.parse(JSON.stringify(domainList));
    const updatedDomains = updatePreferencesCheckBox(
      [...list],
      event.target.checked
    );
    setDomainList(updatedDomains);
  };
  return (
    <List style={{ padding: "30px" }} disabled={loading}>
      <div className={classes.container}>
        <FormControl className={classes.formControl}>
          <InputLabel>Filter By Preference</InputLabel>
          <Select
            disabled={loading}
            labelId="FilterPreference"
            id="preference"
            value={selectedPreference}
            onChange={handlePreferenceChange}
            displayEmpty
            className={classes.selectEmpty}
          >
            {Filterpreference.map((preferenceName, index) => (
              <MenuItem value={preferenceName} key={index}>
                {preferenceName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText></FormHelperText>
        </FormControl>
        <TextField
          label="Search for Domain"
          disabled={loading}
          className={isMobile ? classes.mobileView : classes.dekstopView}
          type="Search for Domain"
          variant="outlined"
          id="outlined-search"
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {filteredDomainList.length > 0 ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBox}
              color={"primary"}
              onChange={handleCheckBoxChange}
              name="Sync All Meetings"
            />
          }
          label="Sync All Meetings"
        />
      ) : (
        ""
      )}

      {filteredDomainList && filteredDomainList.length > 0 ? (
        <List className={classes.list} disabled={loading}>
          <p className={classes.label}>Select Sync Option</p>
          {filteredDomainList.map((domainItem, index) => (
            <React.Fragment key={index} style={{ marginTop: "50px" }}>
              <ListItem className={isMobile ? classes.mobile : ""}>
                <ListItemText
                  primary={domainItem.name}
                  style={{ color: "#1976D2" }}
                />
                <FormControl className={classes.formControl}>
                  <InputLabel id={domainItem.name}></InputLabel>
                  <Select
                    disabled={loading}
                    labelId={domainItem.name}
                    id={domainItem.preference}
                    value={domainItem.preference}
                    onChange={(event) => handleChange(event, domainItem.name)}
                    displayEmpty
                    className={classes.selectEmpty}
                  >
                    {preference.map((preferenceName, index) => (
                      <MenuItem value={preferenceName} key={index}>
                        {preferenceName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText></FormHelperText>
                </FormControl>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      ) : (
        <div className={classes.noDomains}>
          {loading ? (
            <>
              <Skeleton variant="text" width={80} height={80} />
              <Skeleton variant="text" width={"100%"} height={80} />
              <Skeleton variant="rect" width={"100%"} height={118} />
            </>
          ) : (
            "No Domains found"
          )}
        </div>
      )}
      <div className={classes.buttonsContainer}>
        <A_Button
          variant="contained"
          label={!buttonLoading ? "SAVE" : "SAVING..."}
          color="primary"
          className={classes.button}
          onClick={handleSave}
          disabled={!unsavedChanges}
          endIcon={
            buttonLoading ? (
              <A_CircularIndeternment color="secondary" size={20} />
            ) : (
              <></>
            )
          }
        />
      </div>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeAccordian("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>
            <strong>*Guidance:</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <strong>Synchronize:</strong> This choice will synchronize all
            third-party non-private meetings from the chosen domain.
            <br />
            <strong>Let Me Choose:</strong> This option empowers users to choose
            specific meetings for synchronization in the Meetings table.
            <br />
            <strong>Don't Synchronize:</strong> Opting for this will refrain
            from synchronizing any meetings from the selected domain.
            <br /> <br />
            The synchronization cycle will show all meetings scheduled in the
            calendar, ranging from the past 3 days to the upcoming 30 days.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </List>
  );
};

function mapStateToProps(state) {
  return {
    loggedIn: state.getLoggedInUserReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, loggedInUserIdActions, toastActions),
    dispatch
  );
}
UserPreferences.propTypes = {
  loggedIn: PropTypes.object.isRequired,
  setToast: PropTypes.func.isRequired,
  LogedUserId: PropTypes.string.isRequired,
  close: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserPreferences);
