import React, { useState } from "react";
import PropTypes from "prop-types";
import ESG_QuestionnaireSetupView from "./ESG_QuestionnaireSetupView";
import ESG_QuestionnaireSetupHeader from "./ESG_QuestionnaireSetupHeader";

const ESG_adminLayout = (props) => {
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [singleSurvey, setSingleSurvey] = useState({});
  return (
    <div>
      <ESG_QuestionnaireSetupHeader
        open={open}
        setOpen={setOpen}
        edit={edit}
        setEdit={setEdit}
        cotent={props.content}
        singleSurvey={singleSurvey}
      />
      <ESG_QuestionnaireSetupView
        setOpen={setOpen}
        edit={edit}
        setEdit={setEdit}
        cotent={props.content}
        singleSurvey={singleSurvey}
        setSingleSurvey={setSingleSurvey}
      />
    </div>
  );
};
ESG_adminLayout.propTypes = {
  content: PropTypes.any,
};

export default ESG_adminLayout;
