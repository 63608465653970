import React, { useEffect, useState } from "react";
import A_FormTextField from "../../../Atoms/Inputs/FormFields/A_FormTextField";
import A_FormSelectField from "../../../Atoms/Inputs/FormFields/A_FormSelectField";
import A_FormButton from "../../../Atoms/Inputs/FormFields/A_FormButton";
import { getFormattedDate } from "../../../../../Utils/Helpers/dateUtils";
import M_FormDateField from "../../DateFields/M_FormDateField";
import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";

const M_ChartDataEntry = (props) => {
  const [metrics, setMetrics] = useState([]);
  const [existingMetrics, setExistingMetrics] = useState([]);
  const [updateClicked, SetUpdateClicked] = useState(false);
  const [selectedDataPoint, setSelectedDataPoint] = useState(
    props.data &&
      props.data.points &&
      props.data.points.length > 0 &&
      props.data.points[0].date
      ? props.data.points[0].date
      : ""
  );
  const [entryPointOptions, setEntryPointOptions] = useState([]);
  const [updatedPoints, setUpdatedPoints] = useState([]);
  const dataPoints = props.data && props.data.points ? props.data.points : [];
  useEffect(() => {
    if (dataPoints) {
      const options = dataPoints.map((item) => ({
        label: getFormattedDate(item.date, "MMM-yyyy"),
        value: item.date,
      }));
      setEntryPointOptions(options);
      if (selectedDataPoint) {
        const selectedPoint = dataPoints.find(
          (item) => item.date === selectedDataPoint
        );
        setExistingMetrics(JSON.parse(JSON.stringify(selectedPoint.metrics)));
        setMetrics(selectedPoint.metrics);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (updateClicked && props.isSaving === false) {
      props.onCancelUpdates();
    }
  }, [props.isSaving]);
  useEffect(() => {
    if (selectedDataPoint) {
      const selectedPoint = dataPoints.find(
        (item) => item.date === selectedDataPoint
      );
      setExistingMetrics(JSON.parse(JSON.stringify(selectedPoint.metrics)));
      setMetrics(selectedPoint.metrics);
    } else {
      setMetrics([]);
    }
  }, [selectedDataPoint]);

  const onSelectedEntry = (selectedEntry) => {
    setUpdatedPoints([]);
    setSelectedDataPoint(selectedEntry.value);
    setUpdatedPoints([]);
  };
  const onSave = () => {
    SetUpdateClicked(true);
    props.onSaveUpdates(updatedPoints);
  };
  const onCancel = () => {
    setMetrics([]);
    setUpdatedPoints([]);
    props.onCancelUpdates();
    setUpdatedPoints([]);
    // setSelectedDataPoint("");
  };
  const onValueChange = (metric, updatedVal, type) => {
    let updatedValue = updatedVal;

    if (type == "percentage") {
      updatedValue = updatedValue / 100;
    }

    const modifiedMetric = metrics.find((item) => item.id === metric.id);
    modifiedMetric.value = updatedValue;
    modifiedMetric.isModified = true;
    const modifiedMetrics = metrics.map(
      (item) => metrics.find((o) => o.id === item.id) || modifiedMetric
    );
    setMetrics(modifiedMetrics);

    const existingPoint = updatedPoints.find(
      (item) => item.metricid === metric.id
    );

    if (existingPoint) {
      if (updatedValue == "") {
        const checkExistingValue = existingMetrics.find(
          (item) => item.id === metric.id
        );
        checkExistingValue &&
        (checkExistingValue.value == "" || checkExistingValue.value == null)
          ? updatedPoints.splice(
              updatedPoints.findIndex((e) => e.metricid === metric.id),
              1
            )
          : (existingPoint.value = updatedValue);
      } else {
        existingPoint.value = updatedValue;
      }
    } else {
      const updatedMetricPoint = {
        date: metric.date,
        metricid: metric.id,
        investmentId: metric.investmentId,
        series: props.seriesName,
        value: updatedValue,
      };
      setUpdatedPoints([...updatedPoints, updatedMetricPoint]);
    }
  };
  return (
    <>
      {metrics && metrics.length > 0 ? (
        <div>
          <span>{props.data.formName}</span>
          <div>
            {
              <A_FormSelectField
                label={props.data.keyField}
                options={entryPointOptions}
                value={selectedDataPoint}
                onChange={onSelectedEntry}
              />
            }
          </div>
          {metrics &&
            metrics
              .filter((item) => {
                return selectedDataPoint === item.date;
              })
              .map((item) => (
                <div key={`${item.id}_${selectedDataPoint}`}>
                  {item.dataType && item.dataType == "date" ? (
                    <M_FormDateField
                      label={item.name}
                      dataType={item.dataType}
                      defaultValue={item.value}
                      onChange={(e) => {
                        onValueChange(item, e, item.dataType);
                      }}
                    />
                  ) : (
                    <A_FormTextField
                      label={item.name}
                      dataType={item.dataType}
                      value={
                        item.value !== null
                          ? item.dataType == "percentage"
                            ? item.value * 100
                            : item.value
                          : ""
                      }
                      onChange={(e) =>
                        onValueChange(
                          item,
                          e.target.value ? e.target.value : "0",
                          item.dataType
                        )
                      }
                    />
                  )}
                </div>
              ))}

          {selectedDataPoint && (
            <div>
              <A_FormButton
                onSave={onSave}
                onCancel={onCancel}
                saveDisabled={updatedPoints.length == 0}
                isSaveInProgress={props.isSaving}
                saveLabel="Update"
                cancelLabel="Cancel"
              ></A_FormButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
          <Skeleton variant="text" width={"100%"} height={80} />
        </>
      )}
    </>
  );
};
M_ChartDataEntry.propTypes = {
  isSaving: PropTypes.bool,
  data: PropTypes.object,
  id: PropTypes.id,
  seriesName: PropTypes.string,
  onCancelUpdates: PropTypes.func,
  onSaveUpdates: PropTypes.func,
};
export default M_ChartDataEntry;
