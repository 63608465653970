import Axios from "axios";
import { handleOptionSetValue } from "../../../../../../../Utils/Helpers/optionSet.helper";
import * as httpCall from "../../../../../../../Utils/apis/apis";
import { isClientAuthorised } from "../../../Navigations/M_NavBarhelper";
import templateData from "./M_searchTemplate";

const isClientAuthorized = () => {
  const portOrHostname = window.location.port
    ? window.location.port
    : window.location.hostname;
  const authorizedHosts = ["anacap", "anacapqa", "5500"];
  return isClientAuthorised(portOrHostname, authorizedHosts);
};

const getTemplate = async () => {
  let res = await httpCall.httpGet("/CRMData/getSearchTemplate");
  return res;
};
let cancelToken;
const getSearchData = async (keyword) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = Axios.CancelToken.source();
  try {
    const endpoint = isClientAuthorized()
      ? `/CRMData/perspicaciousSearch?keyword=${keyword}`
      : `/CRMData/sqlBasedSearch?keyword=${keyword}`;
    const results = await httpCall.httpGet(endpoint, {
      cancelToken: cancelToken.token,
    });
    return results;
  } catch (error) {
    console.log("errr", error);
  }
};
const filterTemplateValue = (templateConditions, field, value) => {
  let condition = templateConditions.filter(
    (condition) => condition.property === field
  );
  if (condition.length > 0) {
    let defaultCondition = condition[0];
    let defaultValue = "";
    defaultCondition.values.forEach(
      (item) =>
        (defaultValue =
          item.value === value
            ? item.default
            : defaultCondition && defaultCondition.default
            ? ""
            : value)
    );
    return defaultValue;
  }
};
export const getNewSearchResult = async (value) => {
  let template = {};
  if (localStorage.getItem("searchTemplateNewData")) {
    try {
      template = JSON.parse(localStorage.getItem("searchTemplateNewData"));
    } catch (e) {
      let tempTemplate = await getTemplate();
      template = tempTemplate.searchResultTemplate;
      if (!isClientAuthorized()) {
        template.forEach((item) => {
          templateData.forEach((obj) => {
            if (item.label === obj.label) {
              obj.pageId = item.pageId;
              obj.templateId = item.templateId || "";
            }
          });
        });
        localStorage.setItem(
          "searchTemplateNewData",
          JSON.stringify(templateData)
        );
        template = templateData;
      } else
        localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
    }
  } else {
    let tempTemplate = await getTemplate();
    template = tempTemplate.searchResultTemplate;
    if (!isClientAuthorized()) {
      template.forEach((item) => {
        templateData.forEach((obj) => {
          if (item.label === obj.label) {
            obj.pageId = item.pageId;
            obj.templateId = item.templateId || "";
          }
        });
      });
      localStorage.setItem(
        "searchTemplateNewData",
        JSON.stringify(templateData)
      );
      template = templateData;
    } else
      localStorage.setItem("searchTemplateNewData", JSON.stringify(template));
  }
  if (!isClientAuthorized()) {
    template.forEach((obj) => {
      if ("title" in obj) {
        obj.title = ["displayText"];
      }
    });
    const contactItem = template.find((item) => item.label === "contact");
    const emailIndex = contactItem.subtitle.indexOf("email");
    if (emailIndex !== -1) {
      contactItem.subtitle[emailIndex] = "displayTextLevel2";
    }
  }
  let searchResult = await getSearchData(value);
  let finalSearchResult = [];
  for (let searchData in searchResult) {
    let searchSelectedTemplates = template.filter((item) => {
      return (
        item.label.toLowerCase() ===
        searchResult[searchData].label.toLowerCase()
      );
    });
    if (searchSelectedTemplates.length == 0) {
      continue;
    }
    searchSelectedTemplates = searchSelectedTemplates[0];
    let conditions =
      (searchSelectedTemplates.conditionalMapping &&
        searchSelectedTemplates.conditionalMapping.template) ||
      [];
    let fieldForChips = [...new Set(conditions.map((item) => item.property))];
    let fieldForSubtitle = searchSelectedTemplates.subtitle.filter(
      (item) => !fieldForChips.includes(item)
    );
    var chipData = "";
    if (!isClientAuthorized()) {
      chipData = !searchResult[searchData].displayHelperText
        ? ""
        : searchResult[searchData].displayHelperText;
    } else {
      chipData = fieldForChips.map((field) =>
        filterTemplateValue(
          conditions,
          field,
          handleOptionSetValue(searchResult[searchData][field])
        )
      );
    }
    let title = searchSelectedTemplates.title
      .map((titleName) => {
        return searchResult[searchData][titleName];
      })
      .join(" ");
    var subtitle = "";
    if (!isClientAuthorized()) {
      subtitle = !searchResult[searchData].displayTextLevel2
        ? ""
        : searchResult[searchData].displayTextLevel2;
    } else {
      subtitle = fieldForSubtitle.map((titleName) => {
        return handleOptionSetValue(searchResult[searchData][titleName] || "");
      });
      subtitle = subtitle
        .filter((item) =>
          typeof item === "string" ? item.trim() !== "" : true
        )
        .join("\n");
    }
    let id = searchResult[searchData]["id"];
    let header = searchSelectedTemplates["header"]
      ? searchSelectedTemplates["header"]
      : "";
    let pageUrl = searchSelectedTemplates["label"]
      ? searchSelectedTemplates["label"]
      : "";
    let linkValue = searchResult[searchData]["link"];
    let pageId = searchSelectedTemplates["pageId"]
      ? searchSelectedTemplates["pageId"]
      : "";
    let resultTemplate = {
      title: title,
      email: subtitle,
      header: header,
      id: id,
      pageId: pageId,
      pageUrl: pageUrl,
      link: linkValue,
      chips: !isClientAuthorized() ? [chipData] : chipData,
    };
    finalSearchResult.push(resultTemplate);
  }
  return finalSearchResult;
};
