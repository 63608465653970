import axios from "axios";
import { store } from "../../../../../store";
import { getPreviousUpload } from "../../../../../store/actions/previousUploadActions";
import * as commonFunctions from "../../../../../Utils/Helpers/Common";
import { httpPost } from "../../../../../Utils/apis/apis";

// TODO - Refactor handleFileUpload , use saga for async behaviour
export const handleFileUpload = async (
  e = undefined,
  crmId,
  user,
  dropedFiles = null,
  content = null,
  fileName = null,
  setUploading = null,
  setChangesSaved = null,
  fileUploadOnView = false,
  pageUrl = undefined,
  nonCRM = true,
  directory,
  container,
) => {
  return new Promise((res, err) => {
    let resultFiles = [];
    let resultFileName = [];
    let filesList = null;

    if (dropedFiles && dropedFiles[0] && dropedFiles[0].files && dropedFiles[0].files[0]) {
      filesList = dropedFiles[0].files[0];
    } else if (e && e.target && e.target.files && e.target.files[0]) {
      filesList = e.target.files[0];
    }
    let name; 
    if (filesList) {     
      name = fileName + filesList.name;
      resultFileName.push(name);
      resultFiles.push(filesList);
    }

    if (!nonCRM) {
      if (Array.isArray(dropedFiles) && dropedFiles.length > 0) {
        let totalNumberOfFiles = 0;
        let numberOfFilesUploaded = 1;
        dropedFiles.map((dropedFile) => {
          totalNumberOfFiles = totalNumberOfFiles + dropedFile.files.length;
        });
        dropedFiles.map((dropedFile) => {
          let config = dropedFile.fileConfig;
          dropedFile.files.map(async (file) => {
            if (file["uploadedDoc"]) {
              numberOfFilesUploaded = file["deleteFile"]
                ? handleFileDelete(
                    file,
                    totalNumberOfFiles,
                    numberOfFilesUploaded
                  )
                : "";
            } else {
              if (config.allowedExtensions !== undefined) {
                const ext = name.split(".").pop();
                if (
                  config.allowedExtensions.includes(ext.toLowerCase()) ||
                  config.allowedExtensions.includes("*") ||
                  config.allowedExtensions.includes("image/*")
                ) {
                  let reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = async (e) => {
                    try {
                      let subdomain = new URL(
                        window.location.href
                      ).hostname.split(".")[0];
                      {
                        fileUploadOnView ? setUploading(true) : "";
                      }
                      {
                        let name1 = `${fileName}${
                          file.field ? file.field : ""
                        }__${file.name}`;

                        let url = process.env.DOCUMENT_HELPER;
                        let header = {
                          filename: name1,
                          type: config.containerType,
                          "file-operation": "upload",
                          "process-file": config.processFile
                            ? config.processFile
                            : "no",
                          "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
                          subdomain: subdomain,
                          Accept: "application/json",
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + user.access_token,
                        };
                        let formData = {
                          file: e.target.result,
                        };
                        if (config.apiDetails) {
                          let {
                            extraHeaders = {},
                            apiUrl,
                            extraPayloads = {},
                          } = config.apiDetails;
                          header = { ...header, ...extraHeaders };
                          url = apiUrl || url;
                          formData = {
                            ...formData,
                            ...extraPayloads,
                          };
                        }
                        axios
                          .post(url, formData, {
                            headers: header,
                          })
                          .then(() => {
                            if (numberOfFilesUploaded === totalNumberOfFiles) {
                              location.reload();
                            } else {
                              numberOfFilesUploaded++;
                            }
                          });
                        store.dispatch(
                          getPreviousUpload({
                            crmId: crmId,
                            pageUrl,
                            directory,
                            container,
                          })
                        );
                      }
                      {
                        fileUploadOnView ? setUploading(false) : "";
                      }

                      {
                        fileUploadOnView ? setChangesSaved(true) : "";
                      }
                      res();
                    } catch (e) {
                      {
                        fileUploadOnView ? setChangesSaved(false) : "";
                      }
                      err(e);
                    }
                  };
                } else {
                  fileUploadOnView ? setChangesSaved(false) : "";
                }
              }
            }
          });
        });
      }
    } else {
      for (
        let i = 0;
        i <
        (dropedFiles[0] && dropedFiles[0].files && dropedFiles[0].files.length);
        i++
      ) {
        let name;
        name = fileName + dropedFiles[0].files[i].name;
        if (content.allowedExtensions !== undefined) {
          const ext = name.split(".").pop();
          if (
            content.allowedExtensions.includes(ext.toLowerCase()) ||
            content.allowedExtensions.includes("*") ||
            content.allowedExtensions.includes("image/*")
          ) {
            let reader = new FileReader();
            reader.readAsDataURL(dropedFiles[0].files[i]);
            reader.onload = async (e) => {
              try {
                let subdomain = new URL(window.location.href).hostname.split(
                  "."
                )[0];
                {
                  fileUploadOnView ? setUploading(true) : "";
                }
                {
                  let url = process.env.DOCUMENT_HELPER;
                  let header = {
                    filename: name,
                    type: content.containerType,
                    "file-operation": "upload",
                    "process-file": content.processFile
                      ? content.processFile
                      : "no",
                    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
                    subdomain: subdomain,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + user.access_token,
                  };
                  let formData = {
                    file: e.target.result,
                  };
                  if (content.apiDetails) {
                    let {
                      extraHeaders = {},
                      apiUrl,
                      extraPayloads = {},
                    } = content.apiDetails;
                    header = { ...header, ...extraHeaders };
                    url = apiUrl || url;
                    formData = {
                      ...formData,
                      ...extraPayloads,
                    };
                  }
                  await axios.post(url, formData, {
                    headers: header,
                  });
                  store.dispatch(
                    getPreviousUpload({
                      crmId: crmId,
                      pageUrl,
                      directory,
                      container,
                    })
                  );
                }
                {
                  fileUploadOnView ? setUploading(false) : "";
                }

                {
                  fileUploadOnView ? setChangesSaved(true) : "";
                }
                res();
              } catch (e) {
                {
                  fileUploadOnView ? setChangesSaved(false) : "";
                }
                err(e);
              }
            };
          } else {
            fileUploadOnView ? setChangesSaved(false) : "";
          }
        }
      }
    }

    return;
  });
};

const handleFileDelete = async (
  file,
  totalNumberOfFiles,
  numberOfFilesUploaded
) => {
  const documentName = file.fullName;
  const url = `${process.env.DOCUMENT_HELPER}`;
  const httpConfig = commonFunctions.getHttpConfig();
  let subdomain = new URL(window.location.href).hostname.split(".")[0];
  let header = {
    filename: documentName,
    type: "generic",
    "file-operation": "delete",
    "process-file": "no",
    "x-functions-key": process.env.DOCUMENT_HELPER_KEY,
    subdomain: subdomain,
    Authorization: httpConfig.headers.Authorization,
  };
  axios
    .post(
      url,
      {
        method: "POST",
      },
      { responseType: "blob", headers: header }
    )
    .then((response) => {
      const url1 = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url1;

      if (numberOfFilesUploaded === totalNumberOfFiles) {
        location.reload();
      } else {
        numberOfFilesUploaded++;
      }
      // link.setAttribute("download", file.name);
      // document.body.appendChild(link);
      // link.click();
    });
  return numberOfFilesUploaded;
};

export const getDataFromSQLSp = async (spConfigs) => {
  const { chartSPs, spDetails } = spConfigs;
  return await httpPost(
    `/SQLQuery/getDataFromMultipleSPs?chartSPs=${
      chartSPs ? chartSPs : "false"
    }`,
    spDetails
  );
};
